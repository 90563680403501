import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

const PermissionManagement = React.lazy(() => import('./PermissionManagement'));
// const PermissionUpdate = React.lazy(() => import('./PermissionUpdate'));

const PermissionManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      // <ProtectedComponent requiredPerms={[Permission.PERMISSION_GROUP_LIST]}>
      <PermissionManagement />
      // </ProtectedComponent>
    ),
  },

  // {
  //   path: `create`,
  //   element: (
  //     // <ProtectedComponent requiredPerms={[Permission.PERMISSION_GROUP_CREATE]}>
  //       <PermissionUpdate />
  //     // </ProtectedComponent>
  //   ),
  // },

  // {
  //   path: `:id/update`,
  //   element: (
  //     // <ProtectedComponent requiredPerms={[Permission.PERMISSION_GROUP_UPDATE]}>
  //       <PermissionUpdate />
  //     // </ProtectedComponent>
  //   ),
  // },

  //   {
  //     path: `:id/${RouteName.DETAIL}`,
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserDetail />
  //       </ProtectedComponent>
  //     ),
  //   },

  //   {
  //     path: '/:id/change-password',
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserChangePassword />
  //       </ProtectedComponent>
  //     ),
  //   },
  //   {
  //     path: `:id/${RouteName.HISTORY}`,
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserHistory />
  //       </ProtectedComponent>
  //     ),
  //   },
  // {
  //   path: '/create',
  //   element: (
  //     <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
  //       <CommissionConfigsUpdate />
  //     </ProtectedComponent>
  //   ),
  // },
  { path: '*', element: <Navigate to="/404" /> },
];

const PermissionsManagementRoutes = () => useRoutes(PermissionManagementLayout);
export default PermissionsManagementRoutes;
