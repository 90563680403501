import React, { useEffect, useState } from "react";
import {
  CAvatar,
  CCol,
  CContainer,
  CRow,
  CPagination,
  CPaginationItem,
} from "@coreui/react-pro";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { IUser } from "@/shared/model/user.model";
import { getLogUser } from "@/app/modules/Project/projectManagement.api";

function ActionMember({ user }: { user: IUser | undefined }) {
  const [logsUser, setLogsUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.id) {
      dispatch(getLogUser(user?.username) as any).then((data: any) => {
        setLogsUser(data.payload?.data?.data || []);
      });
    }
  }, [user?.id, dispatch]);

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = logsUser.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(logsUser.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <CContainer fluid style={{ paddingLeft: "32px", paddingRight: "32px" }}>
      <CRow style={{ width: "100%" }}>
        {currentItems.map((items: any, index: number) => (
          <CCol xs={12} key={index}>
            <div
              className="py-3"
              style={{ borderBottom: "1px solid var(--gray-modern-300)" }}
            >
              <div
                className="d-flex flex-row card-header align-items-center"
                style={{ gap: "20px" }}
              >
                <CAvatar
                  src={user?.avatar}
                  className="p-0"
                  style={{
                    width: "24px",
                    height: "24px",
                    border: "0.75px solid #101828",
                  }}
                />
                <div className="">
                  <p
                    className="m-0"
                    style={{ fontWeight: 600, fontSize: "15px" }}
                  >
                    {items.title}
                  </p>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ marginTop: "5px", gap: "10px" }}
                  >
                    <p
                      style={{ fontSize: "12px", color: "#9AA4B2" }}
                      className="header-note m-0"
                    >
                      Cập nhật {dayjs(items.createdDate).fromNow(true)} trước
                    </p>
                    <div
                      style={{
                        width: "2px",
                        height: "2px",
                        background: "#697586",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </CCol>
        ))}
      </CRow>
      <div style={{ paddingTop: "20px", paddingBottom: "14px" }}>
        <CPagination align="end" aria-label="Page navigation example">
          <CPaginationItem
            style={{ cursor: "pointer" }}
            aria-label="Previous"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <span aria-hidden="true">&laquo;</span>
          </CPaginationItem>
          {[...Array(totalPages)].map((_, index) => (
            <CPaginationItem
              style={{ cursor: "pointer" }}
              key={index}
              active={currentPage === index + 1}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </CPaginationItem>
          ))}
          <CPaginationItem
            style={{ cursor: "pointer" }}
            aria-label="Next"
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <span aria-hidden="true">&raquo;</span>
          </CPaginationItem>
        </CPagination>
      </div>
    </CContainer>
  );
}

export default ActionMember;
