import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import axios from "../../../../shared/config/axios-interceptor";
import { ResponseStatus } from "../../../../shared/enumeration/ResponseStatus";
import {
  INewPermission,
  IPermission,
} from "../../../../shared/model/permission.model";
import { IParams } from "../../../../shared/shared-interfaces";

const prefix = "permission-group";
// const history = 'revision';

export interface IPermissionParams extends IParams {
  status?: string
}

export const getEntities = createAsyncThunk(
  `get-all-${prefix}`,
  async (fields: IPermissionParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IPermission[]>(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(
  `get-single-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      const { data } = await axios.get<IPermission>(`${prefix}/${id}`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(
  `create-one-${prefix}`,
  async (body: INewPermission, thunkAPI) => {
    try {
      const { data } = await axios.post(`${prefix}`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateEntity = createAsyncThunk(
  `update-one-${prefix}`,
  async (body: IPermission, thunkAPI) => {
    try {
      const { permissionGroupId } = body;
      const { data } = await axios.put<IPermission>(
        `${prefix}/${permissionGroupId}`,
        pickBy(body)
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const removeEntity = createAsyncThunk(
  `delete-one-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      await axios.delete(`${prefix}/${id}`);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface IPermissionDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: IPermissionDel, thunkAPI) => {
    try {
      await axios.post<IPermission>(`${prefix}/status`, requestBody);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
