import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

import { RootState } from "@/reducers";
import { TaskTypeEnum } from "@/shared/enumeration/task.enum";
import { ITask } from "@/shared/model/task.model";
import { IInitialState, IResponse } from "@/shared/shared-interfaces";
import {
  createEntity,
  getEntities,
  getEntity,
  ITaskParams,
  removeEntity,
  updateDeleteEntity,
  getLogsTask,
  updateEntity,
  getEntitiesDataTask,
} from "./tasksManagement.api";
import { ILogs } from "@/shared/model/project.modal";

interface ITaskInitialState extends IInitialState {
  updateEntityStatusSuccess: boolean;
  filterState: ITaskParams;
  logTasks: ILogs[];
  dataTasks?: any;
  openProject?: any;
  openSelectId?: any;
}

export const initialTaskFilter: ITaskParams = {
  page: 0,
  limit: 0,
  // sort: '',
};

const initialState: ITaskInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  logTasks: [],
  totalItems: 0,
  totalPages: 0,
  updateEntityStatusSuccess: false,
  filterState: initialTaskFilter,
  dataTasks: [],
  openProject: null,
  openSelectId: null
};

export const tasksAdapter = createEntityAdapter<ITask>({
  selectId: ({ taskId }) => taskId,
});

const { actions, reducer } = createSlice({
  name: "tasksSlice",
  initialState: tasksAdapter.getInitialState({ initialState }),
  reducers: {
    setFilterState(state, { payload }: PayloadAction<ITaskParams>) {
      state.initialState.filterState = payload;
    },
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.createEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
    },
    resetFetchEntity(state) {
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    updateOpenProject(state: any, action: PayloadAction<any>) {
      state.initialState.openProject = action.payload.a
      state.initialState.openSelectId = action.payload.b
    },
    resetOpenProject(state) {
      state.initialState.openProject = null
      state.initialState.openSelectId = null
    },
    resetDataTask(state) {
      state.initialState.dataTasks = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<ITask[]>>>
      ) => {
        tasksAdapter.upsertMany(state, payload.data.data);
        state.initialState.dataTasks = payload.data.data;
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
    builder.addCase(
      getEntity.fulfilled.type,
      (state, { payload }: PayloadAction<ITask>) => {
        tasksAdapter.upsertOne(state, payload);
        state.initialState.fetchEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitySuccess = false;
      }
    );
    builder.addCase(
      getLogsTask.fulfilled.type,
      (state, { payload }: PayloadAction<IResponse<ILogs[]>>) => {
        state.initialState.logTasks = payload.data;
        state.initialState.fetchEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getLogsTask.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitySuccess = false;
      }
    );
    builder.addCase(
      updateEntity.fulfilled.type,
      (state, { payload }: PayloadAction<ITask>) => {
        tasksAdapter.updateOne(state, { id: payload.taskId, changes: payload });
        state.initialState.updateEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      updateEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
    builder.addCase(
      createEntity.fulfilled.type,
      (state, { payload }: PayloadAction<ITask>) => {
        tasksAdapter.addOne(state, payload);
        state.initialState.createEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      createEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.createEntitySuccess = false;
      }
    );
    builder.addCase(
      removeEntity.fulfilled.type,
      (state, { payload }: PayloadAction<string>) => {
        tasksAdapter.removeOne(state, payload);
        state.initialState.totalItems -= 1;
        state.initialState.deleteEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      removeEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.deleteEntitySuccess = false;
      }
    );
    builder.addCase(
      updateDeleteEntity.fulfilled.type,
      (state, { payload }: PayloadAction<string[]>) => {
        tasksAdapter.removeMany(state, payload);
        state.initialState.totalItems -= payload.length;
        state.initialState.deleteEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      updateDeleteEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.deleteEntitySuccess = false;
      }
    );
    builder.addCase(
      getEntitiesDataTask.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<ITask[]>>>
      ) => {
        state.initialState.dataTasks = payload.data.data;
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntitiesDataTask.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
  },
});

export const {
  fetching,
  resetAll,
  resetEntity,
  setFilterState,
  resetFetchEntity,
  updateOpenProject,
  resetOpenProject,
  resetDataTask
} = actions;
export default reducer;

export const tasksSelectors = tasksAdapter.getSelectors<RootState>(
  (state) => state.tasksReducer
);

const { selectById, selectAll } = tasksAdapter.getSelectors();
const getTaskState = (rootState: RootState) => rootState.tasksReducer;

export const selectEntityById = (id: number | string) => {
  return createSelector(getTaskState, (state) => selectById(state, id));
};

export const selectEntitiesByFilter = (taskType: TaskTypeEnum) => {
  return createSelector(getTaskState, (state) => {
    const tasks = selectAll(state);
    return tasks?.filter((task) => task.type === taskType);
  });
};
