import {
  CAvatar,
  CCol,
  CContainer,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
} from "@coreui/react-pro";
import { ReactNode, useState } from "react";
import Edit02Icon from "../icons/Edit02Icon";
import UserCircleIcon from "../icons/UserCircleIcon";
import { IUser } from "@/shared/model/user.model";
import EmailIcon from "../icons/EmailIcon";
import CIcon from "@coreui/icons-react";
import { cilX } from "@coreui/icons-pro";
import SModal from "../Modal/SModal";

interface ICustomerModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  listActionComponent: { name: string; component: ReactNode }[];
  user: IUser | null;
}
function MemberDetailModal(props: ICustomerModal) {
  const { setVisible, visible, listActionComponent, user } = props;
  const [activeMode, setActiveMode] = useState<{
    name: string;
    component: ReactNode;
  }>(listActionComponent[0]);
  const closeModal = () => {
    setVisible(false);
  };
  const label = user?.fullName || user?.username || user?.email || "";

  return (
    <>
      {/* <div>
        <div className="background-opacity"> */}
      <SModal
        visible={visible}
        onClose={closeModal}
        backdrop="static"
        className="custom-modal custom-pop-up"
      >
        <div className="flex-grow-1" style={{ position: "relative" }}>
          <CRow>
            <CCol xs={12}>
              <div className="d-flex justify-content-between">
                <div
                  className="d-flex flex-row card-header px-4 align-items-center"
                  style={{ marginTop: "18px" }}
                >
                  <div className="avatar-container me-4">
                    <CAvatar
                      src={user?.avatar || ""}
                      style={{
                        backgroundColor: `${user?.avatarColor || "#1570ef"}`,
                      }}
                      key={user?.id}
                      textColor="white"
                      className="p-0 avatar-size-2xl avatar-wrapper"
                    >
                      {label ? label.charAt(0)?.toUpperCase() : ""}
                    </CAvatar>
                    <div className="edit-avatar">
                      <Edit02Icon
                        className="align-top text-gray-modern-500"
                        width={12}
                        height={12}
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <p className="text-medium-sm text-gray-modern-900 mb-1">
                      {user?.fullName || user?.username}
                    </p>
                    <CRow className="flex-wrap">
                      <CCol className="col-auto">
                        <span className="text-sm text-gray-modern-700 mb-2 mb-lg-0">
                          <UserCircleIcon
                            className="me-1 text-gray-modern-400"
                            height={20}
                            width={20}
                          />
                          @{user?.username}
                        </span>
                      </CCol>
                      <CCol className="col-auto">
                        <span className="text-sm text-gray-modern-700">
                          <EmailIcon
                            className="me-1 text-gray-modern-400"
                            height={20}
                            width={20}
                          />{" "}
                          {user?.email}
                        </span>
                      </CCol>
                    </CRow>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      marginTop: "10px",
                    }}
                    onClick={closeModal}
                  >
                    <CIcon icon={cilX} title="Close" />
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
          <div
            style={{
              borderBottom: "1px solid var(--gray-modern-300)",
            }}
          >
            <CContainer fluid className="page-tab-container">
              <CNav variant="underline" role="tablist" className="custom-nav">
                {listActionComponent.map((value, index) => {
                  return (
                    <CNavItem role="presentation" key={index}>
                      <CNavLink
                        active={value.name === activeMode.name}
                        component="button"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected={value.name === activeMode.name}
                        onClick={() => setActiveMode(value)}
                      >
                        {value.name}
                      </CNavLink>
                    </CNavItem>
                  );
                })}
              </CNav>
            </CContainer>
          </div>

          <div className="">{activeMode.component}</div>
        </div>
      </SModal>
      {/* </div>
      </div> */}
    </>
  );
}

export default MemberDetailModal;
