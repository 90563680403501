export enum Permission {
  PERMISSION_GROUP_CREATE = 'PERMISSION_GROUP_CREATE', // Tạo nhóm quyền
  PERMISSION_GROUP_LIST = 'PERMISSION_GROUP_LIST', // Tất cả nhóm quyền
  PERMISSION_GROUP_DETAIL = 'PERMISSION_GROUP_DETAIL', // Chi tiết nhóm quyền
  PERMISSION_GROUP_UPDATE = 'PERMISSION_GROUP_UPDATE', // Cập nhật nhóm quyền
  PERMISSION_GROUP_DELETE = 'PERMISSION_GROUP_DELETE', // Xóa nhóm quyền
  PERMISSION_GROUP_VIEW = 'PERMISSION_GROUP_VIEW', // Quyền xem nhóm

  USER_CREATE = 'USER_CREATE', // Tạo tài khoản
  USER_LIST = 'USER_LIST', // Tất cả tài khoản
  USER_DETAIL = 'USER_DETAIL', // Chi tiết tài khoản
  USER_VIEW = 'USER_VIEW', // Quyền xem tài khoản
  USER_UPDATE = 'USER_UPDATE',
  USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD',
  USER_DELETE = "USER_DELETE",

  PROJECT_LIST = 'PROJECT_LIST', // Danh sách dự án
  PROJECT_CREATE = 'PROJECT_CREATE', // Tạo dự án
  PROJECT_DETAIL = 'PROJECT_DETAIL', // Chi tiết dự án
  PROJECT_UPDATE = 'PROJECT_UPDATE', // Cập nhật dự án
  PROJECT_DELETE = 'PROJECT_DELETE', // Xóa dự án

  DEPARTMENT_VIEW = 'DEPARTMENT_VIEW',
  DEPARTMENT_CREATE = 'DEPARTMENT_CREATE',
  
  SCHEDULE_VIEW = "SCHEDULE_VIEW",

  MESSAGE_VIEW = "MESSAGE_VIEW",
  MESSAGE_CREATE = 'MESSAGE_CREATE',
  MESSAGE_UPDATE = 'MESSAGE_UPDATE',
  MESSAGE_DELETE = 'MESSAGE_DELETE',

  REPORT_VIEW = "REPORT_VIEW",

  DICT_VIEW = 'DICT_VIEW',
  CREATE_DICT = 'CREATE_DICT',
  EDIT_DICT = 'EDIT_DICT',
  DELETE_DICT = 'DELETE_DICT',
  LOCK_DICT = 'LOCK_DICT'

}

export const permissionsArray: Permission[] = [
  Permission.PERMISSION_GROUP_CREATE,
  Permission.PERMISSION_GROUP_LIST,
  Permission.PERMISSION_GROUP_DETAIL,
  Permission.PERMISSION_GROUP_UPDATE,
  Permission.PERMISSION_GROUP_DELETE,
  Permission.PERMISSION_GROUP_VIEW,
  Permission.USER_CREATE,
  Permission.USER_LIST,
  Permission.USER_DETAIL,
  Permission.USER_VIEW,
  Permission.USER_UPDATE,
  Permission.USER_CHANGE_PASSWORD,
  Permission.USER_DELETE,
  Permission.PROJECT_LIST,
  Permission.PROJECT_CREATE,
  Permission.PROJECT_DETAIL,
  Permission.PROJECT_UPDATE,
  Permission.PROJECT_DELETE,
  Permission.DEPARTMENT_VIEW,
  Permission.DEPARTMENT_CREATE,
  Permission.SCHEDULE_VIEW,
  Permission.MESSAGE_VIEW,
  Permission.MESSAGE_CREATE,
  Permission.MESSAGE_UPDATE,
  Permission.MESSAGE_DELETE,
  Permission.REPORT_VIEW,
  Permission.DICT_VIEW,
  Permission.CREATE_DICT,
  Permission.EDIT_DICT,
  Permission.DELETE_DICT,
  Permission.LOCK_DICT,

];

export const mapPermissionsToName: { [key in Permission]: string } = {
  [Permission.PERMISSION_GROUP_CREATE]: 'Tạo mới quyền',
  [Permission.PERMISSION_GROUP_LIST]: 'Xem Danh sách quyền',
  [Permission.PERMISSION_GROUP_DETAIL]: 'Xem Chi tiết quyền',
  [Permission.PERMISSION_GROUP_UPDATE]: 'Cập nhật quyền',
  [Permission.PERMISSION_GROUP_DELETE]: 'Xóa quyền',
  [Permission.PERMISSION_GROUP_VIEW]: 'Quyền xem danh sách quyền',

  [Permission.USER_CREATE]: 'Tạo tài khoản',
  [Permission.USER_LIST]: 'Xem Danh sách tài khoản',
  [Permission.USER_DETAIL]: 'Xem Chi tiết tài khoản',
  [Permission.USER_VIEW]: 'Quyền xem tài khoản',
  [Permission.USER_UPDATE]: 'Quyền cập nhật tài khoản',
  [Permission.USER_CHANGE_PASSWORD]: 'Quyền thay đổi mật khẩu',
  [Permission.USER_DELETE]: 'Xóa tài khoản',


  [Permission.PROJECT_LIST]: 'Xem Danh sách dự án',
  [Permission.PROJECT_CREATE]: 'Tạo dự án',
  [Permission.PROJECT_DETAIL]: 'Xem Chi tiết dự án',
  [Permission.PROJECT_UPDATE]: 'Cập nhật dự án',
  [Permission.PROJECT_DELETE]: 'Xóa dự án',

  [Permission.DEPARTMENT_VIEW]: "Xem phòng ban",
  [Permission.DEPARTMENT_CREATE]: 'Tạo phòng ban',

  [Permission.SCHEDULE_VIEW]: "Xem lịch biểu",

  [Permission.MESSAGE_VIEW]: 'Xem tin nhắn',
  [Permission.MESSAGE_CREATE]: 'Tạo tin nhắn',
  [Permission.MESSAGE_UPDATE]: 'Cập nhật tin nhắn',
  [Permission.MESSAGE_DELETE]: 'Xóa tin nhắn',

  [Permission.REPORT_VIEW]: 'Xem báo cáo',

  [Permission.DICT_VIEW]: 'Xem danh mục dùng chung',
  [Permission.CREATE_DICT]: 'Tạo danh mục dùng chung',
  [Permission.EDIT_DICT]: 'Cập nhật danh mục dùng chung',
  [Permission.DELETE_DICT]: 'Xóa danh mục dùng chung',
  [Permission.LOCK_DICT]: 'Khóa, mở khóa danh mục dùng chung',
};



export const permissionGroupArray: Permission[] = [
  Permission.PERMISSION_GROUP_CREATE,
  Permission.PERMISSION_GROUP_LIST,
  Permission.PERMISSION_GROUP_DETAIL,
  Permission.PERMISSION_GROUP_UPDATE,
  Permission.PERMISSION_GROUP_DELETE,
  Permission.PERMISSION_GROUP_VIEW,
];

export const userManagementPermissionArray: Permission[] = [
  Permission.USER_CREATE,
  Permission.USER_LIST,
  Permission.USER_DETAIL,
  Permission.USER_VIEW,
];

export const projectPermissionArray: Permission[] = [
  Permission.PROJECT_LIST,
  Permission.PROJECT_CREATE,
  Permission.PROJECT_DETAIL,
  Permission.PROJECT_UPDATE,
  Permission.PROJECT_DELETE,
];
