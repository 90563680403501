import axios from "@/shared/config/axios-interceptor";
import { IDepartment } from "@/shared/model/department.model";
import { IParams } from "@/shared/shared-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";

const prefix = "room";

export interface IDepartmentParams extends IParams {}
interface actionDepartment {
    users: string[];
    name: string;
    avatar?: string;
    // avatarFile?: File | undefined;
    avatarColor?: string;
    description?: string;
}
export const getEntities = createAsyncThunk(
    `get-all-department-${prefix}`,
    async (field: IDepartmentParams, thunkAPI) => {
        try {
            const params = pickBy(field);
            return await axios.get<IDepartment[]>(`${prefix}`, {
                params,
            });
        } catch (e: any) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getEntity = createAsyncThunk(
    `get-entity-department-${prefix}`,
    async (id: number, thunkAPI) => {
        try {
            const params = pickBy({ id });
            return await axios.get<IDepartment[]>(`${prefix}`, { params });
        } catch (e: any) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const createEntity = createAsyncThunk(
    `create-department-one-${prefix}`,
    async (body: actionDepartment, thunkAPI) => {
        try {
            const { data } = await axios.post(
                `${prefix}`,
                pickBy({ ...body, type: "ROOM_GROUP" })
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
