import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import axios from "../../../../shared/config/axios-interceptor";
import { IParams } from "@/shared/shared-interfaces";
import { IMenuRole, INewMenuRole } from "@/shared/model/role.model";

const prefix = "permission";
// const history = 'revision';

export type IRoleParams = IParams

export const getEntities = createAsyncThunk(
  `get-all-${prefix}`,
  async (fields: IRoleParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IMenuRole[]>(`${prefix}/tree`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(
  `get-single-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      const { data } = await axios.get<IMenuRole>(`${prefix}/${id}`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(
  `create-one-${prefix}`,
  async (body: INewMenuRole, thunkAPI) => {
    try {
      const { data } = await axios.post(`${prefix}`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// export const updateEntity = createAsyncThunk(
//   `update-one-${prefix}`,
//   async (body: IMenuRole, thunkAPI) => {
//     try {
//     //   const { permissionGroupId } = body;
//       const { data } = await axios.put<IMenuRole>(
//         `${prefix}/${permissionGroupId}`,
//         pickBy(body)
//       );
//       return data;
//     } catch (error: any) {
//       return thunkAPI.rejectWithValue(error.response.data);
//     }
//   }
// );

export const removeEntity = createAsyncThunk(
  `delete-one-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      await axios.delete(`${prefix}/${id}`);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// export const updateDeleteEntity = createAsyncThunk(
//   `delete-status-${prefix}`,
//   async (requestBody: IPermissionDel, thunkAPI) => {
//     try {
//       await axios.post<IPermission>(`${prefix}/status`, requestBody);
//       return requestBody.id;
//     } catch (error: any) {
//       return thunkAPI.rejectWithValue(error.response.data);
//     }
//   }
// );
