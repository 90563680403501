import { createRoot } from 'react-dom/client';
import App from './App';
import './assets/scss/index.scss';
// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import { firebaseConfig, vapidKeyFirebase } from './shared/config/constants';
const container = document.getElementById('root');



// // Khởi tạo Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);

// // Đăng ký service worker
// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then((registration) => {
//         console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch((error) => {
//         console.error('Service Worker registration failed:', error);
//     });
// }

// // Yêu cầu quyền thông báo
// Notification.requestPermission().then((permission) => {
//     if (permission === 'granted') {
//         console.log('Notification permission granted.');
//         getToken(messaging, { vapidKey: vapidKeyFirebase }).then((currentToken) => {
//             if (currentToken) {
//                 console.log('FCM Token:', currentToken);
//                 // Gửi token đến server hoặc lưu trữ
//             } else {
//                 console.log('No registration token available. Request permission to generate one.');
//             }
//         }).catch((err) => {
//             console.log('An error occurred while retrieving token. ', err);
//         });
//     } else {
//         console.log('Unable to get permission to notify.');
//     }
// });

const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);
