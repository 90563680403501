import { RootState } from "@/reducers";
import { IInitialState, IResponse } from "@/shared/shared-interfaces";
import {
    PayloadAction,
    createEntityAdapter,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createEntity, getEntities, IDepartmentParams } from "./department.api";
import { IDepartment } from "@/shared/model/department.model";

interface IDepartmentInitialState extends IInitialState {
    filterState: IDepartmentParams;
}

export const initialPostFilter: IDepartmentParams = {
    page: 1,
    limit: 10,
    type: "ROOM_GROUP",
};

const initialState: IDepartmentInitialState = {
    fetchEntitiesSuccess: false,
    fetchEntitySuccess: false,
    updateEntitySuccess: false,
    deleteEntitySuccess: false,
    loading: false,
    errorMessage: null,
    totalItems: 0,
    totalPages: 0,
    filterState: initialPostFilter,
};

export const departmentAdapter = createEntityAdapter<IDepartment>({
    selectId: ({ id }) => {
        return id;
    },
});

const { actions, reducer } = createSlice({
    name: "postsSlice",
    initialState: departmentAdapter.getInitialState({ initialState }),
    reducers: {
        setFilterState(state, { payload }: PayloadAction<IDepartmentParams>) {
            state.initialState.filterState = payload;
        },
        fetching(state) {
            state.initialState.loading = true;
        },
        resetAll(state) {
            state.initialState.loading = false;
            state.initialState.fetchEntitiesSuccess = false;
            state.initialState.fetchEntitySuccess = false;
            state.initialState.updateEntitySuccess = false;
            state.initialState.deleteEntitySuccess = false;
            state.initialState.errorMessage = null;
        },
        resetEntity(state) {
            state.initialState.updateEntitySuccess = false;
            state.initialState.errorMessage = null;
            state.initialState.deleteEntitySuccess = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEntities.pending.type, (state, action) => {
                state.initialState.loading = true;
            })
            .addCase(
                getEntities.fulfilled.type,
                (
                    state,
                    {
                        payload,
                    }: PayloadAction<AxiosResponse<IResponse<IDepartment[]>>>
                ) => {
                    departmentAdapter.setAll(state, payload.data.data);
                    state.initialState.totalItems = Number(payload.data.count);
                    state.initialState.totalPages = Number(
                        payload.data.total_pages
                    );
                    state.initialState.fetchEntitiesSuccess = true;
                    state.initialState.loading = false;
                }
            )
            .addCase(
                getEntities.rejected.type,
                (state, { payload }: PayloadAction<any>) => {
                    state.initialState.errorMessage = payload?.messageCode;
                    state.initialState.loading = false;
                    state.initialState.fetchEntitiesSuccess = false;
                }
            );

        // builder
        // .addCase(getEntity.pending.type, (state, action) => {
        //   state.initialState.loading = true;
        // })
        // .addCase(
        //   getEntities.fulfilled.type,
        //   (
        //     state,
        //     { payload }: PayloadAction<AxiosResponse<IResponse<IDepartment[]>>>
        //   ) => {
        //     departmentAdapter.setAll(state, payload.data.data);
        //     state.initialState.totalItems = Number(payload.data.count);
        //     state.initialState.totalPages = Number(payload.data.total_pages);
        //     state.initialState.fetchEntitiesSuccess = true;
        //     state.initialState.loading = false;
        //   }
        // )
        // .addCase(
        //   getEntities.rejected.type,
        //   (state, { payload }: PayloadAction<any>) => {
        //     state.initialState.errorMessage = payload?.message;
        //     state.initialState.loading = false;
        //     state.initialState.fetchEntitiesSuccess = false;
        //   }
        // );

        builder
            .addCase(createEntity.pending.type, (state, action) => {
                state.initialState.loading = true;
            })
            .addCase(
                createEntity.fulfilled.type,
                (state, { payload }: PayloadAction<IDepartment>) => {
                    // departmentAdapter.addOne(state, payload);
                    state.initialState.updateEntitySuccess = true;
                    state.initialState.loading = false;
                }
            )
            .addCase(
                createEntity.rejected.type,
                (state, { payload }: PayloadAction<any>) => {
                    state.initialState.errorMessage = payload?.code;
                    state.initialState.loading = false;
                    state.initialState.updateEntitySuccess = false;
                }
            );
    },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const departmentSelectors = departmentAdapter.getSelectors<RootState>(
    (state) => state.departmentReducer
);

const { selectById } = departmentAdapter.getSelectors();
const getDepartmentState = (rootState: RootState) =>
    rootState.departmentReducer;

export const selectEntityById = (id: string) => {
    return createSelector(getDepartmentState, (state) => selectById(state, id));
};
