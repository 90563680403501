import { UPLOAD_API_URL } from "@/shared/config/constants";
import { AxiosResponse } from "axios";
import axios from "../../../shared/config/axios-interceptor";
import { IFileUpload } from "../../../shared/model/fileUpload.model";

export const uploadFile = async (images: File[]) => {
  try {
    const formData = new FormData();
    for (let index = 0; index < images.length; index++) {
      formData.append("file_url", images[index]);
    }
    const { data }: AxiosResponse<IFileUpload> = await axios.post(
      `${UPLOAD_API_URL}file/upload`,
      formData
    );
    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const uploadDocument = async (files: File[]) => {
  try {
    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append("document_url", files[index]);
    }
    const { data }: AxiosResponse<IFileUpload> = await axios.post(
      `${UPLOAD_API_URL}file/document`,
      formData
    );
    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const uploadPdfFile = async (files: File[]) => {
  try {
    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append("document_url", files[index]);
    }
    const { data }: AxiosResponse<IFileUpload> = await axios.post(
      `${UPLOAD_API_URL}file/document/pdf`,
      formData
    );
    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// interface IImage {
//   url: string;
// }

// export const uploadPrivateImage = async (image: File) => {
//   try {
//     const formData = new FormData();
//     formData.append('file', image);
//     const { data }: AxiosResponse<IImage> = await axios.post(`${UPLOAD_API_URL}files/private`, formData);
//     return data.url;
//   } catch (error: any) {
//     throw new Error(error.message);
//   }
// };
