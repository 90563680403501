import BellIcon from "@/app/shared/icons/header/BellIcon";
import CalendarIcon from "@/app/shared/icons/header/CalendarIcon";
import LogoutIcon from "@/app/shared/icons/header/LogoutIcon";
import SettingIcon from "@/app/shared/icons/header/SettingIcon";
import UserPlusIcon from "@/app/shared/icons/header/UserPlusIcon";
import UserSquareIcon from "@/app/shared/icons/header/UserSquareIcon";
import { RootState } from "@/reducers";
import { SystemRole } from "@/shared/enumeration/role";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { getTruncateTxt } from "@/shared/utils/ultils";
import { AppDispatch } from "@/store";
import { cilMenu } from "@coreui/icons-pro";
import CIcon from "@coreui/icons-react";
import {
  CContainer,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CNav,
  CNavItem,
  CNavLink,
} from "@coreui/react-pro";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router-dom";
import { logout, resetAll } from "../../modules/auth/auth.reducer";
import AppBreadcrumb from "../AppBreadcrumb";
import ChatNavItem from "../ChatNavItem";
import { handleGetHeaderItem, HeaderItem } from "../NavItems";
import { toggleAside, toggleSidebar } from "../reducer";
import Search from "@/app/shared/SearchContainer/Search";
import AvatarCheckBox from "@/app/shared/AvatarContainer/AvatarCheckBox";
import { checkUserAdmin, hasPermission } from "@/app/modules/auth/ProtectedPermission";
import { notificationSelectors } from "@/app/modules/notifications/notifications.reducer";

const TheHeader = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { redirectView, location } = useRouter();
  const { pathname } = location;
  const { sidebarShow, asideShow, customHeader } = useSelector(
    (state: RootState) => state.container
  );
  const { user } = useSelector((state: RootState) => state.authentication);
  const isAdmin = user?.role === SystemRole.ADMIN;
  const [headerItem, setHeaderItem] = useState<HeaderItem | null>(null);
  const { updateEntitySuccess } = useSelector(
    (state: RootState) => state.projectReducer.initialState
  );
  const notificationData = useSelector(notificationSelectors.selectAll);
  const isChatView = pathname.includes("/message");

  const toggleSidebarDesktop = () => {
    dispatch(toggleSidebar(!sidebarShow));
  };

  const toggleASideDesktop = () => {
    dispatch(toggleAside(!asideShow));
  };

  const onLogout = () => {
    dispatch(logout());
    dispatch(resetAll());
  };

  useEffect(() => {
    const headerItem = handleGetHeaderItem(pathname);
    setHeaderItem(customHeader ? customHeader : headerItem);
  }, [pathname, customHeader, updateEntitySuccess]);

  const renderHeader = (item: any | null) => {
    if (!item) return "";
    if (typeof item.name === "string") {
      return (
        <h1 className="header-title m-0 d-none d-sm-block">{item?.name}</h1>
      );
    }

    return item?.name;
  };

  return (
    <CHeader position="sticky" className="custom-header shadow-none">
      <CContainer fluid className="header-container">
        {/* <CHeaderBrand className="mx-auto d-md-none" >
      </CHeaderBrand> */}
        <CHeaderNav className="d-flex me-auto ">
          <CNavItem className="nav-breadcrumb d-none">
            <AppBreadcrumb />
          </CNavItem>
          {renderHeader(headerItem)}
          <CHeaderToggler
            className="ps-1 sidebar-toggler-header"
            style={{ padding: "8px" }}
            onClick={toggleSidebarDesktop}
          >
            <CIcon icon={cilMenu} size="lg" />
          </CHeaderToggler>
        </CHeaderNav>
        <CHeaderNav className="custom-form">
          <Search></Search>
          {!isChatView ? <ChatNavItem /> : null}

          <CNavItem>
            <CNavLink className="nav-icon">
              <CalendarIcon className="header-icon" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink className={`nav-icon ${notificationData?.some((value) => !Number(value?.userNotifications?.[0]?.isRead)) && "before-notification"}`} onClick={toggleASideDesktop}>
              <BellIcon className="header-icon" />
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="header-dropdown-nav">
          <CDropdown variant="nav-item">
            <CDropdownToggle className="py-0" caret={false}>
              <AvatarCheckBox user={user as any} />
              <span className="ms-2 d-none d-md-inline text-semibold-sm">
                {getTruncateTxt(user?.fullName || user?.username)}
              </span>
            </CDropdownToggle>
            <CDropdownMenu className="dropdown-menu">
              <CDropdownHeader className="dropdown-header">
                <AvatarCheckBox user={user as any} />
                <div>
                  <p className="header-title m-0">
                    {user?.fullName || user?.username}
                  </p>
                  <p className="header-note m-0">{user?.email}</p>
                </div>
              </CDropdownHeader>
              <CDropdownDivider />
              <CDropdownItem href="#/account/profile">
                <UserSquareIcon />
                Tài khoản
              </CDropdownItem>
              {isAdmin ? (
                <CDropdownItem href="#/settings/system-info">
                  <SettingIcon />
                  Cài đặt hệ thống
                </CDropdownItem>
              ) : null}
              <CDropdownItem>
                <UserPlusIcon />
                Mời bạn bè
              </CDropdownItem>
              <CDropdownItem onClick={redirectView("/settings")}>
                <SettingIcon />
                Hỗ trợ & FAQs
              </CDropdownItem>
              <CDropdownDivider />
              <CDropdownItem href="#/login" onClick={onLogout}>
                <LogoutIcon />
                Đăng xuất
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CHeaderNav>
      </CContainer>
      {/* <CHeaderDivider /> */}
      {/* <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
      {headerItem && headerItem?.tabItems ? (
        <CContainer
          fluid
          className=" page-tab-container"
          style={{ overflow: "hidden" }}
        >
          <CNav
            variant="underline"
            role="tablist"
            className="custom-nav hidden-scrollbar"
            style={{ overflow: "auto hidden", flexWrap: "nowrap" }}
          >
            {headerItem.tabItems.map((item, index) => {
              const { name, path } = item;
              const isActive = matchPath(
                { path, end: false },
                location.pathname
              );

              if ((user?.role && !checkUserAdmin(user?.role)) 
                && (item?.permission && !hasPermission(user?.permissionGroup, item?.permission?.[0]))) {
                return <></>
              }
              return (
                <CNavItem
                  role="presentation"
                  key={index}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <CNavLink
                    style={{ whiteSpace: "nowrap" }}
                    active={Boolean(isActive)}
                    component="button"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected={Boolean(isActive)}
                    onClick={redirectView(path)}
                  >
                    {name}
                  </CNavLink>
                </CNavItem>
              );
            })}
          </CNav>
          {headerItem.rightComponent ? headerItem.rightComponent : null}
        </CContainer>
      ) : (
        ""
      )}
    </CHeader>
  );
};

export default TheHeader;
