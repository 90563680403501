export enum TaskCategoryEnum {
  RENDER = "RENDER",
  MODELING = "MODELING",
  ANIMATION = "ANIMATION",
  EDITOR = "EDITOR",
  CONCEPT = "CONCEPT",
  TEXTURE = "TEXTURE",
}

export const taskCategoryArray: TaskCategoryEnum[] = [
  TaskCategoryEnum.RENDER,
  TaskCategoryEnum.MODELING,
  TaskCategoryEnum.ANIMATION,
  TaskCategoryEnum.EDITOR,
  TaskCategoryEnum.CONCEPT,
  TaskCategoryEnum.TEXTURE,
];

export const mapTaskCategoryToString: { [key in TaskCategoryEnum]: string } = {
  [TaskCategoryEnum.RENDER]: "Render",
  [TaskCategoryEnum.MODELING]: "Modeling",
  [TaskCategoryEnum.ANIMATION]: "Animation",
  [TaskCategoryEnum.EDITOR]: "Editor",
  [TaskCategoryEnum.CONCEPT]: "Concept",
  [TaskCategoryEnum.TEXTURE]: "Texture",
};

// Task Status

export enum TaskStatusEnum {
  NEWTASK = "NEWTASK", // Cần làm
  PROCESSING = "PROCESSING", // Đang thực hiện
  REVIEW = "REVIEW", // Review
  COMPLETED = "COMPLETED", // Hoàn thành
  DELETED = "DELETED", // Đã xóa
}

export enum SubTaskStatusEnum {
  PROCESSING = "PROCESSING", // Đang thực hiện
  REVIEW = "REVIEW", // Review
  COMPLETED = "COMPLETED", // Hoàn thành
  DELETED = "DELETED", // Đã xóa
  NEW = "NEW",
}

export const taskStatusArray: TaskStatusEnum[] = [
  TaskStatusEnum.NEWTASK,
  TaskStatusEnum.PROCESSING,
  TaskStatusEnum.REVIEW,
  TaskStatusEnum.COMPLETED,
  TaskStatusEnum.DELETED,
];

export const subTaskStatusArray: SubTaskStatusEnum[] = [
  SubTaskStatusEnum.NEW,
  SubTaskStatusEnum.PROCESSING,
  SubTaskStatusEnum.REVIEW,
  SubTaskStatusEnum.COMPLETED,
  SubTaskStatusEnum.DELETED,
];

export const mapTaskStatusToString: { [key in TaskStatusEnum]: string } = {
  [TaskStatusEnum.NEWTASK]: "Chưa bắt đầu",
  [TaskStatusEnum.PROCESSING]: "Đang thực hiện",
  [TaskStatusEnum.REVIEW]: "Review",
  [TaskStatusEnum.COMPLETED]: "Hoàn thành",
  [TaskStatusEnum.DELETED]: "Đã xóa",
};

export const subMapTaskStatusToString: { [key in SubTaskStatusEnum]: string } = {
  [SubTaskStatusEnum.NEW]: "Chưa bắt đầu",
  [SubTaskStatusEnum.PROCESSING]: "Đang thực hiện",
  [SubTaskStatusEnum.REVIEW]: "Review",
  [SubTaskStatusEnum.COMPLETED]: "Hoàn thành",
  [SubTaskStatusEnum.DELETED]: "Đã xóa",
};

export const mapTaskStatusToColor: { [key in TaskStatusEnum]: string } = {
  [TaskStatusEnum.NEWTASK]: "gray-modern-200",
  [TaskStatusEnum.PROCESSING]: "blue-600",
  [TaskStatusEnum.REVIEW]: "yellow-400",
  [TaskStatusEnum.COMPLETED]: "green-500",
  [TaskStatusEnum.DELETED]: "error-600",
};

export const subMapTaskStatusToColor: { [key in SubTaskStatusEnum]: string } = {
  [SubTaskStatusEnum.PROCESSING]: "blue-600",
  [SubTaskStatusEnum.REVIEW]: "yellow-400",
  [SubTaskStatusEnum.COMPLETED]: "green-500",
  [SubTaskStatusEnum.DELETED]: "error-600",
  [SubTaskStatusEnum.NEW]: 'gray-modern-200'
};

export const mapOverviewStatusToString: { [key in TaskStatusEnum]: string } = {
  [TaskStatusEnum.NEWTASK]: "Chưa bắt đầu",
  [TaskStatusEnum.PROCESSING]: "Đang thực hiện",
  [TaskStatusEnum.REVIEW]: "Review",
  [TaskStatusEnum.COMPLETED]: "Đã kết thúc",
  [TaskStatusEnum.DELETED]: "Đã xóa",
};

export const subMapOverviewStatusToString: { [key in SubTaskStatusEnum]: string } = {
  [SubTaskStatusEnum.PROCESSING]: "Đang thực hiện",
  [SubTaskStatusEnum.REVIEW]: "Review",
  [SubTaskStatusEnum.COMPLETED]: "Đã kết thúc",
  [SubTaskStatusEnum.DELETED]: "Đã xóa",
  [SubTaskStatusEnum.NEW]: 'Chưa bắt đầu'
};

export const mapOverviewStatusToColor: { [key in TaskStatusEnum]: string } = {
  [TaskStatusEnum.NEWTASK]: "gray-modern-200",
  [TaskStatusEnum.PROCESSING]: "blue-600",
  [TaskStatusEnum.REVIEW]: "yellow-400",
  [TaskStatusEnum.COMPLETED]: "green-500",
  [TaskStatusEnum.DELETED]: "error-600",
};

export const subMapOverviewStatusToColor: { [key in SubTaskStatusEnum]: string } = {
  [SubTaskStatusEnum.PROCESSING]: "blue-600",
  [SubTaskStatusEnum.REVIEW]: "yellow-400",
  [SubTaskStatusEnum.COMPLETED]: "green-500",
  [SubTaskStatusEnum.DELETED]: "error-600",
  [SubTaskStatusEnum.NEW]: 'gray-modern-200'
};

// Task Type

export enum TaskTypeEnum {
  FOLDER = "FOLDER",
  TASK = "TASK",
}

export const taskTypeArray: TaskTypeEnum[] = [
  TaskTypeEnum.FOLDER,
  TaskTypeEnum.TASK,
];

export const mapTaskTypeToString: { [key in TaskTypeEnum]: string } = {
  [TaskTypeEnum.FOLDER]: "Folder",
  [TaskTypeEnum.TASK]: "Task",
};

export enum taskIdNameDefault {
  TOPPROJECT = "topProject",
}
