export enum TimeFormat {
  STANDARD = "STANDARD",
  TIMECODE = "TIMECODE",
  FRAME = "FRAME",
}

export const timeFormatArray: TimeFormat[] = [
  TimeFormat.STANDARD,
  TimeFormat.TIMECODE,
  TimeFormat.FRAME,
];

export const mapTimeFormatToString: { [key in TimeFormat]: string } = {
  [TimeFormat.STANDARD]: "Standard",
  [TimeFormat.TIMECODE]: "Timecode",
  [TimeFormat.FRAME]: "Frame",
};
