import { IInitialState, IResponse } from "@/shared/shared-interfaces";
import {
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "@/reducers";
import { AxiosResponse } from "axios";
import {
    createEntity,
    getEntities,
    IDictionaryChildrenParams,
    removeEntity,
    updateEntity,
} from "./dictionaryManagement.api";
import { IDictionaryChildrenManagement } from "@/shared/model/dictionaryManagement.modal";

interface IDictionaryChildrenManagementInitialState extends IInitialState {
    updateEntityStatusSuccess: boolean;
    filterState: IDictionaryChildrenParams;
    allUser: IDictionaryChildrenManagement[];
}

export const initialDictionaryChildrenFilter: IDictionaryChildrenParams = {
    page: 1,
    limit: 10,
    sortBy: "createdDate",
    sortOrder: "DESC",
};

const initialState: IDictionaryChildrenManagementInitialState = {
    fetchEntitiesSuccess: false,
    fetchEntitySuccess: false,
    updateEntitySuccess: false,
    deleteEntitySuccess: false,
    loading: false,
    errorMessage: null,
    totalItems: 0,
    totalPages: 0,
    updateEntityStatusSuccess: false,
    filterState: initialDictionaryChildrenFilter,
    allUser: [],
};

export const dictionaryChildrenAdapter =
    createEntityAdapter<IDictionaryChildrenManagement>({
        selectId: ({ dictItemId }) => dictItemId,
    });

const { actions, reducer } = createSlice({
    name: "dictionaryChildrenSlice",
    initialState: dictionaryChildrenAdapter.getInitialState({ initialState }),
    reducers: {
        setFilterState(
            state,
            { payload }: PayloadAction<IDictionaryChildrenParams>
        ) {
            state.initialState.filterState = payload;
        },
        fetching(state) {
            state.initialState.loading = true;
        },
        resetAll(state) {
            state.initialState.loading = false;
            state.initialState.fetchEntitiesSuccess = false;
            state.initialState.fetchEntitySuccess = false;
            state.initialState.updateEntitySuccess = false;
            state.initialState.deleteEntitySuccess = false;
            state.initialState.errorMessage = null;
        },
        resetEntity(state) {
            state.initialState.updateEntitySuccess = false;
            state.initialState.errorMessage = null;
            state.initialState.deleteEntitySuccess = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getEntities.fulfilled.type,
            (
                state,
                {
                    payload,
                }: PayloadAction<
                    AxiosResponse<IResponse<IDictionaryChildrenManagement[]>>
                >
            ) => {
                dictionaryChildrenAdapter.setAll(state, payload.data.data);
                state.initialState.totalItems = Number(payload.data.count);
                state.initialState.totalPages = Number(
                    payload.data.total_pages
                );
                state.initialState.fetchEntitiesSuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            getEntities.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.fetchEntitiesSuccess = false;
            }
        );

        // builder.addCase(
        //     getEntity.fulfilled.type,
        //     (state, { payload }: PayloadAction<IUser>) => {
        //         usersAdapter.upsertOne(state, payload);
        //         state.initialState.fetchEntitySuccess = true;
        //         state.initialState.loading = false;
        //     }
        // );
        // builder.addCase(
        //     getEntity.rejected.type,
        //     (state, { payload }: PayloadAction<any>) => {
        //         state.initialState.errorMessage = payload?.message;
        //         state.initialState.loading = false;
        //         state.initialState.fetchEntitySuccess = false;
        //     }
        // );
        builder.addCase(
            updateEntity.fulfilled.type,
            (
                state,
                { payload }: PayloadAction<IDictionaryChildrenManagement>
            ) => {
                dictionaryChildrenAdapter.updateOne(state, {
                    id: payload.dictItemId,
                    changes: payload,
                });
                state.initialState.updateEntitySuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            updateEntity.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.updateEntitySuccess = false;
            }
        );
        builder.addCase(
            createEntity.fulfilled.type,
            (
                state,
                { payload }: PayloadAction<IDictionaryChildrenManagement>
            ) => {
                //@ts-ignore
                if (payload?.code == 400) {
                    state.initialState.updateEntitySuccess = false;
                    state.initialState.loading = false;
                    return;
                }
                dictionaryChildrenAdapter.addOne(state, payload);
                state.initialState.updateEntitySuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            createEntity.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.updateEntitySuccess = false;
            }
        );
        builder.addCase(
            removeEntity.fulfilled.type,
            (state, { payload }: PayloadAction<string>) => {
                // dictionaryChildrenAdapter.removeOne(state, payload);
                state.initialState.deleteEntitySuccess = true;
                state.initialState.totalItems -= 1;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            removeEntity.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.deleteEntitySuccess = false;
            }
        );
        // builder.addCase(
        //     updateDeleteEntity.fulfilled.type,
        //     (state, { payload }: PayloadAction<string[]>) => {
        //         usersAdapter.removeMany(state, payload);
        //         state.initialState.totalItems -= payload.length;
        //         state.initialState.deleteEntitySuccess = true;
        //         state.initialState.loading = false;
        //     }
        // );
        // builder.addCase(
        //     updateDeleteEntity.rejected.type,
        //     (state, { payload }: PayloadAction<any>) => {
        //         state.initialState.errorMessage = payload?.message;
        //         state.initialState.loading = false;
        //         state.initialState.deleteEntitySuccess = false;
        //     }
        // );

        // builder.addCase(
        //     getAllEntities.fulfilled.type,
        //     (state, { payload }: PayloadAction<any>) => {
        //         state.initialState.allUser = payload?.data?.data;
        //         state.initialState.loading = false;
        //     }
        // );
        // builder.addCase(
        //     getAllEntities.rejected.type,
        //     (state, { payload }: PayloadAction<any>) => {
        //         state.initialState.errorMessage = payload?.message;
        //         state.initialState.loading = false;
        //     }
        // );
    },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const dictionaryChildrenSelectors =
    dictionaryChildrenAdapter.getSelectors<RootState>(
        (state) => state.dictionaryChildrenReducer
    );

const { selectById } = dictionaryChildrenAdapter.getSelectors();
const getDictionaryChildrenState = (rootState: RootState) =>
    rootState.dictionaryChildrenReducer;

export const selectEntityById = (id: string) => {
    return createSelector(getDictionaryChildrenState, (state) =>
        selectById(state, id)
    );
};
