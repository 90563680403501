export enum ErrorMessage {
    PROJECT_DOES_NOT_EXIST = "PROJECT_DOES_NOT_EXIST",
    VIDEO_DOES_NOT_EXIST = "VIDEO_DOES_NOT_EXIST",
    LAYER_DOES_NOT_EXIST = "LAYER_DOES_NOT_EXIST",
    USER_DOES_NOT_EXIST = "USER_DOES_NOT_EXIST",
    USER_OR_PASSWORD_INVALID = "USER_OR_PASSWORD_INVALID",
    INVALID_OLD_PASSWORD = "INVALID_OLD_PASSWORD",
    CONFIRM_PASSWORD_INVALID = "CONFIRM_PASSWORD_INVALID",
    USER_NAME_EXIST = "USER_NAME_EXIST",
    EMAIL_ALREADY_EXIST = "EMAIL_ALREADY_EXIST",
    PERMISSION_GROUP_DOES_NOTE_EXIST = "PERMISSION_GROUP_DOES_NOTE_EXIST",
    SERVER_CANNOT_SEND_MAIL = "SERVER_CANNOT_SEND_MAIL",
    NEW_PASSWORD_HAS_BEEN_SENT_TO_YOUR_MAIL = "NEW_PASSWORD_HAS_BEEN_SENT_TO_YOUR_MAIL",
    ROOM_CHAT_NOT_EXIST = "ROOM_CHAT_NOT_EXIST",
    CANNOT_VIEW_DETAIL_ROOM_CHAT = "CANNOT_VIEW_DETAIL_ROOM_CHAT",
    ROOM_CHAT_NAME_EXIST = "ROOM_CHAT_NAME_EXIST",
    DATA_TYPE_ERROR = "DATA_TYPE_ERROR",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    FORBIDDEN = "FORBIDDEN",
    CODE_EXIST = "CODE_EXIST",
    PROJECT_ID_NO_DATA = "PROJECT_ID_NO_DATA",
    NO_DATA = "NO_DATA",
    RESULT_TASK_DOES_NOTE_EXIST = "RESULT_TASK_DOES_NOTE_EXIST",

    // Thảo luận
    DISCUSSION_NOT_EXIST = "DISCUSSION_NOT_EXIST",
    PARRENT_DISCUSSION_NOT_EXIST = "PARRENT_DISCUSSION_NOT_EXIST",
    NO_PERMISSION_UPDATE_DISCUSSION = "NO_PERMISSION_UPDATE_DISCUSSION",
    CANNOT_CREATE_DISCUSSION = "CANNOT_CREATE_DISCUSSION",
    CREATE_DISCUSSION_LEVEL1_EXIST_PARENTID = "CREATE_DISCUSSION_LEVEL1_EXIST_PARENTID",
    CREATE_DISCUSSION_LEVEL2_3_NOT_EXIST_PARENTID = "CREATE_DISCUSSION_LEVEL2_3_NOT_EXIST_PARENTID",
    UPDATE_DISCUSSION_DTO_ERROR = "UPDATE_DISCUSSION_DTO_ERROR",
    CREATE_DISCUSSION_DTO_ERROR_LEVEL_WITH_PARENTID = "CREATE_DISCUSSION_DTO_ERROR_LEVEL_WITH_PARENTID",
    LEVEL_CHILD_AND_PARENTID_LEVEL_CONFLICT = "LEVEL_CHILD_AND_PARENTID_LEVEL_CONFLICT",
    USER_NOT_IN_PROJECT = "USER_NOT_IN_PROJECT",

    // Code for chat
    CANNOT_CREATE_ROOM = "CANNOT_CREATE_ROOM",
    ROOM_MUST_BE_AT_LEAST_2_USER = "ROOM_MUST_BE_AT_LEAST_2_USER",
    ROOM_HAS_USERS_EXIST = "ROOM_HAS_USERS_EXIST",
    INDIVIDUAL_TYPE_MESSAGE = "INDIVIDUAL_TYPE_MESSAGE",

    TASK_ID_NO_DATA = "TASK_ID_NO_DATA",
    TASK_NOT_EXIST = "TASK_NOT_EXIST",
    PROJECT_NOT_HAS_TASK = "PROJECT_NOT_HAS_TASK",
    LEADER_NOT_STAFF_IN_PROJECT = "LEADER_NOT_STAFF_IN_PROJECT",

    SCHEDULE_NOT_EXIST = "SCHEDULE_NOT_EXIST",

    SYSTEM_ERROR = "SYSTE",
    DATA_NOT_EXIST = "DATA_NOT_EXIST",
    DATA_NOT_SAVE = "DATA_NOT_SAVE",
    INVALID_INPUT = "INVALID_INPUT",
    DATA_NOT_DELETE = "DATA_NOT_DELETE",

    UNAUTHORIZED = "UNAUTHORIZED",

    DICT_TYPE_REQUIRED = "DICT_TYPE_REQUIRED",
    DICT_TYPE_EXISTED = "DICT_TYPE_EXISTED",

    CANNOT_CREATE_TASK = "CANNOT_CREATE_TASK",

    CANNOT_CREATE_ASSIGN = "CANNOT_CREATE_ASSIGN",
    DATA_ASSIGN_NOT_EXIST = "DATA_ASSIGN_NOT_EXIST",

    // Dict - DictItem
    CANNOT_DELETE_DICT = "CANNOT_DELETE_DICT",
    ITEM_VALUE_REQUIRED = "ITEM_VALUE_REQUIRED",
    LABEL_REQUIRED = "LABEL_REQUIRED",
    ITEM_VALUE_EXISTED = "ITEM_VALUE_EXISTED",
    LABEL_EXISTED = "LABEL_EXISTED",
    INVALID_PASSWORD = "INVALID_PASSWORD",
}

export const mapErrorMessage: { [key in ErrorMessage]: string } = {
    [ErrorMessage.PROJECT_DOES_NOT_EXIST]: "Project không tồn tại",
    [ErrorMessage.VIDEO_DOES_NOT_EXIST]: "Video không tồn tại",
    [ErrorMessage.LAYER_DOES_NOT_EXIST]: "Layer không tồn tại",
    [ErrorMessage.USER_DOES_NOT_EXIST]: "Tài khoản không tồn tại",
    [ErrorMessage.USER_OR_PASSWORD_INVALID]:
        "Tên tài khoản hoặc mật khẩu không đúng",
    [ErrorMessage.INVALID_OLD_PASSWORD]: "Mật khẩu cũ không đúng",
    [ErrorMessage.CONFIRM_PASSWORD_INVALID]: "Mật khẩu xác nhận không khớp",
    [ErrorMessage.USER_NAME_EXIST]: "Username đã tồn tại",
    [ErrorMessage.EMAIL_ALREADY_EXIST]: "Email đã tồn tại",
    [ErrorMessage.PERMISSION_GROUP_DOES_NOTE_EXIST]: "Nhóm quyền không tồn tại",
    [ErrorMessage.SERVER_CANNOT_SEND_MAIL]: "Lỗi không thể gửi email",
    [ErrorMessage.NEW_PASSWORD_HAS_BEEN_SENT_TO_YOUR_MAIL]:
        "Mật khẩu mới đã được gửi đến mail của bạn",
    [ErrorMessage.ROOM_CHAT_NOT_EXIST]: "Phòng chat không tồn tại",
    [ErrorMessage.CANNOT_VIEW_DETAIL_ROOM_CHAT]:
        "Bạn không có quyền xem phòng chat này",
    [ErrorMessage.ROOM_CHAT_NAME_EXIST]: "Tên phòng chat đã tồn tại",
    [ErrorMessage.CANNOT_CREATE_ROOM]: "Bạn không thể tạo phòng chat này",
    [ErrorMessage.DATA_TYPE_ERROR]: "Dữ liệu truyền lên không đúng định dạng",
    [ErrorMessage.INTERNAL_SERVER_ERROR]:
        "Hệ thống đang bận, vui lòng thử lại sau",
    [ErrorMessage.FORBIDDEN]: "Không có quyền truy cập",
    [ErrorMessage.CODE_EXIST]: "Mã code đã tồn tại",
    [ErrorMessage.DISCUSSION_NOT_EXIST]: "Thảo luận không tồn tại",
    [ErrorMessage.PARRENT_DISCUSSION_NOT_EXIST]: "Thảo luận cha không tồn tại",
    [ErrorMessage.NO_PERMISSION_UPDATE_DISCUSSION]:
        "Không có quyền cập nhật thảo luận này",
    [ErrorMessage.CANNOT_CREATE_DISCUSSION]: "Không thể tạo thảo luận",
    [ErrorMessage.TASK_NOT_EXIST]: "Thư mục không tồn tại",
    [ErrorMessage.PROJECT_NOT_HAS_TASK]: "Dự án không có task này",
    [ErrorMessage.CREATE_DISCUSSION_LEVEL1_EXIST_PARENTID]:
        "Không thể tạo thảo luận cấp 1 khi có cha",
    [ErrorMessage.CREATE_DISCUSSION_LEVEL2_3_NOT_EXIST_PARENTID]:
        "Không thể tạo thảo luận cấp 2,3 nếu thiếu cha",
    [ErrorMessage.UPDATE_DISCUSSION_DTO_ERROR]:
        "Chỉ cập nhật thảo luận 2 trường content và image",
    [ErrorMessage.CREATE_DISCUSSION_DTO_ERROR_LEVEL_WITH_PARENTID]:
        "Lỗi dữ liệu tạo thảo luận giữa level và parentId",
    [ErrorMessage.LEVEL_CHILD_AND_PARENTID_LEVEL_CONFLICT]:
        "Lỗi ràng buộc cấp thảo luận con và cha",
    [ErrorMessage.USER_NOT_IN_PROJECT]:
        "Không thể tạo thảo luận khi không ở trong dự án này",
    [ErrorMessage.SCHEDULE_NOT_EXIST]: "không tìm thấy lịch",
    [ErrorMessage.SYSTEM_ERROR]: "Lỗi hệ thống đang bận",
    [ErrorMessage.DATA_NOT_EXIST]: "Dữ liệu không tồn tại",
    [ErrorMessage.UNAUTHORIZED]: "Lỗi xác thực",
    [ErrorMessage.CANNOT_CREATE_TASK]: "Không thể tạo task",
    [ErrorMessage.CANNOT_CREATE_ASSIGN]: "Không thể tạo assign",
    [ErrorMessage.DATA_ASSIGN_NOT_EXIST]: "Dữ liệu assign không tồn tại",
    [ErrorMessage.DICT_TYPE_REQUIRED]: "Dict type không được để trống",
    [ErrorMessage.DICT_TYPE_EXISTED]: "Dict type đã tồn tại",
    [ErrorMessage.CANNOT_DELETE_DICT]: "Không thể xóa danh mục cha",
    [ErrorMessage.ITEM_VALUE_REQUIRED]: "Gía trị không được để trống",
    [ErrorMessage.LABEL_REQUIRED]: "Nhãn không được để trống",
    [ErrorMessage.ITEM_VALUE_EXISTED]: "Giá trị đã tồn tại",
    [ErrorMessage.LABEL_EXISTED]: "Nhãn đã tồn tại",
    [ErrorMessage.DATA_NOT_SAVE]: "Dữ liệu chưa được lưu",
    [ErrorMessage.INVALID_INPUT]: "Dữ liệu không hợp lệ",
    [ErrorMessage.DATA_NOT_DELETE]: "Dữ liệu chưa được xóa",
    [ErrorMessage.INDIVIDUAL_TYPE_MESSAGE]:
        "Tin nhắn riêng, không thể đổi ảnh đại diện cuộc trò chuyện",
    [ErrorMessage.INVALID_PASSWORD]: "Định dạng mật khẩu không hợp lệ",
    [ErrorMessage.PROJECT_ID_NO_DATA]: "Không tìm thấy Project",
    [ErrorMessage.NO_DATA]: "Không có dữ liệu",
    [ErrorMessage.RESULT_TASK_DOES_NOTE_EXIST]:
        "Kết quả của công việc không tồn tại",
    [ErrorMessage.ROOM_MUST_BE_AT_LEAST_2_USER]: "Cần ít nhất 2 người để tạo",
    [ErrorMessage.ROOM_HAS_USERS_EXIST]: "Người dùng đã có trong phòng",
    [ErrorMessage.TASK_ID_NO_DATA]: "Công việc không tồn tại",
    [ErrorMessage.LEADER_NOT_STAFF_IN_PROJECT]: "Không có quản lý trong dự án",
};
