import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../../../reducers";
import { useRouter } from "../../../shared/utils/hooks/useRouter";

interface IRequireAuthProp {
  children: React.ReactNode;
}

export const RequireAuth = ({ children }: IRequireAuthProp) => {
  const { location } = useRouter();
  const { user, firstTimeLogin } = useSelector(
    (state: RootState) => state.authentication
  );
  const [firstLogin, setFirstLogin] = useState(firstTimeLogin);

  useEffect(() => {
    if (!firstTimeLogin) {
      const isFirstTime = localStorage.getItem("first_login");
      if (isFirstTime) setFirstLogin(JSON.parse(isFirstTime));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTimeLogin]);

  if (firstLogin) {
    return <Navigate to="/reset" replace state={{ path: location.pathname }} />;
  }

  if (!user) {
    // if (isBO) return <Navigate to="/auth/login" state={{ path: location.pathname }} />;
    return <Navigate to="/login" replace state={{ path: location.pathname }} />;
  }

  return <>{children}</>;
};
