import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react-pro";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fontSize, groupDropDown } from "./style";
import { IParams } from "@/shared/shared-interfaces";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store";
import {
  taskStatusArray,
  TaskStatusEnum,
} from "@/shared/enumeration/task.enum";

export interface mode {
  name: string;
  value: string;
  field?: string;
}
export interface ICustomDropDownButton {
  activeMode: mode;
  setMode: (mode: mode) => void;
  listMode: mode[];
  filterState?: IParams;
  getEntities?: Function;
  projectId?: string;
  setFilterState: Function;
  type?: "select" | "search";
  keySearch?: string;
}
function CustomDropDownButton(props: ICustomDropDownButton) {
  const {
    activeMode,
    setMode,
    listMode,
    filterState,
    getEntities,
    projectId,
    setFilterState,
    type = "select",
    keySearch,
  } = props;
  const dispatch = useDispatch<AppDispatch>();

  const handleFilter = (value: mode) => {
    if (type === "search" && keySearch) {
      return dispatch(
        setFilterState({
          ...filterState,
          [keySearch]: value.value,
          page: 1,
        })
      );
    }
    if (
      value?.value &&
      taskStatusArray.includes(value.value as TaskStatusEnum)
    ) {
      return dispatch(
        setFilterState({
          ...filterState,
          status: value.value as TaskStatusEnum,
          page: 1,
        })
      );
    }

    const newFilterState = {
      ...filterState,
      sortBy: value.field,
      sortOrder: value.value,
      projectId: projectId ? projectId : "",
      page: 1,
    };
    dispatch(setFilterState(newFilterState));
  };

  return (
    <>
      <CDropdown className="">
        <CDropdownToggle className="display-none-after" style={groupDropDown}>
          <div
            className="d-flex align-items-center px-2"
            style={{
              gap: "6px",
              padding: "4px 4px",
              cursor: "pointer",
              color: "#364152",
            }}
          >
            <span style={fontSize}>{activeMode.name}</span>
            <FontAwesomeIcon width={20} height={20} icon={faChevronDown} />
          </div>
        </CDropdownToggle>
        <CDropdownMenu className="dropdown-menu custom-select">
          {listMode.map((value: mode, index: number) => {
            return (
              <CDropdownItem
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleFilter(value);
                  setMode(value);
                }}
              >
                <div>{value.name}</div>
              </CDropdownItem>
            );
          })}
        </CDropdownMenu>
      </CDropdown>
    </>
  );
}

export default CustomDropDownButton;
