import React, { ReactNode } from "react";

interface TheLayoutPreviewProps {
  children: ReactNode;
}

const TheLayoutPreview: React.FC<TheLayoutPreviewProps> = ({ children }) => {
  return <div className="layout-preview">{children}</div>;
};

export default TheLayoutPreview;
