import React from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import TheLayout from "./app/containers/components/TheLayout";
import AccountSettingRoutes from "./app/modules/AccountSetting/routes";
import { RequireAuth } from "./app/modules/auth/RequireAuth";
import ChatRoutes from "./app/modules/Chat/routes";
import ProjectManagementRoutes from "./app/modules/Project/routes";
import PermissionsManagementRoutes from "./app/modules/SystemSetting/PermissionGroup/routes";
import UsersManagementRoutes from "./app/modules/SystemSetting/UserManagement/routes";
import MyTaskRoutes from "./app/modules/MyTask/routers";
import DepartmentRoutes from "./app/modules/department/routers";
import CalenderRoutes from "./app/modules/Calender/routers";
import DictionaryRoutes from "./app/modules/SystemSetting/DictionaryManagement/routes";
import MyReportRoutes from "./app/modules/report/routers";
import TheLayoutPreview from "./app/containers/components/TheLayoutPreview";
import { Permission } from "./shared/enumeration/permission";
import ProtectedPermission from "./app/modules/auth/ProtectedPermission";

const TheVideoLayout = React.lazy(
  () => import("./app/shared/TheVideoLayout/TheVideoLayout")
);

const ForgotPassword = React.lazy(
  () => import("./app/modules/auth/ForgotPassword")
);
const ResetPassword = React.lazy(
  () => import("./app/modules/auth/ResetPassword")
);
const Login = React.lazy(() => import("./app/modules/auth/Login"));
const Register = React.lazy(() => import("./app/modules/auth/Register"));
const ResetSuccess = React.lazy(
  () => import("./app/modules/auth/ResetSuccess")
);

// Error pages

const Page404 = React.lazy(() => import("./app/modules/PageError/Page404"));
const Page500 = React.lazy(() => import("./app/modules/PageError/Page500"));
const Page403 = React.lazy(() => import("./app/modules/PageError/Page403"));

const settingRoutes: RouteObject[] = [
  // { path: 'plan-notification-setting/*', element: <PlanNotificationSettingRoutes /> },
  { path: "users/*", element: <ProtectedPermission requiredPerms={[Permission.USER_VIEW]}><UsersManagementRoutes /></ProtectedPermission> },
  { path: "permissions/*", element: <ProtectedPermission requiredPerms={[Permission.PERMISSION_GROUP_VIEW]}><PermissionsManagementRoutes /></ProtectedPermission> },
  { path: "dictionary-management/*", element: <ProtectedPermission requiredPerms={[Permission.DICT_VIEW]}><DictionaryRoutes /></ProtectedPermission> },
];

const privateRoutes: RouteObject[] = [
  { path: "", element: <MyTaskRoutes /> },
  { path: "project/*", element: <ProtectedPermission requiredPerms={[Permission.PROJECT_LIST]}><ProjectManagementRoutes /></ProtectedPermission> },
  { path: "account/*", element: <AccountSettingRoutes /> },
  {
    path: "settings/*",
    children: settingRoutes,
    caseSensitive: true,
  },
  { path: "message/*", element: <ProtectedPermission requiredPerms={[Permission.MESSAGE_VIEW]}><ChatRoutes /></ProtectedPermission> },
  { path: "my-task/*", element: <MyTaskRoutes /> },
  // { path: "/*", element: <MyTaskRoutes /> },
  { path: "department/*", element: <ProtectedPermission requiredPerms={[Permission.DEPARTMENT_VIEW]}><DepartmentRoutes /></ProtectedPermission> },
  { path: "calendar/*", element: <ProtectedPermission requiredPerms={[Permission.SCHEDULE_VIEW]}><CalenderRoutes /></ProtectedPermission> },
  { path: "report/*", element:  <ProtectedPermission requiredPerms={[Permission.REPORT_VIEW]}><MyReportRoutes /></ProtectedPermission> },

  { path: '*', element: <Navigate to="/404" replace /> },
];

const publicRoutes: RouteObject[] = [
  {
    path: "/*",
    element: (
      <RequireAuth>
        <TheLayout />
      </RequireAuth>
    ),
    children: privateRoutes,
    caseSensitive: true,
  },
  {
    path: "preview/:id/playlist",
    element: (
      <TheLayoutPreview>
        <TheVideoLayout />
      </TheLayoutPreview>
    ),
  },
  { path: "/404", element: <Page404 /> },
  { path: "/500", element: <Page500 /> },
  { path: "/403", element: <Page403 /> },
  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
  { path: "/forgot", element: <ForgotPassword /> },
  { path: "/reset", element: <ResetPassword /> },
  { path: "/reset-success", element: <ResetSuccess /> },
  // { path: '/auth/*', element: <AuthRoutes /> },
  { path: "*", element: <Navigate to="/404" replace /> },
];

const RouteRender = () => useRoutes(publicRoutes);

// const BackOfficeRender = () => useRoutes(backOffice);

// const BackOfficeRender = () => {
//   const { location } = useRouter();

//   const { user } = useSelector((state: RootState) => state.authentication);

//   const routes = useRoutes(
//     Boolean(user)
//       ? backOffice
//       : [{ path: '*', element: <Navigate to="/auth/login" state={{ path: location.pathname }} /> }]
//   );
//   return routes;
// };

export { RouteRender, privateRoutes, publicRoutes };
