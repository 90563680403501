import { IInitialState, IResponse } from "@/shared/shared-interfaces";
import {
    createEntity,
    getDataDifferent,
    getEntities,
    IDictionaryParentParams,
    removeEntity,
    updateEntity,
} from "./dictionaryManagement.api";
import {
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "@/reducers";
import { AxiosResponse } from "axios";
import { IDictionaryParentManagement } from "@/shared/model/dictionaryManagement.modal";

interface IDictionaryParentManagementInitialState extends IInitialState {
    updateEntityStatusSuccess: boolean;
    filterState: IDictionaryParentParams;
    allUser: IDictionaryParentManagement[];
    dataDifferent: any;
}

export const initialDictionaryParentFilter: IDictionaryParentParams = {
    page: 1,
    limit: 10,
    sortBy: "createdDate",
    sortOrder: "DESC",
};

const initialState: IDictionaryParentManagementInitialState = {
    fetchEntitiesSuccess: false,
    fetchEntitySuccess: false,
    updateEntitySuccess: false,
    deleteEntitySuccess: false,
    loading: false,
    errorMessage: null,
    totalItems: 0,
    totalPages: 0,
    updateEntityStatusSuccess: false,
    filterState: initialDictionaryParentFilter,
    allUser: [],
    dataDifferent: []
};

export const dictionaryParentAdapter =
    createEntityAdapter<IDictionaryParentManagement>({
        selectId: ({ dictId }) => dictId,
    });

const { actions, reducer } = createSlice({
    name: "dictionaryParentSlice",
    initialState: dictionaryParentAdapter.getInitialState({ initialState }),
    reducers: {
        setFilterState(
            state,
            { payload }: PayloadAction<IDictionaryParentParams>
        ) {
            state.initialState.filterState = payload;
        },
        fetching(state) {
            state.initialState.loading = true;
        },
        resetAll(state) {
            state.initialState.loading = false;
            state.initialState.fetchEntitiesSuccess = false;
            state.initialState.fetchEntitySuccess = false;
            state.initialState.updateEntitySuccess = false;
            state.initialState.deleteEntitySuccess = false;
            state.initialState.errorMessage = null;
        },
        resetEntity(state) {
            state.initialState.updateEntitySuccess = false;
            state.initialState.errorMessage = null;
            state.initialState.deleteEntitySuccess = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getEntities.fulfilled.type,
            (
                state,
                {
                    payload,
                }: PayloadAction<
                    AxiosResponse<IResponse<IDictionaryParentManagement[]>>
                >
            ) => {
                dictionaryParentAdapter.setAll(state, payload.data.data);
                state.initialState.totalItems = Number(payload.data.count);
                state.initialState.totalPages = Number(
                    payload.data.total_pages
                );
                state.initialState.fetchEntitiesSuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            getEntities.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.fetchEntitiesSuccess = false;
            }
        );

        // builder.addCase(
        //     getEntity.fulfilled.type,
        //     (state, { payload }: PayloadAction<IUser>) => {
        //         usersAdapter.upsertOne(state, payload);
        //         state.initialState.fetchEntitySuccess = true;
        //         state.initialState.loading = false;
        //     }
        // );
        // builder.addCase(
        //     getEntity.rejected.type,
        //     (state, { payload }: PayloadAction<any>) => {
        //         state.initialState.errorMessage = payload?.message;
        //         state.initialState.loading = false;
        //         state.initialState.fetchEntitySuccess = false;
        //     }
        // );
        builder.addCase(
            updateEntity.fulfilled.type,
            (
                state,
                { payload }: PayloadAction<IDictionaryParentManagement>
            ) => {
                dictionaryParentAdapter.updateOne(state, {
                    id: payload.dictId,
                    changes: payload,
                });
                state.initialState.updateEntitySuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            updateEntity.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.updateEntitySuccess = false;
            }
        );
        builder.addCase(
            createEntity.fulfilled.type,
            (
                state,
                { payload }: PayloadAction<IDictionaryParentManagement>
            ) => {
                dictionaryParentAdapter.addOne(state, payload);
                state.initialState.updateEntitySuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            createEntity.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.updateEntitySuccess = false;
            }
        );
        builder.addCase(
            removeEntity.fulfilled.type,
            (state, { payload }: PayloadAction<string>) => {
                // dictionaryParentAdapter.removeOne(state, payload);
                //@ts-ignore
                if (payload.data?.code === 400) {
                    state.initialState.deleteEntitySuccess = false;
                    state.initialState.loading = false;
                    return;
                }
                state.initialState.totalItems -= 1;
                state.initialState.deleteEntitySuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            removeEntity.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.deleteEntitySuccess = false;
            }
        );
        // builder.addCase(
        //     updateDeleteEntity.fulfilled.type,
        //     (state, { payload }: PayloadAction<string[]>) => {
        //         usersAdapter.removeMany(state, payload);
        //         state.initialState.totalItems -= payload.length;
        //         state.initialState.deleteEntitySuccess = true;
        //         state.initialState.loading = false;
        //     }
        // );
        // builder.addCase(
        //     updateDeleteEntity.rejected.type,
        //     (state, { payload }: PayloadAction<any>) => {
        //         state.initialState.errorMessage = payload?.message;
        //         state.initialState.loading = false;
        //         state.initialState.deleteEntitySuccess = false;
        //     }
        // );

        builder.addCase(
            getDataDifferent.fulfilled.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.dataDifferent = payload?.data?.data;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            getDataDifferent.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
            }
        );
    },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const dictionaryParentSelectors =
    dictionaryParentAdapter.getSelectors<RootState>(
        (state) => state.dictionaryParentReducer
    );

const { selectById } = dictionaryParentAdapter.getSelectors();
const getDictionaryParentState = (rootState: RootState) =>
    rootState.dictionaryParentReducer;

export const selectEntityById = (id: string) => {
    return createSelector(getDictionaryParentState, (state) =>
        selectById(state, id)
    );
};
