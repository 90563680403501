import {
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState } from "@/reducers";
import { IResponse, IInitialState } from "@/shared/shared-interfaces";
import { IPlaylist } from "@/shared/model/playlist.model";
import {
  createEntity,
  getEntities,
  getEntity,
  removeEntity,
  updateEntity,
} from "./playlist.api";

export const playlistAdapter = createEntityAdapter<IPlaylist>({
  selectId: ({ videoId }) => videoId,
});

export interface IInitialStatePlaylistReducer extends IInitialState {
  chosenPlaylist: IPlaylist | null;
}

const initialState: IInitialStatePlaylistReducer = {
  totalPages: 1,
  chosenPlaylist: null,
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

const { actions, reducer } = createSlice({
  name: "playlistSlice",
  initialState: playlistAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    setChosenPlaylist(state, action: PayloadAction<IPlaylist>) {
      state.initialState.chosenPlaylist = action.payload;
    },
    resetAll(state) {
      state.initialState.chosenPlaylist = null;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    setNewTimelineObj(state, { payload }: PayloadAction<IPlaylist>) {
      playlistAdapter.updateOne(state, {
        id: payload.videoId,
        changes: payload,
      });
      state.initialState.updateEntitySuccess = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<IPlaylist[]>>>
      ) => {
        playlistAdapter.setAll(state, payload?.data?.data);
        state.initialState.totalItems = Number(payload?.data?.count);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );

    builder.addCase(
      getEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IPlaylist>) => {
        playlistAdapter.upsertOne(state, payload);
        state.initialState.fetchEntitySuccess = true;
        state.initialState.loading = false;
      }
    );

    builder.addCase(
      getEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitySuccess = false;
      }
    );

    builder.addCase(
      updateEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IPlaylist>) => {
        playlistAdapter.updateOne(state, {
          id: payload.videoId,
          changes: payload,
        });
        state.initialState.updateEntitySuccess = true;
        state.initialState.loading = false;
      }
    );

    builder.addCase(
      updateEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
    builder.addCase(
      createEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IPlaylist>) => {
        playlistAdapter.addOne(state, payload);
        state.initialState.updateEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      createEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
    builder.addCase(
      removeEntity.fulfilled.type,
      (state, { payload }: PayloadAction<string>) => {
        playlistAdapter.removeOne(state, payload);
        state.initialState.totalItems -= 1;
        state.initialState.deleteEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      removeEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.deleteEntitySuccess = false;
      }
    );
  },
});

export default reducer;

export const {
  fetching,
  resetAll,
  resetEntity,
  setChosenPlaylist,
  setNewTimelineObj,
} = actions;
export const playlistSelectors = playlistAdapter.getSelectors(
  (state: RootState) => state.playReducer
);

const getPlaylistState = (state: RootState) => state.playReducer;
const { selectById } = playlistAdapter.getSelectors();

export const selectEntityById = (id: string) => {
  return createSelector(getPlaylistState, (state) => selectById(state, id));
};
