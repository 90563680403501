import { ReactComponent as FileUploadIcon } from "@/assets/img/file-upload-icon.svg";
import { ReactComponent as SeperateDot } from "@/assets/img/sperate-dot.svg";
import { ReactComponent as UploadFileIcon } from "@/assets/img/upload-file.svg";
import { CButton, CCol, CFormInput, CFormLabel, CRow } from "@coreui/react-pro";
import dayjs from "dayjs";
import { useDeviceDetect } from "../../../shared/utils/hooks/useDeviceDetect";
import {
  imageExtension,
  videoExtension,
} from "../AvatarUpload/AvatarUploadContainer";
import { ToastError } from "../toast/Toast";
import { FileType } from "./FormikMultiFileUpload";

export interface IFileUploadCustom {
  name: string;
  onFileChange: (fileArr: FileType[]) => void;
  fileValue: FileType[];
  label: React.ReactNode;
}

interface IFileContainer {
  index: number;
  file: FileType;
  handleRemoveFile?: (index: number) => () => void;
}

export const returnFileExtension = (srcFile: string) => {
  return srcFile.slice(((srcFile.lastIndexOf(".") - 1) >>> 0) + 2);
};

// const inputFileTypesAccept = imageExtension
//   .map((e) => `image/${e}`)
//   .concat(videoExtension.map((v) => `video/${v}`))
//   .toString();

export const checkErrorInputFile = (filesArr: File[]) => {
  let isValid = true;
  for (let i = 0; i < filesArr.length; i++) {
    const { size } = filesArr[i];
    // const isValidImgExtention = !imageExtension.filter((e) => type.indexOf(e) !== -1).length;
    const maximumSizeBytes = 1024 * 1024 * 10;

    // if (isValidImgExtention) {
    //   ToastError(`File tải lên phải có định dạng (${imageExtension.join(', ')})`);
    //   isValid = false;
    //   break;
    // }
    if (size > maximumSizeBytes) {
      ToastError("File tải lên vượt quá dung lượng cho phép 10MB");
      isValid = false;
      break;
    }
  }
  return isValid;
};

export const FileContainerVisibleEdit = (props: IFileContainer) => {
  const isMobile = useDeviceDetect();

  const { file, index, handleRemoveFile } = props;

  const fileExtension = returnFileExtension(file?.dataURL || "");
  const firstTenCharacters = file?.dataURL?.substring(0, 20) || "";
  const isImgType =
    firstTenCharacters.includes("data:image") ||
    imageExtension.includes(fileExtension);
  const isVideoType =
    firstTenCharacters.includes("data:video") ||
    videoExtension.includes(fileExtension);
  const isPdf =
    firstTenCharacters.includes("data:application/pdf") ||
    fileExtension.includes("pdf");

  const downloadFile = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = file.dataURL;
    downloadLink.target = "_blank";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="file-container d-flex justify-content-between flex-wrap cursor-pointer">
      <div
        className={`file-img-contain ${isImgType ? "" : "no-img"}`}
        onClick={() => {
          if (isImgType || isVideoType || isPdf) {
            downloadFile();
          }
        }}
      >
        {isImgType ? (
          <img src={file.dataURL} alt={`file-img-${index}`} />
        ) : (
          <FileUploadIcon className={"upload-icon"} />
        )}
      </div>
      <div className={`file-content  ${isMobile ? "w-100" : ""}`}>
        <p className="file-name text-break m-0" title={file?.name || ""}>
          {file?.name || ""}
        </p>

        <div
          className={`file-action-info d-flex justify-content-start align-items-center flex-wrap ${
            isMobile ? "w-100" : ""
          }`}
          style={{ gap: "10px" }}
        >
          {dayjs(file?.uploadDate || undefined).format("HH:mm:ss DD/MM/YYYY")}
          <div
            className="d-flex justify-content-start align-items-center"
            style={{ gap: "6px" }}
          >
            <a
              download={file.name}
              href={file.dataURL || ""}
              className="action-text cursor-pointer"
            >
              Tải xuống
            </a>
            {handleRemoveFile ? (
              <>
                <SeperateDot />
                <div
                  className="action-text cursor-pointer"
                  onClick={handleRemoveFile(index)}
                >
                  {" "}
                  Xoá
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const InstanceMultiFileUpload = (props: IFileUploadCustom) => {
  const { onFileChange, name, fileValue, label } = props;
  const images: FileType[] = fileValue;

  const fileToDataURI = (file: File): Promise<FileType> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileObj: FileType = {
          file: file,
          dataURL: e.target?.result as string,
          name: file.name,
          size: file.size,
          type: file.type,
          uploadDate: dayjs().toISOString(),
        };
        resolve(fileObj);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const inputFiles: File[] = e.target.files as File[];
    if (!checkErrorInputFile(inputFiles)) return;
    if (inputFiles && inputFiles.length > 0) {
      const newImagesPromises: Promise<FileType>[] = [];
      Array.from(inputFiles).forEach((item) => {
        newImagesPromises.push(fileToDataURI(item));
      });
      const newImages = await Promise.all(newImagesPromises);
      onFileChange([...images, ...newImages]);
    }
  };

  //   const handleRemoveAllImage = () => {
  //     onFileChange( []);
  //   };

  const handleRemoveFile = (index: number) => () => {
    const newImageArr = fileValue?.filter((_, i) => i !== index);
    onFileChange(newImageArr);
  };

  return (
    <>
      <div className="upload-file-wrapper">
        <div className="d-flex justify-content-between align-items-center mb-6">
          <span>{label}</span>
          <CFormLabel htmlFor={name} className="upload-file-container">
            <CFormInput
              className="input-upoad"
              type="file"
              name={name}
              multiple
              id={name}
              onClick={(e) => (e.currentTarget.value = "")}
              onChange={handleFileUpload}
            />
            <CButton className="btn-custom variant-gray-300 btn-xs btn-upload">
              <UploadFileIcon className="upload-icon" /> Tải lên
            </CButton>
          </CFormLabel>
        </div>
        <CRow className="">
          <CCol xs={12} className="">
            <div className="d-flex flex-wrap">
              {fileValue.map((file, index) => (
                <FileContainerVisibleEdit
                  key={index}
                  index={index}
                  file={file}
                  handleRemoveFile={handleRemoveFile}
                />
              ))}
            </div>
          </CCol>
        </CRow>

        {/* <div className={`${value.length > 0 ? 'd-flex justify-content-end mt-2 mb-2' : 'd-none'} w-100`}>
        <CButton className="delete-all-btn" onClick={handleRemoveAllImage}>
          <CIcon icon={cilX} /> Xóa tất cả ảnh
        </CButton>
      </div> */}
      </div>
    </>
  );
};

export default InstanceMultiFileUpload;
