import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import axios from "../../../../shared/config/axios-interceptor";
import { IParams } from "@/shared/shared-interfaces";
import { ILayer, INewLayer } from "@/shared/model/layer.model";

const prefix = "layer";

export interface ILayerParam extends IParams {
  projectId: number;
}

export const getEntities = createAsyncThunk(
  `get-all-${prefix}`,
  async (field: ILayerParam, thunkAPI) => {
    try {
      const params = pickBy(field);
      return await axios.get<ILayer[]>(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(
  `get-single-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      const { data } = await axios.get<ILayer>(`${prefix}/${id}`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateEntity = createAsyncThunk(
  `update-one-${prefix}`,
  async (body: ILayer, thunkAPI) => {
    try {
      const { layerId } = body;
      const { data } = await axios.put<ILayer>(`${prefix}/${layerId}`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(
  `create-one-${prefix}`,
  async (body: INewLayer, thunkAPI) => {
    try {
      const { data } = await axios.post(`${prefix}`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const removeEntity = createAsyncThunk(
  `delete-one-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      await axios.delete(`${prefix}/${id}`);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
