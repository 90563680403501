import SModal from "@/app/shared/Modal/SModal";
import { RootState } from "@/reducers";
import { ResponseStatus } from "@/shared/enumeration/ResponseStatus";
import { IChat, INewChat } from "@/shared/model/chat.model";
import { ISelectValue } from "@/shared/shared-interfaces";
import { AppDispatch } from "@/store";
import {
    CButton,
    CCol,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from "@coreui/react-pro";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { MultiValue } from "react-select";
import * as Yup from "yup";
import { returnUserMultipleItem } from "../Project/Detail/Tasks/TaskUpdate";
import {
    getEntities as getUserEntities,
    IUserParams,
} from "../SystemSetting/UserManagement/usersManagement.api";
import { userSelectors } from "../SystemSetting/UserManagement/usersManagement.reducer";
import AvatarUploadContainer from "@/app/shared/AvatarUpload/AvatarUploadContainer";
import ColorPickerContainer, {
    defaultColor,
} from "@/app/shared/ColorPicker/ColorPickerContainer";
import { createEntity, updateEntity } from "./chat.api";
import { handleUploadImage } from "@/shared/utils/ultils";

interface ICreateChatProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    setRoomState?: Function;
    roomObject?: IChat;
}

export const typeOptions: ISelectValue<string>[] = [
    {
        label: "Tin nhắn riêng tư",
        value: "INDIVIDUAL",
    },
    {
        label: "Tin nhắn nhóm",
        value: "ROOM_GROUP",
    },
];

export const handleRemappingImageValue = async (value: INewChat) => {
    if (value.avatarFile) {
        const avatarUrlPromises = await handleUploadImage(value.avatarFile);
        value.avatar = avatarUrlPromises;
        value.avatarFile = undefined;
    }

    return value;
};

const CreateChat = (props: ICreateChatProps) => {
    const { visible, setVisible, setRoomState, roomObject } = props;
    const dispatch = useDispatch<AppDispatch>();
    const { user } = useSelector((state: RootState) => state.authentication);
    const [loading, setLoading] = useState<boolean>(false);
    const { filterState } = useSelector(
        (state: RootState) => state.chatReducer.initialState
    );
    const [userFilter, setUserFilter] = useState<IUserParams>({
        page: 1,
        limit: 20,
        status: ResponseStatus.ACTIVE,
    });

    const userItems = useSelector(userSelectors.selectAll);
    //@ts-ignore
    const newRoomObject: INewChat = {
        ...roomObject,
        users: roomObject?.users.map((value) => Number(value.id)) || [],
    };

    const typeOptionsObject = typeOptions.find(
        (item) => item.value === newRoomObject.type
    );
    const customUserItems: ISelectValue<string>[] = returnUserMultipleItem(
        userItems.filter((item) => item.id !== user?.id),
        []
    );

    const initialValues: INewChat = {
        users: [],
        content: "",
        name: "",
        avatar: "",
        avatarColor: defaultColor[0],
        type: typeOptions?.[0],
        description: "",
    };
    const [typeChoose, setTypeChoose] = useState<ISelectValue<string>>(
        typeOptionsObject || typeOptions[0]
    );

    const returnMutiOption = (
        optionValue: number[],
        array: ISelectValue<string>[]
    ): ISelectValue<string>[] => {
        if (!optionValue) return [];
        const selectValue = array?.filter((item) =>
            optionValue.includes(Number(item.value))
        );
        return selectValue;
    };

    const validationSchema = Yup.object().shape({
        users: Yup.array().min(1, "Vui lòng chọn thành viên"),
        content: Yup.string()
            .trim()
            .when("type", {
                is: (type: any) => {
                    return typeChoose.value !== "INDIVIDUAL" && !roomObject
                },
                then: Yup.string()
                    .trim()
                    .required("Không được để trống tin nhắn"),
            }),
    });

    const handleOnClose = () => {
        setVisible(false);
    };

    // useEffect(() => {
    //     if (newValueSubmit) {
    //         const messageData = {
    //             roomId: 114,
    //             content: "value.content",
    //             senderId: user?.id,
    //             senderUser: user?.username,
    //             timestamp: dayjs(new Date()).format(),

    //         };
    //         dispatch(getEntities(filterState));
    //         socket.emit("send_message", messageData);
    //         userItems?.filter((item) =>
    //             newValueSubmit.some((id) => Number(item.id) === id)
    //         );
    //         newValueSubmit.map((id,index) => {
    //             socket.on(`user_${id}_new_room`, (res: ICreateChatResponse) => {
    //                 // socket.off(`user_${user?.id}_new_room`);
    //             })
    //         })
    //         socket.on(
    //             `user_${user?.id}_new_room`,
    //             (res: ICreateChatResponse) => {

    //                 socket.off(`user_${user?.id}_new_room`);
    //             }
    //         );

    //     }
    // }, [newValueSubmit])

    useEffect(() => {
        dispatch(getUserEntities(userFilter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(userFilter)]);

    return (
        <SModal
            visible={visible}
            onClose={handleOnClose}
            backdrop="static"
            className="custom-modal"
        >
            <Formik
                initialValues={newRoomObject || initialValues}
                validationSchema={validationSchema}
                // validateOnChange={false}
                validateOnBlur={false}
                onSubmit={async (value, { resetForm }) => {
                    if (!roomObject) {
                        setLoading(true);
                        const joinUserIds = [...value.users, Number(user?.id)];
                        const joinUsers = userItems?.filter((item) =>
                            joinUserIds.some((id) => Number(item.id) === id)
                        );
                        if (typeChoose.value === typeOptions[0].value) {
                            const oppositeUser = joinUsers.find(
                                (item) => item.id !== user?.id
                            );
                            const newValue = {
                                users: joinUserIds,
                                name:
                                    oppositeUser?.fullName ||
                                    oppositeUser?.username ||
                                    "",
                                type: typeChoose.value,
                                content: value.content,
                            };
                            dispatch(createEntity(newValue as INewChat));
                            if (setRoomState) {
                                setRoomState(newValue);
                            }
                        } else {
                            const newImage = await handleRemappingImageValue(
                                value as INewChat
                            );
                            // value.avatar = avatarUrlPromises;
                            const newValue = {
                                ...newImage,
                                users: joinUserIds,
                                name:
                                    value.name ||
                                    joinUsers
                                        .map(
                                            (user) =>
                                                user.fullName || user.username
                                        )
                                        .join(", "),
                                type: typeChoose.value,
                            };
                            dispatch(createEntity(newValue as INewChat));
                        }
                        handleOnClose();
                    } else {
                        const joinUserIds = [...value.users, Number(user?.id)];
                        const newValue = {
                            ...value,
                            users: joinUserIds,
                        };
                        dispatch(updateEntity(newValue as INewChat));
                        handleOnClose();
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    setFieldValue,
                }) => (
                    <CForm onSubmit={handleSubmit} className="custom-form">
                        <CModalHeader>
                            {roomObject
                                ? "Chỉnh sửa phòng nhắn"
                                : "Tạo phòng nhắn mới"}
                        </CModalHeader>
                        <CModalBody>
                            <CRow>
                                <CCol xs={12} className="mb-24">
                                    <CFormLabel className="text-medium-xs text-gray-modern-700 mb-6">
                                        Loại tin nhắn
                                    </CFormLabel>
                                    <Select
                                        name="type"
                                        defaultValue={typeOptions[0]}
                                        isDisabled={Boolean(roomObject)}
                                        className="custom-select multi-select height-44"
                                        classNamePrefix="react-select"
                                        value={typeOptions.find((value, _) => {
                                            return value.value === values.type;
                                        })}
                                        onChange={(value: any) => {
                                            resetForm();
                                            setTypeChoose(value);
                                            setFieldValue("type", value);
                                        }}
                                        id={"type"}
                                        options={typeOptions}
                                        closeMenuOnSelect={true}
                                        menuPosition={"absolute"}
                                        placeholder={"Loại tin nhắn..."}
                                    />
                                    <CFormFeedback
                                        invalid
                                        className={
                                            !!errors.type && touched.type
                                                ? "d-block"
                                                : "d-none"
                                        }
                                    >
                                        {errors.type}
                                    </CFormFeedback>
                                </CCol>

                                <CCol xs={12} className="mb-24">
                                    <CFormLabel className="text-medium-xs text-gray-modern-700 mb-6">
                                        Gửi tới
                                    </CFormLabel>
                                    <Select
                                        defaultValue={null}
                                        className="custom-select multi-select height-44"
                                        classNamePrefix="react-select"
                                        value={returnMutiOption(
                                            values.users || [],
                                            customUserItems
                                        )}
                                        onChange={async (
                                            newValue: MultiValue<ISelectValue<string> | null>
                                        ) => {
                                            if (newValue.length === 0) {
                                                setUserFilter({
                                                    page: 1,
                                                    limit: 20,
                                                    status: ResponseStatus.ACTIVE,
                                                });
                                            }

                                            const optionValue = newValue.map(
                                                (item) => Number(item?.value)
                                            );
                                            if (
                                                typeChoose?.value ===
                                                typeOptions?.[0]?.value
                                            ) {
                                                let newOptionValue =
                                                    optionValue;
                                                if (newValue.length === 2) {
                                                    newOptionValue = [
                                                        newOptionValue[
                                                            newOptionValue.length -
                                                                1
                                                        ],
                                                    ];
                                                }
                                                setFieldValue(
                                                    `users`,
                                                    newOptionValue
                                                );
                                                return;
                                            }
                                            setFieldValue(`users`, optionValue);
                                        }}
                                        onMenuScrollToBottom={() => {
                                            setUserFilter({
                                                ...userFilter,
                                                limit: userFilter.limit + 20,
                                            });
                                        }}
                                        onInputChange={(newValue: string) => {
                                            setUserFilter({
                                                ...userFilter,
                                                key: newValue,
                                            });
                                        }}
                                        id={"users"}
                                        noOptionsMessage={() => (
                                            <>Không có thành viên nào</>
                                        )}
                                        options={customUserItems}
                                        isClearable={true}
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        menuPosition={"absolute"}
                                        placeholder={"Gửi tới..."}
                                    />
                                    <CFormFeedback
                                        invalid
                                        className={
                                            !!errors.users && touched.users
                                                ? "d-block"
                                                : "d-none"
                                        }
                                    >
                                        {errors.users}
                                    </CFormFeedback>
                                </CCol>

                                {typeChoose?.value !==
                                typeOptions?.[0]?.value ? (
                                    <>
                                        <CCol xs={12} className="mb-24">
                                            <CFormLabel className="text-medium-xs mb-6 label-gray-700">
                                                Tên nhóm
                                            </CFormLabel>
                                            <CFormInput
                                                className="form-height-44"
                                                name="name"
                                                autoComplete="off"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={
                                                    !!errors.name &&
                                                    touched.name
                                                }
                                                placeholder={"Tên nhóm..."}
                                            />
                                            <CFormFeedback
                                                invalid
                                                className={
                                                    !!errors.name &&
                                                    touched.name
                                                        ? "d-block"
                                                        : "d-none"
                                                }
                                            >
                                                {errors.name}
                                            </CFormFeedback>
                                        </CCol>

                                        <CCol xs="12" className="mb-24">
                                            <CFormLabel className="mb-6 label-gray-700">
                                                Chọn ảnh đại diện
                                            </CFormLabel>
                                            <div
                                                className="d-flex align-items-center"
                                                style={{ gap: "24px" }}
                                            >
                                                <AvatarUploadContainer
                                                    avatarSrc={values.avatar}
                                                    
                                                    onFileChange={(
                                                        file,
                                                        fileSrc
                                                    ) => {
                                                        setFieldValue(
                                                            "avatarFile",
                                                            file
                                                        );
                                                        setFieldValue(
                                                            "avatar",
                                                            fileSrc
                                                        );
                                                        setFieldValue(
                                                            "avatarColor",
                                                            ""
                                                        );
                                                    }}
                                                />
                                                <span className="picker-text">
                                                    hoặc
                                                </span>
                                                <div
                                                    className="color-picker-demo"
                                                    style={{
                                                        backgroundColor:
                                                            values.avatarColor,
                                                    }}
                                                >
                                                    {values.name
                                                        ? values.name[0].toUpperCase()
                                                        : ""}
                                                </div>
                                                <ColorPickerContainer
                                                    colorCode={
                                                        values.avatarColor
                                                    }
                                                    onColorChange={(color) => {
                                                        setFieldValue(
                                                            "avatar",
                                                            ""
                                                        );
                                                        setFieldValue(
                                                            "avatarColor",
                                                            color
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </CCol>
                                    </>
                                ) : (
                                    <></>
                                )}

                                {!roomObject && (
                                    <CCol xs={12} className="mb-24">
                                        <CFormLabel className="text-medium-xs mb-6 label-gray-700">
                                            Tin nhắn{" "}
                                            {typeChoose?.value !== "INDIVIDUAL" ? <span style={{ color: "red" }}>
                                                *
                                            </span> : <></>}
                                        </CFormLabel>
                                        <CFormInput
                                            className="form-height-44"
                                            name="content"
                                            autoComplete="off"
                                            value={values.content}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={
                                                !!errors.content &&
                                                touched.content
                                            }
                                            placeholder={"Viết tin nhắn..."}
                                        />
                                        <CFormFeedback
                                            invalid
                                            className={
                                                !!errors.content &&
                                                touched.content
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                        >
                                            {errors.content}
                                        </CFormFeedback>
                                    </CCol>
                                )}
                            </CRow>
                        </CModalBody>

                        <CModalFooter className="d-flex justify-content-end">
                            <CButton
                                className="btn-custom minw-120 variant-gray-300"
                                onClick={() => {
                                    resetForm();
                                    setVisible(false);
                                }}
                            >
                                Huỷ
                            </CButton>
                            <CButton
                                className="btn-custom minw-120 primary-500"
                                type="submit"
                                disabled={loading}
                            >
                                {newRoomObject ? "Lưu" : "Tạo"}
                            </CButton>
                        </CModalFooter>
                    </CForm>
                )}
            </Formik>
        </SModal>
    );
};

export default CreateChat;
