import { IUser } from '@/shared/model/user.model';
import { CAvatar, CFormCheck, CTooltip } from '@coreui/react-pro';

interface IAvatarCheckBoxProps {
  user: IUser;
  checked?: boolean;
  onChecked?: (user: IUser, checked?: boolean) => void;
  size?: number;
  fontSize?: number;
}

const AvatarCheckBox = (props: IAvatarCheckBoxProps) => {
  const { user, checked, onChecked, size = 32, fontSize = 14 } = props;
  const label = user.fullName || user.username || user.email || '';
  return (
    <CTooltip key={user.id} content={label} className="position-fixed">
      <CAvatar
        src={user.avatar || ''}
        style={{
          backgroundColor: `${user.avatarColor || '#1570ef'}`,
          width: `${size}px`,
          height: `${size}px`,
          fontSize: `${fontSize}px`,
        }}
        key={user.id}
        textColor="white"
        className="avatar-checkbox"
        onClick={() => {
          onChecked && onChecked(user, !checked);
        }}
      >
        {label ? label.charAt(0)?.toUpperCase() : ''}
        {checked ? <CFormCheck className="check-box" defaultChecked disabled /> : ''}
      </CAvatar>
    </CTooltip>
  );
};

export default AvatarCheckBox;
