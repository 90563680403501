import { RootState } from "@/reducers";
import { IInitialState, IParams, IResponse } from "@/shared/shared-interfaces";
import {
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { getEntities, INotificationParams, updateReadNotification } from "./notifications.api";
import { INotifications } from "@/shared/model/notifications.model";

interface INotificationsInitialState extends IInitialState {
    filterState: INotificationParams;
    updateNotificationSuccess: boolean;
}

export const initialNotificationFilter: INotificationParams = {
    page: 1,
    limit: 20,
    sortBy: "lastModifiedDate",
    sortOrder: "DESC",
};

const initialState: INotificationsInitialState = {
    updateNotificationSuccess: false,
    fetchEntitiesSuccess: false,
    fetchEntitySuccess: false,
    updateEntitySuccess: false,
    deleteEntitySuccess: false,
    loading: false,
    errorMessage: null,
    totalItems: 0,
    totalPages: 0,
    filterState: initialNotificationFilter,
};

export const notificationAdapter = createEntityAdapter<INotifications>({
    selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
    name: "chatSlice",
    initialState: notificationAdapter.getInitialState({ initialState }),
    reducers: {
        setFilterState(state, { payload }: PayloadAction<IParams>) {
            state.initialState.filterState = payload;
        },
        fetching(state) {
            state.initialState.loading = true;
        },
        resetAll(state) {
            state.initialState.loading = false;
            state.initialState.fetchEntitiesSuccess = false;
            state.initialState.fetchEntitySuccess = false;
            state.initialState.updateEntitySuccess = false;
            state.initialState.deleteEntitySuccess = false;
            state.initialState.errorMessage = null;
            state.initialState.updateNotificationSuccess = false;
        },
        resetEntity(state) {
            state.initialState.updateEntitySuccess = false;
            state.initialState.errorMessage = null;
            state.initialState.deleteEntitySuccess = false;
            state.initialState.loading = false;

        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getEntities.pending.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.loading = true;
            }
        );
        builder.addCase(
            getEntities.fulfilled.type,
            (
                state,
                { payload }: PayloadAction<AxiosResponse<IResponse<INotifications[]>>>
            ) => {
                notificationAdapter.setAll(state, payload.data.data);
                state.initialState.totalItems = Number(payload.data.count);
                state.initialState.totalPages = Number(
                    payload.data.total_pages
                );
                state.initialState.fetchEntitiesSuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            getEntities.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.fetchEntitiesSuccess = false;
            }
        );

        builder.addCase(
            updateReadNotification.fulfilled.type,
            (
                state,
                { payload }: PayloadAction<AxiosResponse<any>>
            ) => {
                state.initialState.updateNotificationSuccess = true;
                state.initialState.fetchEntitiesSuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            updateReadNotification.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.updateNotificationSuccess = false;
                state.initialState.loading = false;
                state.initialState.fetchEntitiesSuccess = false;
            }
        );
    },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const notificationSelectors = notificationAdapter.getSelectors<RootState>(
    (state) => state.notificationReducer
);

const { selectById } = notificationAdapter.getSelectors();
const getNotificationState = (rootState: RootState) => rootState.notificationReducer;

export const selectEntityById = (id: string) => {
    return createSelector(getNotificationState, (state) => selectById(state, id));
};
