import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import axios from "../../../../shared/config/axios-interceptor";
import { IParams } from "@/shared/shared-interfaces";
import { INewPlaylist, IPlaylist } from "@/shared/model/playlist.model";

const prefix = "video";

export interface IPlaylistParam extends IParams {
  projectId: number;
}

export const getEntities = createAsyncThunk(
  `get-all-${prefix}`,
  async (field: IPlaylistParam, thunkAPI) => {
    try {
      const params = pickBy(field);
      return await axios.get<IPlaylist[]>(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(
  `get-single-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      const { data } = await axios.get<IPlaylist>(`${prefix}/${id}`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateEntity = createAsyncThunk(
  `update-one-${prefix}`,
  async (body: IPlaylist, thunkAPI) => {
    try {
      const { videoId } = body;
      const { data } = await axios.put<IPlaylist>(`${prefix}/${videoId}`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(
  `create-one-${prefix}`,
  async (body: INewPlaylist, thunkAPI) => {
    try {
      const { file, projectId } = body;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("projectId", String(projectId));
      const { data } = await axios.post(`${prefix}`, formData);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const removeEntity = createAsyncThunk(
  `delete-one-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      await axios.delete(`${prefix}/${id}`);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
