import { ReactNode } from "react";
import { matchPath } from "react-router-dom";
import {
    Permission,
    permissionGroupArray,
    userManagementPermissionArray,
} from "../../shared/enumeration/permission";
import BellSidebarIcon from "../shared/icons/BellSidebarIcon";
import EmailIcon from "../shared/icons/EmailIcon";
import HeadphonesIcon from "../shared/icons/HeadphonesIcon";
import InfoCircleSidebar from "../shared/icons/InfoCircleSidebar";
import LockIcon from "../shared/icons/LockIcon";
import MessageAlertSquareSidebar from "../shared/icons/MessageAlertSquareSidebar";
import CalendarIcon from "../shared/icons/sidebar/CalendarIcon";
import ClipboardIcon from "../shared/icons/sidebar/ClipboardIcon";
import FolderIcon from "../shared/icons/sidebar/FolderIcon";
import MessageSquareIcon from "../shared/icons/sidebar/MessageSquareIcon";
import PieChartIcon from "../shared/icons/sidebar/PieChartIcon";
import UserGroupIcon from "../shared/icons/sidebar/UserGroupIcon";
import UserCircleIcon from "../shared/icons/UserCircleIcon";
import UserGroup from "../shared/icons/UserGroup";

export const checkMatchPath = (pathArray: string[], pathName: string) => {
    let isActive = false;
    pathArray.forEach((path) => {
        const isMatch = matchPath({ path, end: false }, pathName);
        if (isMatch) {
            isActive = true;
            return;
        }
    });
    return isActive;
};
export interface SidebarItem {
    name: string;
    to: string;
    icon?: ReactNode;
    isTitle?: boolean;
    subItems?: SidebarItem[];
    permissions?: Permission[];
}

export const defaultSiderbarItems: SidebarItem[] = [
    {
        name: "Công việc của tôi",
        to: "/my-task",
        icon: <ClipboardIcon className="custom-nav-icon" />,
    },
    {
        name: "Dự án",
        to: "/project",
        icon: <FolderIcon className="custom-nav-icon" />,
        permissions: [Permission.PROJECT_LIST],
    },
    {
        name: "Phòng ban",
        to: "/department",
        icon: <UserGroupIcon className="custom-nav-icon" />,
        permissions: [Permission.DEPARTMENT_VIEW],
    },
    {
        name: "Lịch biểu",
        to: "/calendar",
        icon: <CalendarIcon className="custom-nav-icon" />,
        permissions: [Permission.SCHEDULE_VIEW]
    },
    {
        name: "Báo cáo",
        to: "/report",
        icon: <PieChartIcon className="custom-nav-icon" />,
        permissions: [Permission.REPORT_VIEW]
    },
    {
        name: "Tin nhắn",
        to: "/message",
        icon: <MessageSquareIcon className="custom-nav-icon" />,
        permissions: [Permission.MESSAGE_VIEW]
    },
];

export const userSettingSidebarItems: SidebarItem[] = [
    {
        name: "Thông tin hệ thống",
        to: "/system-info",
        icon: <InfoCircleSidebar className="custom-nav-icon" />,
    },
    {
        name: "Quyền",
        to: "/permissions",
        icon: <UserGroup className="custom-nav-icon" />,
        permissions: permissionGroupArray,
    },
    {
        name: "Tài khoản",
        to: "/users",
        icon: <UserCircleIcon className="custom-nav-icon" />,
        permissions: userManagementPermissionArray,
    },
    {
        name: "Cài đặt kế hoạch và thông báo",
        to: "/plan-notification-setting",
        icon: <BellSidebarIcon className="custom-nav-icon" />,
    },
    {
        name: "Hỗ trợ",
        to: "/support",
        icon: <MessageAlertSquareSidebar className="custom-nav-icon" />,
    },
    {
        name: "Danh mục dùng chung",
        to: "/dictionary-management",
        icon: <MessageAlertSquareSidebar className="custom-nav-icon" />,
    },
];

export const accountSettingSidebarItems: SidebarItem[] = [
    {
        name: "Thông tin tài khoản",
        to: "/account/profile",
        icon: <UserCircleIcon className="custom-nav-icon" />,
    },
    {
        name: "Thay đổi mật khẩu",
        to: "/account/change-password",
        icon: <LockIcon className="custom-nav-icon" />,
    },
    {
        name: "Thay đổi email",
        to: "/account/change-email",
        icon: <EmailIcon className="custom-nav-icon" />,
    },
    {
        name: "Hỗ trợ",
        to: "/account/support",
        icon: <HeadphonesIcon className="custom-nav-icon" />,
    },
];

export const handleSidebarItemsOnPathName = (path: string) => {
    if (
        checkMatchPath(
            userSettingSidebarItems.map((item) => item.to),
            path
        )
    ) {
        return userSettingSidebarItems;
    }

    if (
        checkMatchPath(
            accountSettingSidebarItems.map((item) => item.to),
            path
        )
    ) {
        return accountSettingSidebarItems;
    }
};

interface ITabItem {
    name: ReactNode;
    path: string;
    permission?: Permission[];
}

export interface HeaderItem {
    name: ReactNode;
    to: string;
    tabItems?: ITabItem[];
    rightComponent?: ReactNode;
}

export const otherHeaderItem: HeaderItem[] = [
    {
        name: "Cài đặt hệ thống",
        to: "/settings",
        tabItems: [
            { name: "Thông tin hệ thống", path: "/settings/system-info" },
            { name: "Quyền", path: "/settings/permissions" , permission: [Permission.PERMISSION_GROUP_VIEW]},
            { name: "Tài khoản", path: "/settings/users" , permission: [Permission.USER_VIEW]},
            {
                name: "Cài đặt kế hoạch và thông báo",
                path: "/settings/plan-notification-setting",
            },
            {
                name: "Danh mục dùng chung",
                path: "settings/dictionary-management", 
                permission: [Permission.DICT_VIEW]
                
            },
            { name: "Hỗ trợ", path: "/settings/support" },
        ],
    },
    {
        name: "Tài khoản của tôi",
        to: "/account",
        tabItems: [
            { name: "Thông tin", path: "/account/profile" },
            { name: "Bảo mật", path: "/account/change-password" },
            { name: "Thông báo", path: "/account/notifications" },
        ],
    },
];

export const handleGetHeaderItem = (path: string) => {
    const siderBarItem = [...defaultSiderbarItems];
    const convertSideBarItem = siderBarItem.map(({ name, to }) => ({
        name,
        to,
    }));
    const allHeaderItem = [...otherHeaderItem, ...convertSideBarItem];
    const headerItem = allHeaderItem.find((item) =>
        checkMatchPath([item.to], path)
    );
    if (headerItem) {
        return headerItem;
    }
    return null;
};
