import axios from '@/shared/config/axios-interceptor';
import { ResponseStatus } from '@/shared/enumeration/ResponseStatus';
import { TaskStatusEnum } from '@/shared/enumeration/task.enum';
import { INewTaskResult, ITaskResult } from '@/shared/model/taskResult.model';
import { IParams } from '@/shared/shared-interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';

const prefix = 'result-task';
// const history = 'revision';

export interface ITaskResultParams extends IParams {
  taskId: number;
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: ITaskResultParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<ITaskResult[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<ITaskResult>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewTaskResult, thunkAPI) => {
  try {
    const { data } = await axios.post(
      `${prefix}`,
      pickBy(body, (value) => value !== null && value !== undefined)
    );
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: ITaskResult, thunkAPI) => {
  try {
    const { resultTaskId } = body;
    if (body.status === TaskStatusEnum.NEWTASK) {
      body.status = "NEW" as TaskStatusEnum
    }
    const { data } = await axios.put<ITaskResult>(
      `${prefix}/${resultTaskId}`,
      pickBy(body, (value) => value !== null && value !== undefined)
    );
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface ITaskResultDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: ITaskResultDel, thunkAPI) => {
    try {
      await axios.post<ITaskResult>(`${prefix}/status`, requestBody);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
