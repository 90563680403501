import { CAvatar, CCol, CContainer, CRow } from "@coreui/react-pro";
import React, { useEffect } from "react";
import { ITask } from "@/shared/model/task.model";
import { useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { useDispatch } from "react-redux";
import { getLogsTask } from "../tasksManagement.api";
import dayjs from "dayjs";
import { toLower } from "lodash";
function TaskActiveTab({ task }: { task: ITask }) {
  const dispatch = useDispatch();
  const { logTasks } = useSelector(
    (state: RootState) => state.tasksReducer.initialState
  );

  useEffect(() => {
    if (task.taskId) dispatch(getLogsTask(Number(task.taskId)) as any);
  }, [task.taskId]);

  return (
    <>
      <CContainer fluid className="tab-content" style={{ paddingLeft: "32px", paddingRight: "32px" }}>
        <CRow style={{ width: "100%" }}>
          {[...logTasks].reverse()?.map((item) => {
            return (
              <CCol xs={12}>
                <div
                  className="py-4"
                  style={{ borderBottom: "1px solid var(--gray-modern-300)" }}
                >
                  <div
                    className="d-flex flex-row card-header"
                    style={{ gap: "15px" }}
                  >
                    {/* <CAvatar
                      src={item.user?.avatar}
                      className="p-0"
                      style={{
                        marginTop: "3px",
                        width: "24px",
                        height: "24px",
                        border: "0.75px solid #101828",
                      }}
                    /> */}

                  {item.user?.avatar ? (
                    <CAvatar src={item.user?.avatar}
                    className="p-0"
                    style={{
                      marginTop: "3px",
                      width: "24px",
                      height: "24px",
                      border: "0.75px solid #101828",
                    }} />
                ) : (
                    <CAvatar
                        textColor="white"
                        className="p-0"
                        style={{
                          backgroundColor: `${item.user?.avatarColor || "#1570ef"}`,
                          marginTop: "3px",
                          width: "24px",
                          height: "24px",
                          border: "0.75px solid #101828",
                        }}
                    >
                        { 
                        item.user?.fullName?.charAt?.(0)?.toUpperCase() ||
                        item.user?.username?.charAt?.(0)?.toUpperCase()}
                    </CAvatar>
                )}
                    <div className="">
                      <p
                        className="m-0"
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "20px", 
                          color: "#697586"
                        }}
                      >
                        <span style={{fontWeight: 500, color: "#121926"}}>{item.user.fullName || item.user.username}</span>{" "}{toLower(item.title)}
                      </p>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ marginTop: "5px", gap: "10px" }}
                      >
                        <p
                          style={{ fontSize: "12px", color: "#9AA4B2" }}
                          className="header-note m-0"
                        >
                          cập nhật {dayjs(item.createdDate).fromNow(true)} trước
                        </p>
                        {/* <div
                          style={{
                            width: "2px",
                            height: "2px",
                            background: "#697586",
                          }}
                        ></div> */}
                        {/* <div>
                      <div className="breadcrumb-container d-flex justify-content-start">
                        {dataTracker.map((trace, index) => (
                          <p
                            className="breadcrumb m-0 cursor-pointer"
                            key={index}
                            style={{ fontSize: "13px" }}
                            onClick={() => {}}
                          >
                            {trace.name}{" "}
                            {index !== dataTracker.length - 1 && (
                              <div className="mx-1">{" > "}</div>
                            )}
                          </p>
                        ))}
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>
            );
          })}
        </CRow>
      </CContainer>
    </>
  );
}

export default TaskActiveTab;
