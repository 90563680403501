import { IParams } from "@/shared/shared-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/shared/config/axios-interceptor";

const prefix = "task";
export type IMyTaskParams = IParams;

export const getEntities = createAsyncThunk(
  `getss-${prefix}`,
  async (fields: IMyTaskParams, thunkAPI) => {
    try {
      return await axios.get(`${prefix}`, { params: fields });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntitiesMyTask = createAsyncThunk(
  `get-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      const params = { assignId: id };
      return await axios.get(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createTask = createAsyncThunk(
  `create-${prefix}`,
  async (body: any, thunkAPI) => {
    try {
      const response = await axios.post(`${prefix}`, body);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateTask = createAsyncThunk(
  `update-${prefix}`,
  async (
    {
      taskId,
      newStatus,
      id,
    }: { taskId: number; newStatus?: string; id: string | undefined },
    thunkAPI
  ) => {
    try {
      const response = await axios.put(`${prefix}/${taskId}`, {
        status: newStatus,
        assignIds: [id],
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteTask = createAsyncThunk(
  `delete-${prefix}`,
  async (
    { task, assignIds }: { task: number; assignIds: number },
    thunkAPI
  ) => {
    try {
      const response = await axios.delete(`${prefix}/user-assign`, {
        data: { taskId: task, assignIds: [assignIds] },
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const totalTask = createAsyncThunk(
  `total-Task-${prefix}`,
  async (id: number, thunkAPI) => {
    try {
      const response = await axios.get(`${prefix}/total-task/${id}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
