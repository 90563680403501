import {
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
  } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState } from "../../../../reducers";
import { IInitialState, IResponse } from "../../../../shared/shared-interfaces";
import { getEntities, getEntity, IRoleParams } from "./role.api";
import { IMenuRole } from "@/shared/model/role.model";

  
  interface IRoleMenuInitialState extends IInitialState {
    updateEntityStatusSuccess: boolean;
    filterState: IRoleParams;
  }
  
  export const initialRolesFilter: IRoleParams = {
    page: 1,
    limit: 10,
    // sortBy: "createdDate",
    sortOrder: "DESC",
  };
  
  const initialState: IRoleMenuInitialState = {
    fetchEntitiesSuccess: false,
    fetchEntitySuccess: false,
    updateEntitySuccess: false,
    deleteEntitySuccess: false,
    loading: false,
    errorMessage: null,
    totalItems: 0,
    totalPages: 0,
    updateEntityStatusSuccess: false,
    filterState: initialRolesFilter,
  };
  
  export const roleMenuAdapter = createEntityAdapter<IMenuRole>({
    selectId: ({ id }) => id,
  });
  
  const { actions, reducer } = createSlice({
    name: "rolesSlice",
    initialState: roleMenuAdapter.getInitialState({ initialState }),
    reducers: {
      setFilterState(state, { payload }: PayloadAction<IRoleParams>) {
        state.initialState.filterState = payload;
      },
      fetching(state) {
        state.initialState.loading = true;
      },
      resetAll(state) {
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
        state.initialState.fetchEntitySuccess = false;
        state.initialState.updateEntitySuccess = false;
        state.initialState.deleteEntitySuccess = false;
        state.initialState.errorMessage = null;
      },
      resetEntity(state) {
        state.initialState.updateEntitySuccess = false;
        state.initialState.errorMessage = null;
        state.initialState.deleteEntitySuccess = false;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(
        getEntities.fulfilled.type,
        (
          state,
          { payload }: PayloadAction<AxiosResponse<IResponse<IMenuRole[]>>>
        ) => {
          roleMenuAdapter.setAll(state, payload.data.data);
          state.initialState.totalItems = Number(payload.data.count);
          state.initialState.totalPages = Number(payload.data.total_pages);
          state.initialState.fetchEntitiesSuccess = true;
          state.initialState.loading = false;
        }
      );
      builder.addCase(
        getEntities.rejected.type,
        (state, { payload }: PayloadAction<any>) => {
          state.initialState.errorMessage = payload?.message;
          state.initialState.loading = false;
          state.initialState.fetchEntitiesSuccess = false;
        }
      );
      builder.addCase(
        getEntity.fulfilled.type,
        (state, { payload }: PayloadAction<IMenuRole>) => {
          roleMenuAdapter.upsertOne(state, payload);
          state.initialState.fetchEntitySuccess = true;
          state.initialState.loading = false;
        }
      );
      builder.addCase(
        getEntity.rejected.type,
        (state, { payload }: PayloadAction<any>) => {
          state.initialState.errorMessage = payload?.message;
          state.initialState.loading = false;
          state.initialState.fetchEntitySuccess = false;
        }
      );
    //   builder.addCase(
    //     updateEntity.fulfilled.type,
    //     (state, { payload }: PayloadAction<IPermission>) => {
    //       roleMenuAdapter.updateOne(state, {
    //         id: payload.permissionGroupId,
    //         changes: payload,
    //       });
    //       state.initialState.updateEntitySuccess = true;
    //       state.initialState.loading = false;
    //     }
    //   );
    //   builder.addCase(
    //     updateEntity.rejected.type,
    //     (state, { payload }: PayloadAction<any>) => {
    //       state.initialState.errorMessage = payload?.message;
    //       state.initialState.loading = false;
    //       state.initialState.updateEntitySuccess = false;
    //     }
    //   );
    //   builder.addCase(
    //     createEntity.fulfilled.type,
    //     (state, { payload }: PayloadAction<IPermission>) => {
    //       roleMenuAdapter.addOne(state, payload);
    //       state.initialState.updateEntitySuccess = true;
    //       state.initialState.loading = false;
    //     }
    //   );
    //   builder.addCase(
    //     createEntity.rejected.type,
    //     (state, { payload }: PayloadAction<any>) => {
    //       state.initialState.errorMessage = payload?.message;
    //       state.initialState.loading = false;
    //       state.initialState.updateEntitySuccess = false;
    //     }
    //   );
    //   builder.addCase(
    //     removeEntity.fulfilled.type,
    //     (state, { payload }: PayloadAction<string>) => {
    //       roleMenuAdapter.removeOne(state, payload);
    //       state.initialState.totalItems -= 1;
    //       state.initialState.deleteEntitySuccess = true;
    //       state.initialState.loading = false;
    //     }
    //   );
    //   builder.addCase(
    //     removeEntity.rejected.type,
    //     (state, { payload }: PayloadAction<any>) => {
    //       state.initialState.errorMessage = payload?.message;
    //       state.initialState.loading = false;
    //       state.initialState.deleteEntitySuccess = false;
    //     }
    //   );
    //   builder.addCase(
    //     updateDeleteEntity.fulfilled.type,
    //     (state, { payload }: PayloadAction<string[]>) => {
    //       roleMenuAdapter.removeMany(state, payload);
    //       state.initialState.totalItems -= payload.length;
    //       state.initialState.deleteEntitySuccess = true;
    //       state.initialState.loading = false;
    //     }
    //   );
    //   builder.addCase(
    //     updateDeleteEntity.rejected.type,
    //     (state, { payload }: PayloadAction<any>) => {
    //       state.initialState.errorMessage = payload?.message;
    //       state.initialState.loading = false;
    //       state.initialState.deleteEntitySuccess = false;
    //     }
    //   );
    },
  });
  
  export const { fetching, resetAll, resetEntity, setFilterState } = actions;
  export default reducer;
  
  export const roleMenuSelectors = roleMenuAdapter.getSelectors<RootState>(
    (state) => state.roleMenuReducer
  );
  
  const { selectById } = roleMenuAdapter.getSelectors();
  const getPermissionState = (rootState: RootState) =>
    rootState.roleMenuReducer;
  
  export const selectEntityById = (id: string) => {
    return createSelector(getPermissionState, (state) => selectById(state, id));
  };
  