import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

import { RootState } from "@/reducers";
import { ITask } from "@/shared/model/task.model";
import { IStaff } from "@/shared/model/user.model";
import { IInitialState, IResponse } from "@/shared/shared-interfaces";
import {
  IMyTaskParams,
  createTask,
  getEntities,
  deleteTask,
  updateTask,
  getEntitiesMyTask,
  totalTask,
} from "./myTask.api";
import { TaskTypeEnum } from "@/shared/enumeration/task.enum";

interface ITaskInitialState extends IInitialState {
  projectStaff: IStaff[];
  fetchProjectStaffSuccess: boolean;
  errorProjectStaffMessage: string | null;
  updateEntityStatusSuccess: boolean;
  filterTasks: boolean;
  filterState: IMyTaskParams;
  updateDocumentSuccess: boolean;
  errorDocumentMessage: string | null;
  taskDetails: ITask[];
  totalTask: any;
}

export const initialProjectFilter: IMyTaskParams = {
  page: 1,
  limit: 10,
  sortBy: "createdDate",
  sortOrder: "DESC",
};

const initialState: ITaskInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  filterTasks: false,
  updateEntitySuccess: false,
  createEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  totalPages: 0,
  updateEntityStatusSuccess: false,
  filterState: initialProjectFilter,
  projectStaff: [],
  taskDetails: [],
  fetchProjectStaffSuccess: false,
  errorProjectStaffMessage: null,
  updateDocumentSuccess: false,
  errorDocumentMessage: null,
  totalTask: null,
};

export const myTaskAdapter = createEntityAdapter<ITask>({
  selectId: ({ taskId }) => taskId,
});

const { actions, reducer } = createSlice({
  name: "myTaskSlice",
  initialState: myTaskAdapter.getInitialState({ initialState }),
  reducers: {
    setFilterState(state, { payload }: PayloadAction<IMyTaskParams>) {
      state.initialState.filterState = payload;
    },
    setFilterTasks(state) {
      state.initialState.filterTasks = true;
    },
    resetFilterTasks(state) {
      state.initialState.filterTasks = false;
    },
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.createEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.createEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    resetProjectStaff(state) {
      state.initialState.fetchProjectStaffSuccess = false;
      state.initialState.projectStaff = [];
      state.initialState.errorProjectStaffMessage = null;
    },
    resetUploadDocument(state) {
      state.initialState.updateDocumentSuccess = false;
      state.initialState.errorDocumentMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<[]>>>) => {
        myTaskAdapter.setAll(state, payload.data.data);
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
    builder.addCase(
      getEntitiesMyTask.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<[]>>>) => {
        state.initialState.taskDetails = payload.data.data;
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntitiesMyTask.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
    builder.addCase(
      createTask.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<[]>>>) => {
        // myTaskAdapter.upsertOne(payload.data.data);
        state.initialState.createEntitySuccess = true;
        state.initialState.updateEntitySuccess = false;
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      createTask.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.createEntitySuccess = false;
        state.initialState.updateEntitySuccess = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
    builder.addCase(
      updateTask.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<[]>>>) => {
        // myTaskAdapter.upsertOne(payload.data.data);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.updateEntitySuccess = true;
        state.initialState.createEntitySuccess = false;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      updateTask.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
        state.initialState.createEntitySuccess = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );

    builder.addCase(
      deleteTask.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<[]>>>) => {
        state.initialState.deleteEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      deleteTask.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
        state.initialState.deleteEntitySuccess = false;
      }
    );
    builder.addCase(
      totalTask.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<[]>>>) => {
        state.initialState.totalTask = payload.data;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      totalTask.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
      }
    );
  },
});

export const {
  fetching,
  resetAll,
  resetEntity,
  setFilterState,
  resetUploadDocument,
  setFilterTasks,
  resetFilterTasks,
} = actions;
export default reducer;

export const myTaskSelectors = myTaskAdapter.getSelectors<RootState>(
  (state) => state.myTask
);

const { selectById, selectAll } = myTaskAdapter.getSelectors();
const getmyTasktate = (rootState: RootState) => rootState.myTask;

export const selectEntityById = (id: string) => {
  return createSelector(getmyTasktate, (state) => selectById(state, id));
};

export const selectEntitiesByFilter = (taskType: TaskTypeEnum) => {
  return createSelector(getmyTasktate, (state) => {
    const tasks = selectAll(state);
    return tasks?.filter((task) => task.type === taskType);
  });
};
