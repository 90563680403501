import { ReactComponent as ClipboardIcon } from "@/assets/img/clipboard-icon.svg";
import { ReactComponent as FolderIcon } from "@/assets/img/folder-icon.svg";
import { ReactComponent as MessageSquareIcon } from "@/assets/img/message-square-02.svg";

import { ReactComponent as DotGridIcon } from "@/assets/img/dot-grid.svg";

import { ReactComponent as LinkIcon } from "@/assets/img/link-01.svg";

import AvatarContainer from "@/app/shared/AvatarContainer/AvatarContainer";
import StatusSelect from "@/app/shared/StatusSelect/StatusSelect";
import PlusIcon from "@/app/shared/icons/PlusIcon";
import {
    TaskStatusEnum,
    TaskTypeEnum,
    mapTaskStatusToColor,
    mapTaskStatusToString,
    taskIdNameDefault,
} from "@/shared/enumeration/task.enum";
import { IProject } from "@/shared/model/project.modal";
import { CCol, CRow } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import { ITaskTree } from "../TasksManagementGantt";
import { buttonDefault } from "./styleTask";
import CustomDropDownButton, {
    mode,
} from "@/app/shared/CustomDropDownButton/DropDownButton";
import { setFilterState } from "../tasksManagement.reducer";
import axios from "../../../../../../shared/config/axios-interceptor";
import { handleJsonParseFileArr } from "@/shared/utils/ultils";
import { useSelector } from "react-redux";
import { RootState } from "@/reducers";

interface ISubTaskTabProp {
    treeItem: ITaskTree;
    setSelectTaskId: (task: number | string) => void;
    project: IProject;
    setCreateTask?: Function;
}

interface ISubTaskCardProp {
    treeItem: ITaskTree;
    setSelectTaskId: (task: number | string) => void;
}

const SubTaskCard = (props: ISubTaskCardProp) => {
    const { treeItem, setSelectTaskId } = props;
    const userArray =
        treeItem && treeItem?.assigns
            ? treeItem?.assigns?.map((item: any) => item.assign)
            : [];
    const mapTaskTypeToIcon: { [key in TaskTypeEnum]: React.ReactNode } = {
        [TaskTypeEnum.FOLDER]: <FolderIcon className="tree-icon" />,
        [TaskTypeEnum.TASK]: <ClipboardIcon className="tree-icon" />,
    };
    const fileString = treeItem?.files ?? "[]";
    let parsedFiles;
    try {
        parsedFiles = handleJsonParseFileArr(fileString);
    } catch (error) {
        console.error("Lỗi khi parse JSON:", error);
        parsedFiles = [];
    }
    return (
        <div
            className="subtask-card w-100"
            onClick={() => {
                setSelectTaskId(treeItem?.taskId);
            }}
        >
            <div className="subtask-header">
                <div className="subtask-title">
                    <p className="mb-0">
                        {mapTaskTypeToIcon[treeItem?.type as TaskTypeEnum]}{" "}
                        <span className="info-text">{treeItem?.taskId}</span>
                    </p>

                    {(treeItem?.type as TaskTypeEnum) === TaskTypeEnum.TASK ? (
                        <StatusSelect
                            color={
                                mapTaskStatusToColor[
                                    (treeItem?.status ===
                                    ("NEW" as TaskStatusEnum)
                                        ? "NEWTASK"
                                        : treeItem?.status) as TaskStatusEnum
                                ]
                            }
                            value={{
                                value: (treeItem?.status ===
                                ("NEW" as TaskStatusEnum)
                                    ? "NEWTASK"
                                    : treeItem?.status) as TaskStatusEnum,
                                label: mapTaskStatusToString[
                                    (treeItem?.status ===
                                    ("NEW" as TaskStatusEnum)
                                        ? "NEWTASK"
                                        : treeItem?.status) as TaskStatusEnum
                                ],
                            }}
                            minWidth="158px"
                            inModal
                            disabled
                        />
                    ) : (
                        <div style={{ minWidth: "98px" }}>
                            {/* <TaskProgress
                height={6}
                taskProgress={handleGetProgressBar(
                  treeItem,
                  taskStatusProgressArray
                )}
              /> */}
                        </div>
                    )}
                </div>
                <div className="subtask-body">
                    <p className="subtask-name mb-0">{treeItem?.name}</p>
                </div>
            </div>
            <div className="subtask-footer">
                <div className="task-info-wrapper">
                    <span className="task-info">
                        <MessageSquareIcon /> {treeItem?.discussion || 0}
                    </span>
                    <span className="task-info">
                        <LinkIcon /> {parsedFiles?.length || 0}
                    </span>
                </div>
                <AvatarContainer
                    limit={2}
                    size={20}
                    fontSize={10}
                    userArray={userArray}
                />
            </div>
        </div>
    );
};

const SubTaskTab = (props: ISubTaskTabProp) => {
    const { treeItem, setSelectTaskId, setCreateTask, project } = props;
    const [tasks, setTasks] = useState<ITaskTree | null>(null);
    const subTreeArr = tasks ? tasks.children : treeItem.children || [];
    const {createEntitySuccess} = useSelector((state: RootState) => state.tasksReducer.initialState)
    useEffect(() => {
        if (
            treeItem.taskId &&
            String(treeItem.taskId) !== taskIdNameDefault.TOPPROJECT
        ) {
            const fetchData = async () => {
                const response = await axios.get(`task/${treeItem.taskId}`);
                setTasks(response.data?.data);
            };
            fetchData();
        } else {
            setTasks(null);
        }
    }, [treeItem.taskId, createEntitySuccess]);
    const [displayType, setDisplayType] = useState<"grid" | "list">("grid");
    const listMode: mode[] = [
        { name: "Ngày tạo - giảm dần", value: "DESC", field: "createdDate"},
        { name: "Ngày tạo - tăng dần", value: "ASC", field: "createdDate"},
    ];
    const [activeMode, setActiveMode] = useState<mode>(listMode[0]);

    return (
        <>
            <div className="subtask-tab tab-content">
                <div
                    className="subtask-header d-flex justify-content-between  \d-none"
                    style={{ marginBottom: "16px" }}
                >
                    <div className="subtask-function d-flex align-items-center">
                        <button
                            className=""
                            type="button"
                            onClick={(e) => {
                                setCreateTask && setCreateTask(true);
                            }}
                            style={buttonDefault}
                        >
                            <PlusIcon
                                style={{ color: "#F06927" }}
                                width={20}
                                height={20}
                            />{" "}
                            Tạo
                        </button>

                        <CustomDropDownButton
                            setFilterState={setFilterState}
                            activeMode={activeMode}
                            setMode={setActiveMode}
                            listMode={listMode}
                        ></CustomDropDownButton>
                    </div>
                    <div className="subtask-display">
                        {/* <span
              className={`display-type ${
                displayType === "list" ? "active" : ""
              }`}
              onClick={() => setDisplayType("list")}
            >
              <MenuIcon />
            </span> */}
                        <span
                            className={`display-type ${
                                displayType === "grid" ? "active" : ""
                            }`}
                            onClick={() => setDisplayType("grid")}
                        >
                            <DotGridIcon />
                        </span>
                    </div>
                </div>

                <CRow className="subcard-container p-0 m-0">
                    {subTreeArr?.length > 0 ? subTreeArr?.map((item, index) => (
                        <CCol
                            xs={6}
                            className="p-0"
                            // style={{ width: "fit-content" }}
                            key={index}
                            style={{ width: "calc(50% - 10px)" }}
                        >
                            <SubTaskCard
                                treeItem={item}
                                setSelectTaskId={setSelectTaskId}
                            />
                        </CCol>
                    )) : <div className="p-0">Hiện không có công việc nào</div>}
                </CRow>
            </div>
        </>
    );
};

export default SubTaskTab;
