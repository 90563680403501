import { RootState } from "@/reducers";
import { IChat } from "@/shared/model/chat.model";
import { IInitialState, IParams, IResponse } from "@/shared/shared-interfaces";
import {
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createEntity, getEntities, IChatParams, removeEntity, removeMemberRoomEntity, updateEntity } from "./chat.api";

interface IChatInitialState extends IInitialState {
    filterState: IChatParams;
    deleteMemberRoomSuccess?: boolean;
    dataPostGroup: any;
    updateEntityRoomSuccess: boolean;
}

export const initialChatFilter: IChatParams = {
    page: 1,
    limit: 20,
    sortBy: "lastModifiedDate",
    sortOrder: "DESC",
};

const initialState: IChatInitialState = {
    fetchEntitiesSuccess: false,
    fetchEntitySuccess: false,
    updateEntitySuccess: false,
    deleteEntitySuccess: false,
    deleteMemberRoomSuccess: false,
    dataPostGroup: [],
    loading: false,
    errorMessage: null,
    totalItems: 0,
    totalPages: 0,
    filterState: initialChatFilter,
    updateEntityRoomSuccess: false,
};

export const chatAdapter = createEntityAdapter<IChat>({
    selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
    name: "chatSlice",
    initialState: chatAdapter.getInitialState({ initialState }),
    reducers: {
        setFilterState(state, { payload }: PayloadAction<IParams>) {
            state.initialState.filterState = payload;
        },
        fetching(state) {
            state.initialState.loading = true;
        },
        resetAll(state) {
            state.initialState.loading = false;
            state.initialState.fetchEntitiesSuccess = false;
            state.initialState.fetchEntitySuccess = false;
            state.initialState.updateEntitySuccess = false;
            state.initialState.deleteEntitySuccess = false;
            state.initialState.errorMessage = null;
            state.initialState.deleteMemberRoomSuccess = false;
            state.initialState.dataPostGroup = null;
            state.initialState.updateEntityRoomSuccess = false;

        },
        resetEntity(state) {
            state.initialState.updateEntitySuccess = false;
            state.initialState.errorMessage = null;
            state.initialState.deleteEntitySuccess = false;
            state.initialState.dataPostGroup = null;
            state.initialState.loading = false;

        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getEntities.pending.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.loading = true;
            }
        );
        builder.addCase(
            getEntities.fulfilled.type,
            (
                state,
                { payload }: PayloadAction<AxiosResponse<IResponse<IChat[]>>>
            ) => {
                chatAdapter.setAll(state, payload.data.data);
                state.initialState.totalItems = Number(payload.data.count);
                state.initialState.totalPages = Number(
                    payload.data.total_pages
                );
                state.initialState.fetchEntitiesSuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            getEntities.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.fetchEntitiesSuccess = false;
            }
        );

        builder
            .addCase(createEntity.pending.type, (state, action) => {
                state.initialState.loading = true;
            })
            .addCase(createEntity.fulfilled.type, (state, { payload }: any) => {
                // departmentAdapter.addOne(state, payload);
                state.initialState.dataPostGroup = payload;
                state.initialState.updateEntitySuccess = true;
                state.initialState.loading = false;
            })
            .addCase(
                createEntity.rejected.type,
                (state, { payload }: PayloadAction<any>) => {
                    state.initialState.errorMessage = payload?.code;
                    state.initialState.loading = false;
                    state.initialState.updateEntitySuccess = false;
                }
            );

        builder
            .addCase(updateEntity.pending.type, (state, action) => {
                state.initialState.loading = true;
            })
            .addCase(updateEntity.fulfilled.type, (state, { payload }: any) => {
                state.initialState.updateEntityRoomSuccess = true;
                state.initialState.loading = false;
            })
            .addCase(
                updateEntity.rejected.type,
                (state, { payload }: PayloadAction<any>) => {
                    state.initialState.errorMessage = payload?.code;
                    state.initialState.loading = false;
                    state.initialState.updateEntityRoomSuccess = false;
                }
            );

             builder
            .addCase(removeEntity.pending.type, (state, action) => {
                state.initialState.loading = true;
            })
            .addCase(removeEntity.fulfilled.type, (state, { payload }: any) => {
                // departmentAdapter.addOne(state, payload);
                state.initialState.deleteEntitySuccess = true;
                state.initialState.loading = false;
            })
            .addCase(
                removeEntity.rejected.type,
                (state, { payload }: PayloadAction<any>) => {
                    state.initialState.errorMessage = payload?.code;
                    state.initialState.loading = false;
                    state.initialState.deleteEntitySuccess = false;
                    // state.initialState.updateEntitySuccess = false;
                }
            );

            builder
            .addCase(removeMemberRoomEntity.pending.type, (state, action) => {
                state.initialState.loading = true;
            })
            .addCase(removeMemberRoomEntity.fulfilled.type, (state, { payload }: any) => {
                // departmentAdapter.addOne(state, payload);
                // state.initialState.rem = true;
                state.initialState.deleteMemberRoomSuccess = true;
                state.initialState.loading = false;
            })
            .addCase(
                removeMemberRoomEntity.rejected.type,
                (state, { payload }: PayloadAction<any>) => {
                    state.initialState.errorMessage = payload?.code;
                    state.initialState.loading = false;
                    state.initialState.deleteMemberRoomSuccess = false;
                    // state.initialState.updateEntitySuccess = false;
                }
            );
    },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const chatSelectors = chatAdapter.getSelectors<RootState>(
    (state) => state.chatReducer
);

const { selectById } = chatAdapter.getSelectors();
const getChatState = (rootState: RootState) => rootState.chatReducer;

export const selectEntityById = (id: string) => {
    return createSelector(getChatState, (state) => selectById(state, id));
};
