import { ISelectValue } from "@/shared/shared-interfaces";
import Select, { SingleValue } from "react-select";

interface IStatusSelect<T> {
  value: ISelectValue<T>;
  color: string;
  inModal?: boolean;
  options?: ISelectValue<T>[];
  minWidth?: string;
  disabled?: boolean;
  onChange?: (value: ISelectValue<T> | null) => void;
}

const StatusSelect = <T,>(props: IStatusSelect<T>) => {
  const { color, onChange, value, options, minWidth, disabled, inModal } =
    props;

  const customStyles = {
    menu: (provided: any, state: any) => ({
      ...provided,
      minWidth: minWidth || "unset",
    }),
    // menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <div>
      <Select
        // menuIsOpen={true}
        isDisabled={disabled}
        closeMenuOnSelect={true}
        closeMenuOnScroll={true}
        defaultValue={null}
        className={`status-select ${disabled ? "read-only" : ""} ${color}`}
        classNamePrefix="react-select"
        value={value}
        onChange={(newValue: SingleValue<ISelectValue<T>>) => {
          onChange && onChange(newValue || null);
        }}
        noOptionsMessage={() => <>Không có trạng thái nào</>}
        options={options || []}
        placeholder={"Chọn Trạng thái"}
        isClearable={false}
        isSearchable={false}
        menuPosition={"absolute"}
        menuPortalTarget={inModal ? undefined : document.body}
        styles={customStyles}
      />
    </div>
  );
};

export default StatusSelect;
