import axios from "@/shared/config/axios-interceptor";
import { IParams } from "@/shared/shared-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
const prefix = "notifications";
// const history = 'revision';

export type INotificationParams = IParams;

export const getEntities = createAsyncThunk(
  `get-all-${prefix}`,
  async (id: any, thunkAPI) => {
    try {
      return await axios.get<INotificationParams[]>(`${prefix}/user/${id}`);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateReadNotification = createAsyncThunk(
  `get-reader-noti-${prefix}`,
  async (field: any, thunkAPI) => {
    try {
      const body = pickBy(field);
      return await axios.put<INotificationParams[]>(`${prefix}/read-notification`, body);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
