import React from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

const CalenderOverview = React.lazy(() => import("./CalenderOverview"));

const CalenderLayout: RouteObject[] = [
  {
    path: "/",
    element: <CalenderOverview />,
  },

  { path: "*", element: <Navigate to="/404" /> },
];

const CalenderRoutes = () => useRoutes(CalenderLayout);
export default CalenderRoutes;
