import React from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

const DepartmentOverview = React.lazy(
    () => import("./components/DepartmentOverview")
);

const MyDepartmentLayout: RouteObject[] = [
    {
        path: "/",
        element: (
            <>
                <DepartmentOverview />
            </>
        ),
    },

    { path: "*", element: <Navigate to="/404" /> },
];

const DepartmentRoutes = () => useRoutes(MyDepartmentLayout);
export default DepartmentRoutes;
