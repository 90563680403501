import React from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

const ProjectManagement = React.lazy(() => import("./ProjectManagement"));
const TasksManagementGantt = React.lazy(
  () => import("./Detail/Tasks/TasksManagementGantt")
);
const ProjectDetailContainer = React.lazy(
  () => import("./Detail/ProjectDetailContainer")
);
const MembersManagement = React.lazy(
  () => import("./Detail/Members/MembersManagement")
);
const ProjectDocumentManagementLayout = React.lazy(
  () => import("./Detail/Document/routes")
);
const Forums = React.lazy(() => import("./Detail/Forums/Forums"));
const ProjectOverview = React.lazy(
  () => import("./Detail/Overview/ProjectOverview")
);

const projectDetailRoutes: RouteObject[] = [
  { path: "overview", element: <ProjectOverview /> },
  { path: "tasks", element: <TasksManagementGantt /> },
  { path: "documents/*", element: <ProjectDocumentManagementLayout /> },
  { path: "members", element: <MembersManagement /> },
  { path: "forums", element: <Forums /> },
];

const ProjectManagementLayout: RouteObject[] = [
  {
    path: "/",
    element: (
      // <ProtectedComponent requiredPerms={[P]}>
      <ProjectManagement />
      // </ProtectedComponent>
    ),
  },

  {
    path: `:id/*`,
    element: <ProjectDetailContainer />,
    children: projectDetailRoutes,
    caseSensitive: true,
  },

  //   {
  //     path: `${RouteName.CREATE}`,
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserUpdate />
  //       </ProtectedComponent>
  //     ),
  //   },

  //   {
  //     path: `:id/${RouteName.UPDATE}`,
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserUpdate />
  //       </ProtectedComponent>
  //     ),
  //   },

  //   {
  //     path: `:id/${RouteName.DETAIL}`,
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserDetail />
  //       </ProtectedComponent>
  //     ),
  //   },

  //   {
  //     path: '/:id/change-password',
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserChangePassword />
  //       </ProtectedComponent>
  //     ),
  //   },
  //   {
  //     path: `:id/${RouteName.HISTORY}`,
  //     element: (
  //       <ProtectedComponent requiredPerms={[Roles.ADMIN, Roles.SYSTEM_MANAGEMENT]}>
  //         <UserHistory />
  //       </ProtectedComponent>
  //     ),
  //   },
  // {
  //   path: '/create',
  //   element: (
  //     <ProtectedComponent requiredPerms={[SystemRoles.ROLE_ADMIN]}>
  //       <CommissionConfigsUpdate />
  //     </ProtectedComponent>
  //   ),
  // },
  { path: "*", element: <Navigate to="/404" /> },
];

const ProjectManagementRoutes = () => useRoutes(ProjectManagementLayout);
export default ProjectManagementRoutes;
