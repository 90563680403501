import { ReactComponent as CheckSquareIcon } from "@/assets/img/check-square.svg";
import { ReactComponent as ClipboardIcon } from "@/assets/img/clipboard-icon.svg";

import { ReactComponent as InfoIcon } from "@/assets/img/info-icon.svg";

import { ReactComponent as MessageTextSquareIcon } from "@/assets/img/message-text-square.svg";
import { ReactComponent as ClockFastForwardIcon } from "@/assets/img/clock-fast-forward-icon.svg";

import projectWallpaper from "@/assets/img/project-detail-wallpaper.png";
import SModal from "@/app/shared/Modal/SModal";
import { RootState } from "@/reducers";
import {
  taskIdNameDefault,
  TaskTypeEnum,
} from "@/shared/enumeration/task.enum";
import { IProject } from "@/shared/model/project.modal";
import { ITask } from "@/shared/model/task.model";
import { AppDispatch } from "@/store";
import {
  CModalBody,
  CModalHeader,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from "@coreui/react-pro";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ITaskTree } from "../TasksManagementGantt";
import { resetEntity, resetOpenProject, selectEntityById } from "../tasksManagement.reducer";
import SubTaskTab from "./SubTaskTab";
import TaskDetailHeader from "./TaskDetailHeader";
import TaskDiscussTab from "./TaskDiscussTab";
import TaskInfomationTab from "./TaskInfomationTab";
import TaskResultTab from "./TaskResult/TaskResultTab";
import TaskActiveTab from "./TaskActiveTab";
import axios from "../../../../../../shared/config/axios-interceptor";
import { IProjectMember } from "@/shared/model/projectMember.modal";

enum TaskDetailTabEnum {
  TASK = "TASK",
  INFO = "INFO",
  RESULT = "RESULT",
  DISCUSS = "DISCUSS",
  ACTIVITY = "ACTIVITY",
}

interface ITaskDetailModalProps {
  visible: boolean;
  setVisible: Function;
  project: IProject;
  treeItemArr?: ITaskTree[];
  chosenTaskId: number | string;
  isMyTask?: boolean;
  isAddMember?: boolean;
  setCreateTask?: Function;
  projectMembers?: IProjectMember[];
}

interface TaskTypeList {
  name: string;
  icon: ReactNode;
  condition?: (string | taskIdNameDefault | TaskTypeEnum)[];
  typeTab: TaskDetailTabEnum;
}

// const handleGetTreeItemCurrent = (treeItem: ITaskTree, treeItemArr: ITaskTree[]): ITaskTree => {
//   const currentTreeItem = treeItemArr.find((item) => item.id === treeItem.id);
//   if (currentTreeItem) {
//     return currentTreeItem;
//   }
//   const subTreeArr = treeItemArr.map((item) => item.children || []).flat();
//   return handleGetTreeItemCurrent(treeItem, subTreeArr);
// };

// const handleGetTreeItemTraceToCurrent = (
//   treeItem: TreeItem,
//   treeItemArr: TreeItem[],
//   parentTrace: TreeItem[] = [],
//   traceArr: TreeItem[] = []
// ): TreeItem[] => {
//   if (treeItemArr.length === 0) {
//     return traceArr;
//   }
//   for (let index = 0; index < treeItemArr.length; index++) {
//     if (treeItemArr[index].id === treeItem.id) {
//       traceArr = parentTrace.concat([treeItemArr[index]]);
//     }
//     if (
//       treeItemArr[index].subTree &&
//       (treeItemArr[index]?.subTree?.length || 0) > 0 &&
//       treeItemArr[index].id !== treeItem.id
//     ) {
//       parentTrace.push(treeItemArr[index]);
//       traceArr = handleGetTreeItemTraceToCurrent(treeItem, treeItemArr[index]?.subTree || [], parentTrace, traceArr);
//     }
//     if (
//       (!treeItemArr[index].subTree || (treeItemArr[index]?.subTree?.length || 0) > 0) &&
//       treeItemArr[index].id !== treeItem.id
//     ) {
//       parentTrace = parentTrace.filter((trace) => trace.id === 'topProject');
//     }
//   }

//   return traceArr;
// };

// Need to refactor this function

// const handleGetTreeItemTraceToCurrent = (
//   treeItem: ITaskTree,
//   treeItemArr: ITaskTree[],
//   parentTrace: ITaskTree[] = [],
//   traceArr: ITaskTree[] = []
// ): ITaskTree[] => {
//   if (treeItemArr.length === 0) {
//     return traceArr;
//   }
//   treeItemArr.forEach((item) => {
//     if (item.id === treeItem.id) {
//       traceArr = parentTrace.concat([item]);
//     }
//     if (item.children && (item?.children?.length || 0) > 0 && item.id !== treeItem.id) {
//       parentTrace.push(item);
//       traceArr = handleGetTreeItemTraceToCurrent(treeItem, item?.children || [], parentTrace, traceArr);
//     }
//     if ((!item.children || (item?.children?.length || 0) > 0) && item.id !== treeItem.id) {
//       parentTrace = parentTrace.filter((trace) => trace.id === 'topProject');
//     }
//   });

//   return traceArr;
// };

const TaskDetailModal = (props: ITaskDetailModalProps) => {
  const {
    visible,
    setVisible,
    chosenTaskId,
    project,
    treeItemArr,
    isMyTask = false,
    isAddMember = false,
    setCreateTask,
    projectMembers,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector(
    (state: RootState) => state.tasksReducer
  );
  const { fetchEntitySuccess} = initialState;

  const [activeTab, setActiveTab] = useState<TaskDetailTabEnum>(
    () => TaskDetailTabEnum.TASK
  );

  // const currentTask = handleGetTreeItemCurrent(chosenTask, taskTreeItem);

  const [selectTaskId, setSelectTaskId] = useState<number | string>(
    chosenTaskId
  );

  const [currentTask, setCurrentTask] = useState<ITask | undefined>(undefined);

  const taskEntity = useSelector(
    selectEntityById(
      selectTaskId !== taskIdNameDefault.TOPPROJECT ? selectTaskId : ""
    )
  );

  const [taskNameValue, setTaskNameValue] = useState<string | null>(null);

  const currentSelectId = useRef<number | string | null>(null);

  const handleSetActiveTab = (tab: TaskDetailTabEnum) => () =>
    setActiveTab(tab);

  const handleSelectId = (id: number | string) => {
    currentSelectId.current = selectTaskId;
    setTaskNameValue(null);
    setSelectTaskId(id);
  };

  const handleOnClose = () => {
    setVisible(false);
    dispatch(resetOpenProject());
  };

  const prop = useSelector((state: RootState) => state.tasksReducer);
  const state = prop.initialState;
  useEffect(() => {
    if (taskEntity) {
      setCurrentTask(taskEntity);
      dispatch(resetEntity());
    } else {
      if (chosenTaskId && chosenTaskId !== taskIdNameDefault.TOPPROJECT && !isMyTask) {
        const fetchData = async () => {
          const response = await axios.get(`task/${chosenTaskId}`);
          setCurrentTask(response.data?.data);
        };
        fetchData();
        dispatch(resetEntity());
      }
    }
    dispatch(resetEntity());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskEntity, state.updateEntitySuccess]);

  useEffect(() => {
    if (selectTaskId !== taskIdNameDefault.TOPPROJECT) {
      // dispatch(getEntity(String(selectTaskId)));
    } else {
      setCurrentTask(undefined);
      setActiveTab(TaskDetailTabEnum.TASK);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTaskId]);

  useEffect(() => {
    if (
      (currentSelectId.current === null ||
        currentSelectId.current !== selectTaskId) &&
      currentTask
    ) {
      setActiveTab(
        currentTask?.type === TaskTypeEnum.TASK
          ? TaskDetailTabEnum.INFO
          : TaskDetailTabEnum.TASK
      );
      currentSelectId.current = selectTaskId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTask]);

  useEffect(() => {
    return () => {
      currentSelectId.current = null;
    };
  }, []);

  const getTreeItem = () => {
    if (selectTaskId === taskIdNameDefault.TOPPROJECT) {
      return treeItemArr?.[0];
    } else {
      const getSubtree = currentTask?.children;
      const treeItemConvert = {
        ...currentTask,
        id: currentTask?.taskId,
        subTree: getSubtree,
      };
      return treeItemConvert;
    }
  };

  const taskTypeList: TaskTypeList[] = [
    {
      name: "Công việc",
      icon: <ClipboardIcon />,
      condition: [TaskTypeEnum.FOLDER, taskIdNameDefault.TOPPROJECT],
      typeTab: TaskDetailTabEnum.TASK,
    },
    {
      name: "Thông tin",
      icon: <InfoIcon />,
      condition: [TaskTypeEnum.FOLDER, TaskTypeEnum.TASK],
      typeTab: TaskDetailTabEnum.INFO,
    },
    {
      name: "Kết quả",
      icon: <CheckSquareIcon />,
      condition: [TaskTypeEnum.TASK],
      typeTab: TaskDetailTabEnum.RESULT,
    },
    {
      name: "Thảo luận",
      icon: <MessageTextSquareIcon />,
      condition: [TaskTypeEnum.FOLDER, TaskTypeEnum.TASK],
      typeTab: TaskDetailTabEnum.DISCUSS,
    },
    {
      name: "Hoạt động",
      icon: <ClockFastForwardIcon />,
      typeTab: TaskDetailTabEnum.ACTIVITY,
    },
  ];

  return (
    <SModal
      visible={visible}
      onClose={handleOnClose}
      backdrop="static"
      className="task-detail-modal custom-form overflow-hidden-module"
    >
      <>
        <CModalHeader
          closeButton={false}
          style={{
            backgroundImage: `url(${projectWallpaper})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <TaskDetailHeader
            projectMembers={projectMembers}
            isMyTask={isMyTask}
            isAddMember={isAddMember}
            currentTask={getTreeItem() as ITaskTree}
            project={project}
            handleSelectId={handleSelectId}
            onClose={handleOnClose}
            selectTaskId={selectTaskId}
            setTaskNameValue={setTaskNameValue}
            taskNameValue={taskNameValue}
          />
        </CModalHeader>
        <CModalBody className="">
          <div className="modal-tab-container">
            <CNav variant="underline" role="tablist" className="custom-nav">
              {taskTypeList?.map((value, index) => {
                if (
                  !value?.condition ||
                  (currentTask?.type &&
                    value?.condition?.includes(currentTask?.type)) ||
                  (typeof selectTaskId === "string" &&
                    value?.condition?.includes(selectTaskId))
                ) {
                  return (
                    <CNavItem role="presentation" key={`tab-${index}`}>
                      <CNavLink
                        active={activeTab === value?.typeTab}
                        component="button"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected={activeTab === value?.typeTab}
                        onClick={handleSetActiveTab(value?.typeTab)}
                      >
                        {value?.icon} {value?.name}
                      </CNavLink>
                    </CNavItem>
                  );
                }
                return <></>;
              })}
            </CNav>
          </div>
          <CTabContent className="tab-container">
            {activeTab === TaskDetailTabEnum.TASK ? (
              <CTabPane
                role="tabpanel"
                visible={activeTab === TaskDetailTabEnum.TASK}
              >
                <SubTaskTab
                  treeItem={getTreeItem() as ITaskTree}
                  setSelectTaskId={handleSelectId}
                  project={project}
                  setCreateTask={setCreateTask}
                />
              </CTabPane>
            ) : (
              ""
            )}

            {activeTab === TaskDetailTabEnum.INFO ? (
              <CTabPane
                role="tabpanel"
                visible={activeTab === TaskDetailTabEnum.INFO}
              >
                {currentTask ? (
                  <TaskInfomationTab
                    project={project}
                    taskObj={currentTask as ITaskTree}
                  />
                ) : (
                  ""
                )}
              </CTabPane>
            ) : (
              ""
            )}
            {activeTab === TaskDetailTabEnum.RESULT && currentTask ? (
              <CTabPane
                role="tabpanel"
                visible={activeTab === TaskDetailTabEnum.RESULT}
              >
                <TaskResultTab taskObj={currentTask} />
              </CTabPane>
            ) : (
              ""
            )}

            {activeTab === TaskDetailTabEnum.DISCUSS && currentTask ? (
              <CTabPane
                role="tabpanel"
                visible={activeTab === TaskDetailTabEnum.DISCUSS}
              >
                <TaskDiscussTab taskObj={currentTask} />
              </CTabPane>
            ) : (
              ""
            )}

            {activeTab === TaskDetailTabEnum.ACTIVITY && currentTask ? (
              <CTabPane
                role="tabpanel"
                visible={activeTab === TaskDetailTabEnum.ACTIVITY}
              >
                <TaskActiveTab task={currentTask}></TaskActiveTab>
              </CTabPane>
            ) : (
              ""
            )}
          </CTabContent>
        </CModalBody>
      </>
    </SModal>
  );
};

export default TaskDetailModal;
