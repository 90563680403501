import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CCol, CContainer, CRow, CSmartTable } from "@coreui/react-pro";
import { RootState } from "@/reducers";
import { IUser } from "@/shared/model/user.model";
import { ITask } from "@/shared/model/task.model";
import { getEntitiesMyTask } from "@/app/modules/MyTask/store/myTask.api";
import { setFilterState } from "@/app/modules/MyTask/store/myTask.reducer";
import CustomTableFooter from "../CustomTableFooter/CustomTableFooter";
import AvatarContainer from "../AvatarContainer/AvatarContainer";
import StatusSelect from "../StatusSelect/StatusSelect";
import { compareTime, formatDate } from "@/shared/utils/ultils";
import {
  mapOverviewStatusToColor,
  mapOverviewStatusToString,
  taskStatusArray,
  TaskStatusEnum,
} from "@/shared/enumeration/task.enum";

const ITEMS_PER_PAGE = 4;

function Work({ user }: { user: IUser | undefined }) {
  const dispatch = useDispatch();
  const { taskDetails, filterState } = useSelector(
    (state: RootState) => state.myTask.initialState
  );

  useEffect(() => {
    if (user?.id) {
      dispatch(getEntitiesMyTask(user.id) as any);
    }
  }, [user?.id]);

  // useEffect(() => {
  //   dispatch(
  //     setFilterState({ ...filterState, limit: ITEMS_PER_PAGE, page: 1 })
  //   );
  // }, []);

  const columns = [
    {
      key: "name",
      label: "Công việc",
      sorter: false,
      _style: { width: "36%", minWidth: "250px" },
    },
    {
      key: "member",
      label: "Giao cho",
      sorter: false,
      _style: { width: "20%", minWidth: "150px" },
    },
    {
      key: "progress",
      label: "Trạng thái",
      sorter: false,
      _style: { width: "25%", minWidth: "160px" },
    },
    {
      key: "endedDate",
      label: "Ngày kết thúc",
      sorter: false,
      _style: { width: "25%", minWidth: "100px" },
    },
  ];

  const paginatedTaskDetails = useMemo(() => {
    const startIndex = (filterState.page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return taskDetails.slice(startIndex, endIndex);
  }, [taskDetails, filterState.page]);

  const handlePaginationChange = (page: number) => {
    dispatch(setFilterState({ ...filterState, page }));
  };

  const totalPages = Math.ceil(taskDetails.length / ITEMS_PER_PAGE);

  return (
    <CContainer
      fluid
      style={{
        paddingLeft: "32px",
        paddingRight: "32px",
      }}
    >
      <CRow style={{ width: "100%" }}>
        <CCol xs={12}>
          <div className="table-content my-3">
            <CSmartTable
              header
              columns={columns}
              items={paginatedTaskDetails}
              clickableRows
              noItemsLabel="Không có bản ghi"
              itemsPerPage={ITEMS_PER_PAGE}
              scopedColumns={{
                name: (item: ITask) => (
                  <td>
                    <div className="avatar-container-display">
                      <div>
                        <p
                          style={{
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "18px",
                            color: "#121926",
                          }}
                        >
                          {item.name}
                        </p>
                      </div>
                    </div>
                  </td>
                ),
                member: (item: ITask) => {
                  const userArray =
                    item?.assignUsers?.[0]?.assigns?.map(
                      (assignItem: any) => assignItem.assign
                    ) ??
                    item?.assigns?.map((assignItem: any) => assignItem.assign);
                  return (
                    <td>
                      <AvatarContainer
                        limit={5}
                        size={20}
                        userArray={userArray}
                      />
                    </td>
                  );
                },
                progress: (item: ITask) => (
                  <td>
                    <StatusSelect
                      color={
                        mapOverviewStatusToColor[
                          compareTime(
                            item?.startedDate,
                            item?.endDate
                          ) as TaskStatusEnum
                        ]
                      }
                      options={taskStatusArray.map((statusItem) => ({
                        value: statusItem,
                        label: mapOverviewStatusToString[statusItem],
                      }))}
                      value={{
                        value: compareTime(
                          item?.startedDate,
                          item?.endDate
                        ) as TaskStatusEnum,
                        label:
                          mapOverviewStatusToString[
                            compareTime(
                              item?.startedDate,
                              item?.endDate
                            ) as TaskStatusEnum
                          ],
                      }}
                      disabled={true}
                      minWidth="158px"
                    />
                  </td>
                ),
                endedDate: (item: ITask) => (
                  <td>{formatDate(item.endDate || undefined, "DD/MM/YYYY")}</td>
                ),
              }}
              tableProps={{
                hover: true,
                align: "middle",
                responsive: "lg",
                className: "custom-table",
                style: { borderRadius: "8px" },
              }}
              tableHeadProps={{
                className: "table-head",
              }}
              tableBodyProps={{
                className: "table-body",
              }}
            />
          </div>
        </CCol>
      </CRow>
      <div style={{ paddingBottom: "14px" }}>
        <CustomTableFooter
          totalItems={taskDetails.length}
          hideSideChosen
          totalPages={totalPages}
          filterState={filterState}
          setFilterState={(filter) => dispatch(setFilterState(filter))}
          handlePaginationChange={handlePaginationChange}
        />
      </div>
    </CContainer>
  );
}

export default Work;
