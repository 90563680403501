import axios from "@/shared/config/axios-interceptor";
import { IChat, INewChat } from "@/shared/model/chat.model";
import { IParams } from "@/shared/shared-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";

const prefix = "room";

export type IChatParams = IParams;

export const getEntities = createAsyncThunk(
    `get-chat-all-${prefix}`,
    async (fields: IChatParams, thunkAPI) => {
        try {
            const params = pickBy(fields);
            return await axios.get<IChat[]>(`${prefix}`, { params });
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getEntity = createAsyncThunk(
    `get-chat-single-${prefix}`,
    async (id: string, thunkAPI) => {
        try {
            const { data } = await axios.get<IChat>(`${prefix}/${id}`);
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createEntity = createAsyncThunk(
    `create-chat-room-one-${prefix}`,
    async (body: INewChat, thunkAPI) => {
        try {
            const { data } = await axios.post(`${prefix}`, pickBy(body));
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateEntity = createAsyncThunk(
    `update-chat-room-one-${prefix}`,
    async (body: any, thunkAPI) => {
        try {
            const { id } = body;
            const { data } = await axios.put<IChat>(`${prefix}`, pickBy(body));
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const removeEntity = createAsyncThunk(
    `delete-chat-room-one-${prefix}`,
    async (id: string, thunkAPI) => {
        try {
            await axios.delete(`${prefix}`, {data: [id]});
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const removeMemberRoomEntity = createAsyncThunk(
    `delete-member-chat-room-api-one-${prefix}`,
    async (body: { users: string[]; id: string }, thunkAPI) => {
        try {
            return await axios.delete(`${prefix}/user-room`, {data: pickBy(body)});
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
