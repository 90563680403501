import axios from '@/shared/config/axios-interceptor';
import { IAddProjectMember, IProjectMember } from '@/shared/model/projectMember.modal';
import { IParams } from '@/shared/shared-interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';

const prefix = 'project';

export interface IProjectMemberParams extends IParams {
  projectId: number;
}

export const getEntities = createAsyncThunk(
  `get-all-${prefix}-members`,
  async (fields: IProjectMemberParams, thunkAPI) => {
    try {
      const { projectId } = fields;
      const params = pickBy(fields);
      return await axios.get<IProjectMember[]>(`${prefix}/staff/${projectId}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntities = createAsyncThunk(
  `add-many-${prefix}-member`,
  async (body: IAddProjectMember, thunkAPI) => {
    try {
      const { data } = await axios.post(`${prefix}/add-staff`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateEntity = createAsyncThunk(`update-one-${prefix}-member`, async (body: IProjectMember, thunkAPI) => {
  try {
    const { userId, projectId, roleProject } = body;
    const { data } = await axios.put<IProjectMember>(`${prefix}/staff/${projectId}/${userId}`, { roleProject });
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`remove-one-${prefix}-member`, async (body: IProjectMember, thunkAPI) => {
  try {
    const { userId, projectId } = body;
    const data = {
      userIds: [userId],
      projectId: projectId,
    };
    await axios.delete(`${prefix}/staff`, { data });
    return userId;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
