import { RootState } from "@/reducers";
import { IProjectMember } from "@/shared/model/projectMember.modal";
import { IInitialState, IResponse } from "@/shared/shared-interfaces";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import {
  createEntities,
  getEntities,
  IProjectMemberParams,
  removeEntity,
  updateEntity,
} from "./memberManagement.api";

interface IProjectMemberInitialState extends IInitialState {
  updateEntityStatusSuccess: boolean;
  filterState: IProjectMemberParams;
}

export const initialProjectMemberFilter: IProjectMemberParams = {
  page: 1,
  limit: 10,
  sortBy: "createdDate",
  sortOrder: "DESC",
  projectId: 0,
};

const initialState: IProjectMemberInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  createEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  totalPages: 0,
  updateEntityStatusSuccess: false,
  filterState: initialProjectMemberFilter,
};

export const projectMembersAdapter = createEntityAdapter<IProjectMember>({
  selectId: ({ userId }) => userId,
});

const { actions, reducer } = createSlice({
  name: "projectMembersSlice",
  initialState: projectMembersAdapter.getInitialState({ initialState }),
  reducers: {
    setFilterState(state, { payload }: PayloadAction<IProjectMemberParams>) {
      state.initialState.filterState = payload;
    },
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.createEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.createEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<IProjectMember[]>>>
      ) => {
        projectMembersAdapter.setAll(state, payload.data.data);
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
    builder.addCase(
      updateEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IProjectMember>) => {
        projectMembersAdapter.updateOne(state, {
          id: payload.userId,
          changes: payload,
        });
        state.initialState.updateEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      updateEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
    builder.addCase(
      createEntities.fulfilled.type,
      (state, { payload }: PayloadAction<IProjectMember[] | null>) => {
        if (payload) {
          // projectMembersAdapter.addMany(state, payload);
          state.initialState.totalItems += payload.length;
          state.initialState.createEntitySuccess = true;
        } else {
          state.initialState.errorMessage = "Received null data from server";
          state.initialState.createEntitySuccess = false;
        }
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      createEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
    builder.addCase(
      removeEntity.fulfilled.type,
      (state, { payload }: PayloadAction<string>) => {
        projectMembersAdapter.removeOne(state, payload);
        state.initialState.totalItems -= 1;
        state.initialState.deleteEntitySuccess = true;
        state.initialState.updateEntitySuccess = false;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      removeEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.deleteEntitySuccess = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
  },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const projectMembersSelectors =
  projectMembersAdapter.getSelectors<RootState>(
    (state) => state.projectMemberReducer
  );

const { selectById } = projectMembersAdapter.getSelectors();
const getProjectMemberState = (rootState: RootState) =>
  rootState.projectMemberReducer;

export const selectEntityById = (id: string) => {
  return createSelector(getProjectMemberState, (state) =>
    selectById(state, id)
  );
};
