import { CProgress, CProgressStacked } from "@coreui/react-pro";

export interface IProgressBar {
  className: string;
  progress: number;
}

interface ITaskProgress {
  taskProgress: IProgressBar[];
  height?: number;
}

const TaskProgress = (props: ITaskProgress) => {
  const { taskProgress, height = 6 } = props;

  return (
    <>
      <CProgressStacked
        className="progress-bar-stacked"
        style={{ height: `${height}px` }}
      >
        {taskProgress?.map((item, index) => (
          <CProgress
            key={index}
            height={height}
            value={item?.progress}
            className={`progress-bar-custom ${item.className}`}
          />
        ))}
      </CProgressStacked>
    </>
  );
};

export default TaskProgress;
