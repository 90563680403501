import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "./app/containers/ErrorBoundary";
import TheContainer from "../src/app/containers/components/TheContainer";
import store from "./store";

const App = () => {
  return (
    <ErrorBoundary>
      {/* <React.StrictMode> */}
      <Provider store={store}>
        <TheContainer />
      </Provider>
      {/* </React.StrictMode> */}
    </ErrorBoundary>
  );
};

export default App;
