import { uploadFile } from "@/app/modules/sharedApi/shared.api";
import { FileType } from "@/app/shared/TaskUploadContainer/FormikMultiFileUpload";
import dayjs, { Dayjs } from "dayjs";
import { IFileUpload } from "../model/fileUpload.model";
import { IParams } from "../shared-interfaces";
import _ from "lodash";
import { TaskStatusEnum } from "../enumeration/task.enum";
interface ITimeCode {
  hours: number;
  minutes: number;
  seconds: number;
  frames: number;
}

export const checkIsLocalhost = (): boolean => {
  const isLocalHost =
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    Boolean(
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
    );
  return isLocalHost;
};

export const createIndexes = <T, G extends IParams>(data: T[], filter: G) => {
  const { page, limit } = filter;
  return data.map((element, index) => ({
    ...element,
    index: (page - 1) * limit + index + 1,
  }));
};

const noMoreThanOneCommas = (input: number | string) => {
  const str = input.toString();
  let commasCount = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === ".") commasCount++;
    if (commasCount > 1) break;
  }
  return commasCount <= 1;
};

export const formatDate = (
  input: string | Date | undefined,
  format: string
): string => {
  return dayjs(input).format(format);
};

export const insertCommas = (
  input: number | undefined | string,
  decimals = 4
) => {
  if (typeof input === "undefined") return "";
  if (!noMoreThanOneCommas(input)) return "";
  const parts = input.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (parts[1]) parts[1] = parts[1].substring(0, decimals); // Only take the first 4 decimals
  return parts.join(".");
};

export const unInsertCommas = (input: string) => {
  const parts = input.split(".");
  parts[0] = parts[0].replaceAll(",", "");
  if (parts[1]) parts[1] = parts[1].substring(0, 4); // Only take the first 4 decimals
  return parts.join(".");
};

export const getEllipsisTxt = (str: string, n = 5) => {
  if (str) {
    return str.length > n
      ? `${str.slice(0, n)}...${str.slice(str.length - n)}`
      : str;
  }
  return "";
};

export const getTruncateTxt = (str: string | undefined, n = 10) => {
  if (str) {
    return str.length > n ? `${str.substring(0, n)}...` : str;
  }
  return "";
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const handleJsonParseArr = (jsonString: string | undefined) => {
  if (!jsonString) return [];
  // try catch to handle JSON.parse
  try {
    const array: string[] = jsonString ? JSON.parse(jsonString) : [];
    return array;
  } catch (e) {
    return [];
  }
};

export const handleJsonParseFileArr = (jsonString: string | undefined) => {
  if (!jsonString) return [];
  // try catch to handle JSON.parse
  try {
    const array: FileType[] = jsonString ? JSON.parse(jsonString) : [];
    return array;
  } catch (e) {
    return [];
  }
};

export const handleJsonParseFileType = (
  jsonString: string | undefined
): FileType | null => {
  if (!jsonString) return null;
  // try catch to handle JSON.parse
  try {
    const fileType: FileType = jsonString ? JSON.parse(jsonString) : null;
    return fileType;
  } catch (e) {
    return null;
  }
};

export const checkIsDateBefore = (startDate: Dayjs, endDate: Dayjs) => {
  if (startDate.isBefore(endDate)) return true;
  return false;
};

export const checkIsDateAfter = (startDate: Dayjs, endDate: Dayjs) => {
  if (startDate.isAfter(endDate)) return true;
  return false;
};

export const handleUploadImage = async (file: File | undefined) => {
  if (!file) return "";
  try {
    const newImagePromies: IFileUpload = await uploadFile([file]);
    const newImageUrlArr = newImagePromies.photo_url.map(
      (item) => item.image_url
    );
    return newImageUrlArr[0];
  } catch (e) {
    throw Error("Error upload file");
  }
};

const handleRemapingFile = (fileArray: FileType[], urlArr: string[]) => {
  const resultArr = fileArray.map((file, index) => {
    const fileObj: FileType = {
      ...file,
      file: undefined,
      dataURL: urlArr[index],
    };
    return fileObj;
  });
  return resultArr;
};

export const handleUploadMultipleFile = async (fileArray: FileType[]) => {
  if (fileArray.length === 0) return "";
  try {
    const prevFile = fileArray
      ?.filter((item) => item.file === undefined)
      ?.map((item) => item);
    const newFileArr: File[] = fileArray
      ?.filter((item) => item.file !== undefined)
      ?.map((item) => item.file!);
    const newFilePromies: IFileUpload = await uploadFile(newFileArr);
    const newFileObj: FileType[] = fileArray?.filter(
      (item) => item.file !== undefined
    );
    const newFileUrlArr = newFilePromies?.photo_url?.map(
      (item) => item.image_url
    );
    const newMappingFileArr = handleRemapingFile(newFileObj, newFileUrlArr);
    const newFileArrResult =
      prevFile && prevFile.length > 0
        ? [...prevFile, ...newMappingFileArr]
        : [...newMappingFileArr];
    const formatFilesUrl = JSON.stringify(newFileArrResult);
    return formatFilesUrl;
  } catch (e) {
    throw Error("Error upload file");
  }
};

export const handleUploadFileType = async (fileType: FileType) => {
  if (!fileType) return "";
  if (fileType.file === undefined) return JSON.stringify(fileType);
  try {
    const newImagePromies: IFileUpload = await uploadFile([fileType.file]);
    const newImageUrlArr = newImagePromies.photo_url.map(
      (item) => item.image_url
    );
    const fileTypeResult: FileType = {
      ...fileType,
      file: undefined,
      dataURL: newImageUrlArr[0],
    };
    const formatFilesUrl = JSON.stringify(fileTypeResult);
    return formatFilesUrl;
  } catch (e) {
    throw Error("Error upload file");
  }
};

export const sumArray = function (array: number[]) {
  return _.sum(array);
};

export const phoneRegex = /^0[1-9]{1}([0-9]{8})$/;
export const compareTime = function (startedTime: string, endedTime: string) {
  if (
    dayjs(startedTime).isBefore(dayjs(new Date())) &&
    dayjs(new Date()).isBefore(dayjs(endedTime))
  ) {
    return TaskStatusEnum.PROCESSING;
  } else if (dayjs(startedTime).isAfter(dayjs(new Date()))) {
    return TaskStatusEnum.NEWTASK;
  } else if (dayjs(endedTime).isBefore(dayjs(new Date()))) {
    return TaskStatusEnum.COMPLETED;
  }
};

export const convertSecondToTimeCode = (
  seconds: number,
  fps: number
): ITimeCode => {
  fps = typeof fps !== "undefined" ? fps : 24;
  return {
    hours: Math.floor(seconds / 3600),
    minutes: Math.floor((seconds % 3600) / 60),
    seconds: Math.floor(seconds % 60),
    frames: Math.floor((seconds * fps) % fps),
  };
};

export const displayTimeCode = (secondsCount: number, fps: number) => {
  const pad = (secondsCount: number) => {
    return secondsCount < 10 ? "0" + secondsCount : secondsCount;
  };
  const { hours, minutes, seconds, frames } = convertSecondToTimeCode(
    secondsCount,
    fps
  );
  fps = typeof fps !== "undefined" ? fps : 24;
  return [pad(hours), pad(minutes), pad(seconds), pad(frames)].join(":");
};

export const convertSecondToFrame = (secondsCount: number, fps: number) => {
  const { hours, minutes, seconds, frames } = convertSecondToTimeCode(
    secondsCount,
    fps
  );
  const convertHourToSecond = hours * 3600;
  const convertMinuteToSecond = minutes * 60;
  const totalSeconds = convertHourToSecond + convertMinuteToSecond + seconds;
  const totalFrames = totalSeconds * fps + frames;
  return totalFrames;
};
