import { ReactComponent as GoBackIcon } from "@/assets/img/arrow-narrow-left.svg";
import { ReactComponent as VerticalDotIcon } from "@/assets/img/dot-vertical.svg";
import { ReactComponent as DownloadIcon } from "@/assets/img/download-02.svg";
import { ReactComponent as LinkIcon } from "@/assets/img/link-01.svg";
import { ReactComponent as LinkExternalIcon } from "@/assets/img/link-external.svg";
import { ReactComponent as MessageSquareIcon } from "@/assets/img/message-square-02.svg";
import SModal from "@/app/shared/Modal/SModal";
import { FileType } from "@/app/shared/TaskUploadContainer/FormikMultiFileUpload";
import {
  MediaFileType,
  mapImageVideoMimeType,
} from "@/shared/enumeration/shared.enum";
import { ITaskResult } from "@/shared/model/taskResult.model";
import { handleJsonParseFileType } from "@/shared/utils/ultils";
import {
  CButton,
  CDropdown,
  CDropdownToggle,
  CImage,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react-pro";
import { useRouter } from "@/shared/utils/hooks/useRouter";

interface IFilePreviewModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  taskResult: ITaskResult;
  notTaskResult?: boolean;
}

const FilePreviewModal = (props: IFilePreviewModalProps) => {
  const { taskResult, setVisible, visible, notTaskResult } = props;
  const taskFile = handleJsonParseFileType(taskResult.file);
  const { navigate, location } = useRouter();
  const downloadFile = (file: FileType) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = file.dataURL;
    downloadLink.download = file.name;
    downloadLink.target = "_blank";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handlePreviewFile = (fileType?: FileType | null) => {
    if (fileType) {
      const mediaType = mapImageVideoMimeType[fileType.type]
        ? mapImageVideoMimeType[fileType.type]
        : null;
      if (mediaType === MediaFileType.IMAGE) {
        return (
          <div className="image-container">
            <CImage src={fileType.dataURL} />
          </div>
        );
      }
      if (mediaType === MediaFileType.VIDEO) {
        return (
          <div className="player-ratio-box" data-vjs-player>
            <video
              src={fileType.dataURL}
              className="video-js vjs-fill"
              id="video-container"
              controls={true}
            ></video>
          </div>
        );
      }

      return (
        <div className="file-container">
          <p className="file-text">
            Không thể preview File này vui lòng tải xuống
          </p>
          <CButton
            className="btn-custom primary-500 btn-xs"
            onClick={() => downloadFile(fileType)}
          >
            Tải xuống
          </CButton>
        </div>
      );
    }

    return <></>;
  };

  const isVideo = (taskFile: FileType) => {
    if (!taskFile?.dataURL) return false;

    const extension = taskFile.dataURL.split(".").pop()?.toLowerCase();
    const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "mkv", "webm"];

    return videoExtensions.includes(extension ?? "");
  };

  return (
    <SModal
      fullscreen
      backdrop="static"
      visible={visible}
      onClose={() => setVisible(false)}
      aria-labelledby="FullscreenExample1"
      className="file-preview-modal"
    >
      <CModalHeader closeButton={false}>
        <div className="header-container w-100">
          <div className="header-title cursor-pointer">
            <CButton
              onClick={() => setVisible(false)}
              className="btn-custom icon-btn icon-btn-sm variant-transparent-white"
            >
              <GoBackIcon />
            </CButton>{" "}
            {taskResult.name}
          </div>
          <div className="info-content ">
            <div className="task-info-wrapper">
              <div className="task-info">
                <LinkIcon /> 8
              </div>
              <div className="task-info">
                <MessageSquareIcon /> 8
              </div>
            </div>
            {taskFile && taskResult && isVideo(taskFile) && (
              <CButton className="btn-custom gray-modern-600 btn-xs rounded-24">
                <span
                  onClick={() => {
                    navigate(`/preview/${taskResult.taskId}/playlist`);
                  }}
                >
                  Chuyển sang trang review <LinkExternalIcon />
                </span>
              </CButton>
            )}
          </div>
          <div className={`${notTaskResult ? "d-none" : ""} other-function `}>
            {taskFile ? (
              <CButton
                className="btn-custom icon-btn icon-btn-sm variant-transparent-white"
                onClick={() => downloadFile(taskFile)}
              >
                <DownloadIcon />
              </CButton>
            ) : (
              ""
            )}

            <CDropdown className="dropdown-custom ">
              <CDropdownToggle
                caret={false}
                className="btn-custom icon-btn icon-btn-sm variant-transparent-white"
              >
                <VerticalDotIcon />
              </CDropdownToggle>
              {/* <CDropdownMenu className="dropdown-menu">
                <CDropdownItem>
                  <FolderIcon className="dropdown-icon" /> Lưu vào thư mục
                </CDropdownItem>
                <CDropdownItem>
                  <ClipboardIcon className="dropdown-icon" /> Lưu
                </CDropdownItem>
              </CDropdownMenu> */}
            </CDropdown>
          </div>
        </div>
      </CModalHeader>
      <CModalBody>
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          {handlePreviewFile(taskFile)}
        </div>
      </CModalBody>
      <CModalFooter></CModalFooter>
    </SModal>
  );
};
export default FilePreviewModal;
