interface IFormDividerProps {
  margin?: number;
  color?: string;
}

const FormDivider = (props: IFormDividerProps) => {
  const { margin = 24, color = '#E3E8EF' } = props;
  return <hr style={{ margin: `${margin}px 0`, borderTop: `1px solid ${color}`, opacity: 1 }} />;
};

export default FormDivider;
