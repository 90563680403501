import { ReactComponent as CheckIcon } from '@/assets/img/checkIcon.svg';
import ColorPickerAdvance from './ColorPickerAdvance';

export const defaultColor = ['#ff5c00', '#fdb022', '#17b26a', '#15b79e', '#2e90fa', '#7a5af8', '#ee46bc', '#f63d68'];

interface IColorPickerContainerProps {
  colorArray?: string[];
  colorCode?: string;
  onColorChange: (colorCode: string) => void;
}

const ColorPickerContainer = (props: IColorPickerContainerProps) => {
  const { onColorChange, colorCode, colorArray } = props;
  const colorPicker = colorArray || defaultColor;
  return (
    <>
      <div className="color-picker-container">
        <div className="color-picker-list">
          {colorPicker.map((item, index) => (
            <span
              className={`color-picker ${colorCode && colorCode === item ? 'active' : ''}`}
              key={index}
              style={{ backgroundColor: `${item}` }}
              onClick={() => onColorChange(item)}
            >
              {colorCode && colorCode === item ? <CheckIcon /> : ''}
            </span>
          ))}
          <ColorPickerAdvance color={colorCode} setColor={onColorChange} />
        </div>
      </div>
    </>
  );
};

export default ColorPickerContainer;
