export enum ResponseStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export const responseStatusArray: ResponseStatus[] = [
  ResponseStatus.ACTIVE,
  ResponseStatus.INACTIVE,
  ResponseStatus.DELETED,
];

export const mapResponseStatus: { [key in ResponseStatus]: string } = {
  [ResponseStatus.ACTIVE]: 'Hoạt động',
  [ResponseStatus.INACTIVE]: 'Không hoạt động',
  [ResponseStatus.DELETED]: 'Đã xóa',
};

export const mapResponseStatusToColor: { [key in ResponseStatus]: string } = {
  [ResponseStatus.ACTIVE]: 'success',
  [ResponseStatus.INACTIVE]: 'danger',
  [ResponseStatus.DELETED]: 'gray',
};
