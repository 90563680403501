import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimeFormat } from "../../tool/time.enum";
import { ITimeline } from "@/shared/model/playlist.model";

export interface IContainerSize {
  width: number;
  height: number;
}

interface IInitialLoginState {
  hadAnnotation: boolean;
  isDrawable: boolean;
  isClear: boolean;
  openSideBar: boolean;
  timeFormat: TimeFormat;
  chosenMarker: ITimeline | null;
}

const initialState: IInitialLoginState = {
  hadAnnotation: false,
  isDrawable: false,
  isClear: false,
  openSideBar: true,
  timeFormat: TimeFormat.STANDARD,
  chosenMarker: null,
};

const { actions, reducer } = createSlice({
  name: "videoSlice",
  initialState,
  reducers: {
    toggleSideBar(state, action: PayloadAction<boolean>) {
      state.openSideBar = action.payload;
    },
    toggleDrawable(state, action: PayloadAction<boolean>) {
      state.isDrawable = action.payload;
    },
    toggleHadAnnotation(state, action: PayloadAction<boolean>) {
      state.hadAnnotation = action.payload;
    },
    setTimeFormat(state, action: PayloadAction<TimeFormat>) {
      state.timeFormat = action.payload;
    },
    onClearAnnotation(state) {
      state.isClear = true;
    },
    resetClearState(state) {
      state.isClear = false;
    },
    setChosenMarker(state, action: PayloadAction<ITimeline | null>) {
      state.chosenMarker = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  onClearAnnotation,
  resetClearState,
  toggleSideBar,
  toggleDrawable,
  toggleHadAnnotation,
  setTimeFormat,
  setChosenMarker,
} = actions;
export default reducer;
