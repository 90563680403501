import { RootState } from "@/reducers";
import { IMessage } from "@/shared/model/message.model";
import { IInitialState, IResponse } from "@/shared/shared-interfaces";
import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { getEntities, getFindRoom, IMessageParams } from "./message.api";

interface IMessageInitialState extends IInitialState {
  filterState: IMessageParams;
  messageInfoShow: boolean;
  idFindRoom?: number | string | null;
}

export const initialMessageFilter: IMessageParams = {
  page: 1,
  limit: 50,
  sortBy: "timestamp",
  sortOrder: "DESC",
  roomId: 0,
};

const initialState: IMessageInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  totalPages: 0,
  messageInfoShow: false,
  filterState: initialMessageFilter,
  idFindRoom: null
};

export const messageAdapter = createEntityAdapter<IMessage>({
  selectId: ({ _id }) => _id,
});

const { actions, reducer } = createSlice({
  name: "messageSlice",
  initialState: messageAdapter.getInitialState({ initialState }),
  reducers: {
    toggleMessageInfo: (state, { payload }: PayloadAction<boolean>) => {
      state.initialState.messageInfoShow = payload;
    },
    setFilterState(state, { payload }: PayloadAction<IMessageParams>) {
      state.initialState.filterState = payload;
    },
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.idFindRoom = null;

    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    resetFilterState(state) {
      state.initialState.filterState = initialMessageFilter;
      state.initialState.totalPages = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<IMessage[]>>>
      ) => {
        messageAdapter.setAll(state, payload?.data?.data);
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );

    builder.addCase(
      getFindRoom.fulfilled.type,
      (
        state,
        { payload }: any
      ) => {
        state.initialState.idFindRoom = payload.data.id
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getFindRoom.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
  },
});

export const {
  fetching,
  resetAll,
  resetEntity,
  setFilterState,
  toggleMessageInfo,
  resetFilterState,
} = actions;
export default reducer;

export const messageSelectors = messageAdapter.getSelectors<RootState>(
  (state) => state.messageReducer
);

const { selectById } = messageAdapter.getSelectors();
const getMessageState = (rootState: RootState) => rootState.messageReducer;

export const selectEntityById = (id: string) => {
  return createSelector(getMessageState, (state) => selectById(state, id));
};
