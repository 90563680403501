import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

import { RootState } from "@/reducers";
import { ITask } from "@/shared/model/task.model";
import { IStaff } from "@/shared/model/user.model";
import { IInitialState, IResponse } from "@/shared/shared-interfaces";
import {
  createCalender,
  deleteCalender,
  getEntities,
  getEntityCalender,
  updateCalender,
  IMyScheduleParams,
} from "./calender.api";
import { ICalender } from "@/shared/model/calender";

interface ITaskInitialState extends IInitialState {
  projectStaff: IStaff[];
  fetchProjectStaffSuccess: boolean;
  errorProjectStaffMessage: string | null;
  updateEntityStatusSuccess: boolean;
  filterState: IMyScheduleParams;
  updateDocumentSuccess: boolean;
  errorDocumentMessage: string | null;
  taskDetails: ITask[];
  totalTask: any;
}

export const initialProjectFilter: IMyScheduleParams = {
  start: "",
  end: "",
  page: 1,
  limit: 1000,
};

const initialState: ITaskInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  createEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  totalPages: 0,
  updateEntityStatusSuccess: false,
  filterState: initialProjectFilter,
  projectStaff: [],
  taskDetails: [],
  fetchProjectStaffSuccess: false,
  errorProjectStaffMessage: null,
  updateDocumentSuccess: false,
  errorDocumentMessage: null,
  totalTask: null,
};

export const myCalenderAdapter = createEntityAdapter<ICalender>({
  selectId: ({ scheduleId }) => scheduleId,
});

const { actions, reducer } = createSlice({
  name: "calenderSlice",
  initialState: myCalenderAdapter.getInitialState({ initialState }),
  reducers: {
    setFilterState(state, { payload }: PayloadAction<IMyScheduleParams>) {
      state.initialState.filterState = payload;
    },
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.createEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.createEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    resetProjectStaff(state) {
      state.initialState.fetchProjectStaffSuccess = false;
      state.initialState.projectStaff = [];
      state.initialState.errorProjectStaffMessage = null;
    },
    resetUploadDocument(state) {
      state.initialState.updateDocumentSuccess = false;
      state.initialState.errorDocumentMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<[]>>>) => {
        myCalenderAdapter.setAll(state, payload.data.data);
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
    builder.addCase(
      getEntityCalender.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<[]>>>) => {
        state.initialState.taskDetails = payload.data.data;
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntityCalender.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
    builder.addCase(
      createCalender.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<[]>>>) => {
        // myCalenderAdapter.upsertOne(payload.data.data);
        state.initialState.createEntitySuccess = true;
        state.initialState.updateEntitySuccess = false;
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      createCalender.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.createEntitySuccess = false;
        state.initialState.updateEntitySuccess = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
    builder.addCase(
      updateCalender.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<[]>>>) => {
        // myCalenderAdapter.upsertOne(payload.data.data);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.updateEntitySuccess = true;
        state.initialState.createEntitySuccess = false;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      updateCalender.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
        state.initialState.createEntitySuccess = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );

    builder.addCase(
      deleteCalender.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<[]>>>) => {
        // myCalenderAdapter.upsertOne(payload.data.data);
        state.initialState.deleteEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      deleteCalender.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
        state.initialState.deleteEntitySuccess = false;
      }
    );
  },
});

export const {
  fetching,
  resetAll,
  resetEntity,
  setFilterState,
  resetUploadDocument,
} = actions;
export default reducer;

export const calenderSelectors = myCalenderAdapter.getSelectors<RootState>(
  (state) => state.calenderReducer
);

const { selectById } = myCalenderAdapter.getSelectors();
const getCalendertate = (rootState: RootState) => rootState.calenderReducer;

export const selectEntityById = (id: string) => {
  return createSelector(getCalendertate, (state) => selectById(state, id));
};
