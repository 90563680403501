import {
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState } from "@/reducers";
import { IComment } from "@/shared/model/comment.model";
import {
  createEntity,
  getEntities,
  getEntity,
  removeEntity,
  updateEntity,
} from "./comment.api";
import { IInitialState, IResponse } from "@/shared/shared-interfaces";

export const commentAdapter = createEntityAdapter<IComment>({
  selectId: ({ commentId }) => commentId,
});

export interface IInitialStateCommentReducer extends IInitialState {
  updateComment: IComment | null;
}

const initialState: IInitialStateCommentReducer = {
  totalPages: 1,
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  updateComment: null,
};

const { actions, reducer } = createSlice({
  name: "commentSlice",
  initialState: commentAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.updateComment = null;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateComment = null;
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<IComment[]>>>
      ) => {
        commentAdapter.setAll(state, payload.data.data);
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );

    builder.addCase(
      getEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IComment>) => {
        commentAdapter.upsertOne(state, payload);
        state.initialState.fetchEntitySuccess = true;
        state.initialState.loading = false;
      }
    );

    builder.addCase(
      getEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitySuccess = false;
      }
    );

    builder.addCase(
      updateEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IComment>) => {
        commentAdapter.updateOne(state, {
          id: payload.commentId,
          changes: payload,
        });
        state.initialState.updateEntitySuccess = true;
        state.initialState.loading = false;
      }
    );

    builder.addCase(
      updateEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
    builder.addCase(
      createEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IComment>) => {
        state.initialState.updateComment = payload;
        commentAdapter.addOne(state, payload);
        state.initialState.updateEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      createEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
    builder.addCase(
      removeEntity.fulfilled.type,
      (state, { payload }: PayloadAction<string>) => {
        commentAdapter.removeOne(state, payload);
        state.initialState.totalItems -= 1;
        state.initialState.deleteEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      removeEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.deleteEntitySuccess = false;
      }
    );
  },
});

export default reducer;

export const { fetching, resetAll, resetEntity } = actions;
export const commentSelectors = commentAdapter.getSelectors(
  (state: RootState) => state.commentReducer
);

const getCommentState = (state: RootState) => state.commentReducer;
const { selectById } = commentAdapter.getSelectors();

export const selectEntityById = (id: string) => {
  return createSelector(getCommentState, (state) => selectById(state, id));
};
