import { SVGProps } from 'react';
const FaceSmileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width || 16}
    height={props?.height || 16}
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1580_20986)">
      <path
        d="M6.66699 11.6665C6.66699 11.6665 7.91699 13.3332 10.0003 13.3332C12.0837 13.3332 13.3337 11.6665 13.3337 11.6665M12.5003 7.49984H12.5087M7.50033 7.49984H7.50866M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984ZM12.917 7.49984C12.917 7.72996 12.7304 7.9165 12.5003 7.9165C12.2702 7.9165 12.0837 7.72996 12.0837 7.49984C12.0837 7.26972 12.2702 7.08317 12.5003 7.08317C12.7304 7.08317 12.917 7.26972 12.917 7.49984ZM7.91699 7.49984C7.91699 7.72996 7.73044 7.9165 7.50033 7.9165C7.27021 7.9165 7.08366 7.72996 7.08366 7.49984C7.08366 7.26972 7.27021 7.08317 7.50033 7.08317C7.73044 7.08317 7.91699 7.26972 7.91699 7.49984Z"
        stroke={props?.stroke}
        strokeWidth={1.67}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1580_20986">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default FaceSmileIcon;
