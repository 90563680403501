export const fontSize = {
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: "400",
};

export const groupDropDown = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#000",
  background: "#fff",
  boxShadow: "none",
  gap: "100px",
};
