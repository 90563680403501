import { RootState } from "@/reducers";
import { IPost } from "@/shared/model/post";
import { IInitialState, IResponse } from "@/shared/shared-interfaces";
import {
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import {
  IPostParams,
  createEntity,
  getEntities,
  getEntity,
  updateEntity,
  deleteEntity,
} from "./posts.api";

interface IPostInitialState extends IInitialState {
  filterState: IPostParams;
}

export const initialPostFilter: IPostParams = {
  page: 1,
  limit: 10,
  sortBy: "createdDate",
  sortOrder: "DESC",
};

const initialState: IPostInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  totalPages: 0,
  filterState: initialPostFilter,
};

export const postsAdapter = createEntityAdapter<IPost>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: "postsSlice",
  initialState: postsAdapter.getInitialState({ initialState }),
  reducers: {
    setFilterState(state, { payload }: PayloadAction<IPostParams>) {
      state.initialState.filterState = payload;
    },
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<IPost[]>>>
      ) => {
        postsAdapter.setAll(state, payload.data.data);
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
    builder.addCase(
      getEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IPost>) => {
        postsAdapter.upsertOne(state, payload);
        state.initialState.fetchEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitySuccess = false;
      }
    );
    builder.addCase(
      updateEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IPost>) => {
        if (payload.level === 1) {
          postsAdapter.updateOne(state, { id: payload.id, changes: payload });
        }

        if (payload?.level === 2 && payload.parentId) {
          const postUpdate = state.entities[payload.parentId];
          if (!postUpdate) return;
          const childrenUpdate = postUpdate.children?.map((item) =>
            item.id === payload.id ? { ...item, ...payload } : item
          );

          postsAdapter.updateOne(state, {
            id: payload.parentId,
            changes: { children: childrenUpdate },
          });
        }

        if (payload?.level === 3 && payload.parent?.parentId) {
          const postUpdate = state.entities[payload.parent?.parentId];
          if (!postUpdate) return;
          const parentUpdate = postUpdate?.children?.map((item) =>
            item.id === payload.parentId
              ? {
                  ...item,
                  children: item.children?.map((i) =>
                    i.id === payload.id ? { ...i, ...payload } : i
                  ),
                }
              : item
          );
          postsAdapter.updateOne(state, {
            id: payload.parent?.parentId,
            changes: { children: parentUpdate },
          });
        }

        state.initialState.updateEntitySuccess = true;
        state.initialState.loading = false;
        state.initialState.deleteEntitySuccess = false;
      }
    );
    builder.addCase(
      updateEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
        state.initialState.deleteEntitySuccess = false;
      }
    );
    builder.addCase(
      createEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IPost>) => {
        if (payload.level === 1) {
          postsAdapter.addOne(state, payload);
          state.initialState.totalItems += 1;
        }

        if (payload?.level === 2 && payload.parentId) {
          const postUpdate = state.entities[payload.parentId];
          if (!postUpdate) return;

          postsAdapter.updateOne(state, {
            id: payload.parentId,
            changes: {
              children: postUpdate?.children
                ? [...postUpdate?.children, payload]
                : [payload],
            },
          });
        }

        if (payload?.level === 3 && payload.parent?.parentId) {
          const postUpdate = state.entities[payload.parent?.parentId];
          if (!postUpdate) return;
          const parentUpdate = postUpdate?.children?.map((item) =>
            item.id === payload.parentId
              ? {
                  ...item,
                  children: item.children
                    ? [...item.children, payload]
                    : [payload],
                }
              : item
          );
          postsAdapter.updateOne(state, {
            id: payload.parent?.parentId,
            changes: { children: parentUpdate },
          });
        }

        state.initialState.updateEntitySuccess = true;
        state.initialState.deleteEntitySuccess = false;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      createEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
      }
    );

    builder.addCase(
      deleteEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IPost>) => {
        state.initialState.deleteEntitySuccess = true;
        state.initialState.updateEntitySuccess = false;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      deleteEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.deleteEntitySuccess = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
  },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const postsSelectors = postsAdapter.getSelectors<RootState>(
  (state) => state.postsReducer
);

const { selectById } = postsAdapter.getSelectors();
const getPostsState = (rootState: RootState) => rootState.postsReducer;

export const selectEntityById = (id: string) => {
  return createSelector(getPostsState, (state) => selectById(state, id));
};
