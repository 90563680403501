import { RootState } from "@/reducers";
import { Permission } from "@/shared/enumeration/permission";
import { SystemRole } from "@/shared/enumeration/role";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { handleJsonParseArr } from "@/shared/utils/ultils";
import { intersection } from "lodash";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export interface IProtectedPermissionProps {
    children: ReactNode;
    requiredPerms: Permission[];
}
export const hasPermission = (permissionUser: any, permission: Permission) => {
    const permissionArray = handleJsonParseArr(
        permissionUser?.permissions || ""
    ) as Permission[];
    return permissionArray.includes(permission);
};

export const checkUserAdmin = (role: SystemRole) => {
    return role === SystemRole.ADMIN;
};

export const checkOverlapBetweenPerms = (
    userAuthorities: Permission[],
    requiredPerms: Permission[]
): boolean => {
    const overlappedPerms = intersection(requiredPerms, userAuthorities);
    return Boolean(overlappedPerms.length);
};

function ProtectedPermission({
    children,
    requiredPerms = [],
}: IProtectedPermissionProps) {
    const { user } = useSelector((state: RootState) => state.authentication);
    const { location } = useRouter();

    if (!user) {
        return <Navigate to="/login" state={{ path: location.pathname }} />;
    }

    const { permissionGroup, role } = user;
    const permissionArray = handleJsonParseArr(
        permissionGroup?.permissions || ""
    ) as Permission[];

    if (checkUserAdmin(role)) return <>{children}</>;
    return checkOverlapBetweenPerms(permissionArray, requiredPerms) ? (
        <>{children}</>
    ) : (
        <Navigate to="/403" replace />
    );
}

export default ProtectedPermission;
