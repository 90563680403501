import { ReactComponent as PlusIcon } from '@/assets/img/plusIcon.svg';
import { CDropdown, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro';
import { useState } from 'react';
import { HexAlphaColorPicker } from 'react-colorful';

interface IColorPickerAdvanceProps {
  color?: string;
  setColor: (colorCode: string) => void;
}

const ColorPickerAdvance = (props: IColorPickerAdvanceProps) => {
  const { color, setColor } = props;
  const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);
  return (
    <>
      {/* <span className="color-picker create-color" style={{ backgroundColor: `var(--gray-modern-200)` }}>
            <PlusIcon />
          </span> */}
      <CDropdown
        className="color-popover-container"
        autoClose="outside"
        placement="bottom"
        visible={colorPickerOpen}
        onHide={() => setColorPickerOpen(false)}
        onShow={() => setColorPickerOpen(true)}
      >
        <CDropdownToggle
          className="color-picker-toggle cursor-pointer"
          onClick={() => setColorPickerOpen(!colorPickerOpen)}
          caret={false}
        >
          <PlusIcon />
        </CDropdownToggle>
        <CDropdownMenu className="dropdown-menu p-0">
          <div className="color-popover-wrapper">
            <section className="">
              <HexAlphaColorPicker color={color || undefined} onChange={(newColor: string) => setColor(newColor)} />
            </section>
          </div>
        </CDropdownMenu>
      </CDropdown>
    </>
  );
};

export default ColorPickerAdvance;
