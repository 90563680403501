import { ReactComponent as FileUploadIcon } from "@/assets/img/file-upload-icon.svg";
import { ReactComponent as LinkIcon } from "@/assets/img/link-01.svg";
import { ReactComponent as MessageSquareIcon } from "@/assets/img/message-square-02.svg";
import FormDivider from "@/app/shared/FormDivider/FormDivider";
import ButtonUpload from "@/app/shared/UploadComponent/ButtonUpload";
import { CAvatar, CButton } from "@coreui/react-pro";
import React, { useEffect, useState } from "react";

import StatusSelect from "@/app/shared/StatusSelect/StatusSelect";
import { FileType } from "@/app/shared/TaskUploadContainer/FormikMultiFileUpload";
import { ToastSuccess } from "@/app/shared/toast/Toast";
import { RootState } from "@/reducers";
import { mapMediaMimeType } from "@/shared/enumeration/shared.enum";
import {
  TaskStatusEnum,
  mapTaskStatusToColor,
  mapTaskStatusToString,
  taskStatusArray,
} from "@/shared/enumeration/task.enum";
import { ITask } from "@/shared/model/task.model";
import { INewTaskResult, ITaskResult } from "@/shared/model/taskResult.model";
import {
  handleJsonParseFileType,
  handleUploadFileType,
} from "@/shared/utils/ultils";
import { AppDispatch } from "@/store";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import FilePreviewModal from "./ResultPreviewModal";
import { createEntity, getEntities, updateEntity } from "./tasksResult.api";
import { fetching, resetEntity, setFilterState } from "./tasksResult.reducer";

// const user = {
//   avatar: '',
//   avatarColor: '',
//   id: '',
//   fullName: 'Quang Đỗ',
//   username: 'quangdo123',
//   email: 'quangdo@gmail.com',
//   createdDate: '2023-11-02T09:18:30.044Z',
//   description:
//     '<ul><li>Hoàn thành thiết kế bối cảnh cho TVC quảng cáo sprint 2</li><li>Hoàn thành xây dựng hồ sơ nhân vật chính</li></ul>',
// };

// const imgSrc =
//   'https://s3-alpha-sig.figma.com/img/2675/9b4f/24c13fad09c440e11c5fa7c27bf87204?Expires=1699833600&Signature=nPXixoFQ0e2xOaf6HYdzcUdQhyj1Rxk5I1EiVK1xT7RKHp3xfAY6qBlaUG~nEr9cJdccCWLCnr6s3fWJ3o803qpzNpgmE3LTnYS4mvjow60Q7SdDxUVreU49Fl7edrB32qjv62kLgqV1ISKLdzfqNpqHG7QnEpLBC1gku0fdijdWmqPcHRKRSWD5JsClpjx3pMLzZDdHDRRbCkb1okzo3j-2TTyYOIG5QBgbvx5AAOdpbAm8Uz9-lwO517OZy5CDCaHi3c3JX~Uvjqbe8-dSwLWCR6qXUbmqCEjT0i55GOjo-FwknJ3O64s0u5rNSKUUt53DPMcWAlm50ZRNPrCVtw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4';

// interface ITaskResultContent {}

// const TaskResultContent = (props: ITaskResultContent) => {
//   const label = user.fullName || user.username || user.email || '';

//   return (
//     <div className="result-content-container">
//       <CAvatar
//         src={user.avatar || ''}
//         style={{
//           backgroundColor: `${user.avatarColor || '#1570ef'}`,
//         }}
//         key={user.id}
//         textColor="white"
//         className="avatar-24"
//       >
//         {label ? label.charAt(0)?.toUpperCase() : ''}
//       </CAvatar>
//       <div className="result-content">
//         <div className="content-header">
//           <span className="user-name">{user.fullName}</span>
//           <span className="created-date">{dayjs(user.createdDate).format('HH:mm DD/MM/YYYY')}</span>
//         </div>
//         <div className="content-body" dangerouslySetInnerHTML={{ __html: user.description }}></div>
//       </div>
//     </div>
//   );
// };

// interface ITaskResultCommentEdit {
//   onClose: () => void;
// }

// const TaskResultCommentEdit = (props: ITaskResultCommentEdit) => {
//   const { onClose } = props;
//   const label = user.fullName || user.username || user.email || '';

//   return (
//     <div className="result-comment-container">
//       <CAvatar
//         src={user.avatar || ''}
//         style={{
//           backgroundColor: `${user.avatarColor || '#1570ef'}`,
//         }}
//         key={user.id}
//         textColor="white"
//         className="avatar-32"
//       >
//         {label ? label.charAt(0)?.toUpperCase() : ''}
//       </CAvatar>
//       <div className="result-comment-wrapper ">
//         <CustomFormikEditor
//           simpleMode
//           size="sm"
//           name="description"
//           value={''}
//           setFieldValue={(_, value) => console.log(value)}
//         />
//         <div className="d-flex justify-content-end mt-3" style={{ gap: '12px' }}>
//           <CButton className="btn-custom variant-gray-300 btn-xs" onClick={() => onClose()}>
//             Huỷ
//           </CButton>
//           <CButton className="btn-custom primary-500 btn-xs">Cập nhật</CButton>
//         </div>
//       </div>
//     </div>
//   );
// };

interface ITaskResultFileContainer {
  isImgType?: boolean;
  taskResult: ITaskResult;
  taskFile: FileType;
  onStatusChange: (status: TaskStatusEnum, taskResult: ITaskResult) => void;
  onSelectCard: (taskResult: ITaskResult) => void;
}

const TaskResultFileContainer = (props: ITaskResultFileContainer) => {
  const { onSelectCard, taskResult, taskFile, onStatusChange } = props;
  const { createdDate, owner, status: newStatus } = taskResult;
  let status = newStatus
  if (status === "NEW" as TaskStatusEnum) {
    status = TaskStatusEnum.NEWTASK;
  }
  const label = owner?.fullName || owner?.username || owner?.email || "";

  // const taskFile = handleJsonParseFileType(taskResult.file);

  const isImgType = mapMediaMimeType[taskFile?.type] ? true : false;

  const imgStyle: any = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    objectFit: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${taskFile.dataURL})`,
  };

  return (
    <div
      className="result-file-card cursor-pointer"
      onClick={() => onSelectCard(taskResult)}
    >
      <div className={`file-cover`} style={isImgType ? imgStyle : {}}>
        {!isImgType ? <FileUploadIcon className={"upload-icon"} /> : <></>}
        <div className="file-status" onClick={(e) => e.stopPropagation()}>
          <StatusSelect
            color={mapTaskStatusToColor[status]}
            options={taskStatusArray.map((item) => ({
              value: item,
              label: mapTaskStatusToString[item],
            }))}
            value={{
              value: status,
              label: mapTaskStatusToString[status],
            }}
            minWidth="158px"
            inModal
            onChange={(value) => {
              onStatusChange(value?.value as TaskStatusEnum, taskResult);
            }}
          />
        </div>
      </div>
      <div className="file-information" title={taskFile.name}>
        <div className="file-name">{taskFile.name}</div>

        <div className="file-detail-info">
          {dayjs(createdDate).format("HH:mm DD/MM/YYYY")}
          <CAvatar
            src={owner?.avatar || ""}
            style={{
              backgroundColor: `${owner?.avatarColor || "#1570ef"}`,
              fontSize: "10px",
            }}
            key={owner?.id}
            textColor="white"
            className="avatar-20"
          >
            {label ? label.charAt(0)?.toUpperCase() : ""}
          </CAvatar>
        </div>

        <div className="file-other-info">
          <span className="task-info">
            <LinkIcon /> 8
          </span>
          <span className="task-info">
            <MessageSquareIcon /> 8
          </span>
        </div>
      </div>
    </div>
  );
};

interface ITaskResultTabProps {
  taskObj: ITask;
}

const TaskResultTab = (props: ITaskResultTabProps) => {
  const { taskObj } = props;
  // const bottomRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector(
    (state: RootState) => state.taskResultReducer
  );
  const { filterState, totalItems, updateEntitySuccess } = initialState;
  // const taskResults = useSelector(tasksResultSelectors.selectAll);
  const [taskResults, setTaskResults] = useState<ITaskResult[]>([]);

  // const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [filePreview, setFilePreview] = useState<ITaskResult | null>(null);

  const handleClosePreview = (value: boolean) => {
    setFilePreview(null);
  };

  // useEffect(() => {
  //   if (enableEdit) {
  //     bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [enableEdit]);

  const handdleFileResultClick = (taskResult: ITaskResult) => {
    setFilePreview(taskResult);
  };

  useEffect(() => {
    dispatch(fetching());
    dispatch(
      getEntities({ ...filterState, taskId: Number(taskObj?.taskId) })
    ).then((data: any) => {
      setTaskResults(data.payload?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), updateEntitySuccess]);

  useEffect(() => {
    if (updateEntitySuccess) {
      ToastSuccess("Cập nhập thành công");
      dispatch(resetEntity());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  const handleUploadFile = async (fileArr: FileType[]) => {
    if (fileArr?.[0]) {
      const finalFile = fileArr[0];
      const filesPromies = await handleUploadFileType(finalFile);
      const taskResult: INewTaskResult = {
        taskId: taskObj?.taskId,
        file: filesPromies,
        name: finalFile?.name,
        url: "",
      };
      dispatch(createEntity(taskResult));
    }
  };

  const handleUpdateStatus = (
    status: TaskStatusEnum,
    taskResult: ITaskResult
  ) => {
    dispatch(updateEntity({ ...taskResult, status }));
  };

  return (
    <>
      <div
        className="result-tab tab-content"
        onScroll={(e) => {
          const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
          const bottom = scrollHeight - scrollTop === clientHeight;
          if (bottom) {
            if (filterState?.limit <= totalItems) {
              dispatch(
                setFilterState({
                  ...filterState,
                  limit: filterState.limit + 10,
                })
              );
            }
          }
        }}
      >
        <div className="function-container">
          <ButtonUpload
            onFileChange={handleUploadFile}
            width={125}
            height={38}
            name="upload-button"
            single
          >
            <CButton
              className="btn-custom variant-gray-neutral-500 btn-xs"
              style={{ width: "125px", height: "38px" }}
            >
              Tải lên tài liệu
            </CButton>
          </ButtonUpload>
        </div>
        <FormDivider />

        <div className="result-file-container ">
          {taskResults?.map((taskResult) => {
            const taskFile = handleJsonParseFileType(taskResult?.file);
            if (taskFile) {
              return (
                <TaskResultFileContainer
                  key={taskResult?.id}
                  taskResult={taskResult}
                  taskFile={taskFile}
                  onSelectCard={handdleFileResultClick}
                  onStatusChange={handleUpdateStatus}
                />
              );
            } else {
              return <React.Fragment key={taskResult?.id}></React.Fragment>;
            }
          })}
        </div>
        {/* <FormDivider />

        <TaskResultContent />
        <FormDivider />
        <div ref={bottomRef} className={`${enableEdit ? '' : 'd-none'}`}>
          <TaskResultCommentEdit onClose={disableEdit} />
        </div> */}

        {filePreview ? (
          <FilePreviewModal
            visible={Boolean(filePreview)}
            setVisible={handleClosePreview}
            taskResult={filePreview}
          />
        ) : (
          ""
        )}
        {/* <FormDivider /> */}
      </div>
    </>
  );
};

export default TaskResultTab;
