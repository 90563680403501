import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import ProtectedPermission from '../auth/ProtectedPermission';
import { Permission } from '@/shared/enumeration/permission';

const Chat = React.lazy(() => import('./Chat'));

const ChatLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedPermission requiredPerms={[Permission.MESSAGE_VIEW]}>
      <Chat />
    </ProtectedPermission>
    ),
  },
  {
    path: ':roomId',
    element: (
      // <ProtectedComponent requiredPerms={[]}>
      <Chat />
      // </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const ChatRoutes = () => useRoutes(ChatLayout);
export default ChatRoutes;
