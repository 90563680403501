import { CModal } from "@coreui/react-pro";
import { CModalProps } from "@coreui/react-pro/dist/components/modal/CModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";

const SModal = (props: CModalProps & React.RefAttributes<HTMLDivElement>) => {
  const { darkMode } = useSelector((state: RootState) => state.container);

  return (
    <CModal
      {...props}
      className={`${darkMode ? "dark-theme" : "light-theme"} ${
        props.className
      }`}
    >
      {props.children}
    </CModal>
  );
};

export default SModal;
