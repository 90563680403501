import axios from "@/shared/config/axios-interceptor";
import { INewPost, IPost } from "@/shared/model/post";
import { IParams } from "@/shared/shared-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";

const prefix = "discussion";

export interface IPostParams extends IParams {
  type?: "project" | "task";
  level?: number;
  projectId?: number;
  taskId?: number;
  parentId?: number;
  getChild?: boolean;
}

export const getEntities = createAsyncThunk(
  `get-all-${prefix}-posts`,
  async (fields: IPostParams, thunkAPI) => {
    try {
      const { level, projectId, taskId } = fields;
      const id = projectId || taskId;
      const params = pickBy(fields);
      return await axios.get<IPost[]>(`${prefix}/level/${level}/${id}`, {
        params,
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(
  `get-single-${prefix}-post`,
  async (id: string, thunkAPI) => {
    try {
      const { data } = await axios.get<IPost>(`${prefix}/${id}`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(
  `create-one-${prefix}-post`,
  async (body: INewPost, thunkAPI) => {
    try {
      const { data } = await axios.post(`${prefix}`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateEntity = createAsyncThunk(
  `update-one-${prefix}-post`,
  async (
    body: { content: string; imageUrls?: string[]; id: number },
    thunkAPI
  ) => {
    try {
      const { id, ...update } = body;
      const { data } = await axios.put<IPost>(
        `${prefix}/${id}`,
        pickBy(update)
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteEntity = createAsyncThunk(
  `delete-${prefix}-post`,
  async (id: string, thunkAPI) => {
    try {
      const { data } = await axios.delete<IPost>(`${prefix}/${id}`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
