import avatar from "@/assets/img/avatar.jpg";
import FaceSmileIcon from "@/app/shared/icons/FaceSmileIcon";
import SendIcon from "@/app/shared/icons/SendIcon";
import { RootState } from "@/reducers";
import { INewPost, IPost } from "@/shared/model/post";
import { IUser } from "@/shared/model/user.model";
import { AppDispatch } from "@/store";
import {
  CAvatar,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CTooltip,
} from "@coreui/react-pro";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createEntity, updateEntity } from "./posts.api";
import { fetching, resetEntity } from "./posts.reducer";

interface ICommentProps {
  data: IPost;
}

export const TooltipUserInfo = ({ user }: { user: IUser }) => (
  <div className="d-flex align-items-center">
    <div className="avatar">
      <CAvatar src={user?.avatar || avatar} className="avatar-32 me-1" />
    </div>
    <div className="text-start">
      <p className="text-medium-xs m-0">{user?.fullName || user?.username}</p>
      <p className="text-xs m-0">{user?.email}</p>
    </div>
  </div>
);

const Comment = ({ data }: ICommentProps) => {
  const { user } = useSelector((state: RootState) => state.authentication);
  const [reply, setReply] = useState(false);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector(
    (state: RootState) => state.postsReducer
  );
  const { updateEntitySuccess } = initialState;
  const [edit, setEdit] = useState(false);
  const [editComment, setEditComment] = useState("");

  //   const [showEmoji, setShowEmoji] = useState(false);

  //   const addEmoji = (e: IEmoji) => {
  //     setComment(comment + e.native);
  //   };

  const sendComment = () => {
    if (!comment.trim()) return;
    const newComment: INewPost = {
      projectId: data.projectId,
      level: data.level === 1 ? 2 : 3,
      content: comment.trim(),
      parentId: data.id,
      notificationDto: {
        userId: [user?.id ?? ""],
        typeNotification: "discussion",
        notificationDiscussion: {},
      },
    };
    dispatch(fetching());
    dispatch(createEntity(newComment));
  };

  const updateComment = () => {
    if (!editComment.trim()) return;
    if (editComment.trim() === data.content) {
      setEdit(false);
    }
    dispatch(fetching());
    dispatch(updateEntity({ id: data.id, content: editComment.trim() }));
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    edit = false
  ) => {
    if (e.key === "Enter") {
      if (comment.trim() && !edit) sendComment();
      if (editComment.trim() && edit) updateComment();
    }
  };

  useEffect(() => {
    if (updateEntitySuccess) {
      dispatch(resetEntity());
      setComment("");
      setEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  return (
    <div className="d-flex">
      <CTooltip content={<TooltipUserInfo user={data.user} />}>
        <div className="avatar">
          <CAvatar src={data.user?.avatar || avatar} className="avatar-32" />
        </div>
      </CTooltip>

      <div className="ms-2 w-100">
        {edit ? (
          <>
            <div className="comment-input">
              <CInputGroup className="input-start-group">
                <CFormInput
                  placeholder="Viết bình luận..."
                  name="content"
                  autoComplete="off"
                  value={editComment}
                  onChange={(e) => setEditComment(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, true)}
                />
                <CInputGroupText
                  onClick={updateComment}
                  className="cursor-pointer"
                >
                  <SendIcon height={16} width={16} />
                </CInputGroupText>
              </CInputGroup>
            </div>
            <p
              className="text-decoration-underline text-primary text-xs cursor-pointer mb-0 mt-2"
              onClick={() => setEdit(false)}
            >
              Huỷ
            </p>
          </>
        ) : (
          <>
            <div className={`comment-content ${data.taskId ? "d-block" : ""}`}>
              <CTooltip content={<TooltipUserInfo user={data.user} />}>
                <p className="text-medium-xs text-gray-modern-700 mb-1">
                  {data.user?.fullName || data.user?.username}
                </p>
              </CTooltip>
              <p className="text-xs text-gray-modern-700 mb-0">
                {data.content}
              </p>
            </div>
            <div className="d-flex mt-2">
              <FaceSmileIcon
                width={16}
                height={16}
                className="text-gray-modern-500 cursor-pointer me-12"
                //   onClick={(e) => {
                //     e.stopPropagation();
                //     setShowEmoji(!showEmoji);
                //   }}
              />
              {/* {showEmoji ? (
              <div className="position-absolute mt-4 emoji-container">
                <Picker
                  data={emojiData}
                  onEmojiSelect={addEmoji}
                  onClickOutside={() => setShowEmoji(false)}
                  theme="light"
                  locale="vi"
                  previewPosition="none"
                  skinTonePosition="none"
                />
              </div>
            ) : null} */}

              {data.level !== 3 ? (
                <p
                  className="text-decoration-underline text-gray-modern-500 text-xs cursor-pointer me-12 mb-0"
                  onClick={() => setReply(true)}
                >
                  Trả lời
                </p>
              ) : null}

              {Number(user?.id) === data.userId ? (
                <p
                  className="text-decoration-underline text-gray-modern-500 text-xs cursor-pointer me-12 mb-0"
                  onClick={() => {
                    setEdit(true);
                    setEditComment(data.content);
                  }}
                >
                  Chỉnh sửa
                </p>
              ) : null}

              <p className="m-0 text-gray-modern-400 text-xs">
                {dayjs(data.createdDate).fromNow()}
              </p>
            </div>
          </>
        )}

        {data.children?.length
          ? data.children?.map((rep) => (
              <div className="mt-3" key={`comment-${rep.id}`}>
                <Comment data={rep} />
              </div>
            ))
          : null}

        {reply ? (
          <div className="comment-input d-flex mt-3">
            <div>
              <CAvatar src={user?.avatar || avatar} className="avatar-32" />
            </div>
            <CInputGroup className="input-start-group ms-2">
              <CFormInput
                placeholder="Viết bình luận..."
                name="content"
                autoComplete="off"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <CInputGroupText onClick={sendComment} className="cursor-pointer">
                <SendIcon height={16} width={16} />
              </CInputGroupText>
            </CInputGroup>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Comment;
