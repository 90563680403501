import React from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

const ReportOverView = React.lazy(() => import("./components/Report"));

const MyReportLayout: RouteObject[] = [
  {
    path: "/",
    element: <ReportOverView />,
  },

  { path: "*", element: <Navigate to="/404" /> },
];

const MyReportRoutes = () => useRoutes(MyReportLayout);
export default MyReportRoutes;
