import { SVGProps } from "react"
const SettingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    stroke="currentColor"
    {...props}
  >
    <path
      stroke="#364152"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m6.263 12.914.39.876a1.475 1.475 0 0 0 2.696 0l.39-.876a1.617 1.617 0 0 1 1.647-.95l.953.102a1.474 1.474 0 0 0 1.348-2.334l-.564-.776A1.62 1.62 0 0 1 12.816 8c0-.343.108-.676.31-.953l.564-.775a1.474 1.474 0 0 0-1.348-2.335l-.953.101a1.617 1.617 0 0 1-1.647-.953l-.393-.876a1.475 1.475 0 0 0-2.696 0l-.39.877a1.617 1.617 0 0 1-1.646.952l-.957-.101a1.475 1.475 0 0 0-1.348 2.335l.565.775a1.62 1.62 0 0 1 0 1.905l-.565.776a1.474 1.474 0 0 0 1.348 2.335l.954-.102a1.622 1.622 0 0 1 1.65.953Z"
    />
    <path
      stroke="#364152"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
    />
  </svg>
)
export default SettingIcon
