import { AppDispatch } from "@/store";
import { CFormInput } from "@coreui/react-pro";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
interface CustomFormSelect {
  keyId?: string;
  keyNameSearch: string;
  getEntities: Function;
  placeholder: string;
  filterState: any;
  setFilterState: Function;
  resetTextSearch?: boolean;
  disabled?: boolean;
}
function FormSelect(props: CustomFormSelect) {
  const dispatch = useDispatch<AppDispatch>();
  const {
    keyNameSearch,
    filterState,
    setFilterState,
    resetTextSearch,
    disabled,
  } = props;
  const [textSearch, setTextSearch] = useState<string>("");
  const handleSearch = (e: any) => {
    setTextSearch(e.target.value);
  };
  // useEffect(() => {
  //     setFilteredOptions(
  //         options?.filter((option: any) => {
  //             return option?.[keyNameSearch]
  //                 .toLowerCase()
  //                 .includes(textSearch.toLowerCase());
  //         })
  //     );
  // }, [textSearch, options]);
  useEffect(() => {
    if (textSearch) {
      setTextSearch("");
    }
  }, [resetTextSearch]);

  useEffect(() => {
    dispatch(
      setFilterState({
        ...filterState,
        [keyNameSearch]: textSearch,
        page: 1,
      })
    );
  }, [textSearch]);

  // useEffect(() => {
  //   document.addEventListener("click", (e) => {
  //     if (e.target !== refDiv.current) {
  //       setOpen(false);
  //     }
  //   });
  // }, []);
  return (
    <>
      <div
        style={{
          // position: "relative",
          // top: "0",
          // left: 0,
          minWidth: "150px",
          width: "100%",
          backgroundColor: "white",
          // border: "1px solid #ccc",
          borderRadius: "10px",
        }}
      >
        <div className="">
          <CFormInput
            disabled={Boolean(disabled)}
            placeholder={props.placeholder}
            value={textSearch}
            onChange={handleSearch}
            className=""
            style={{
              // borderColor: "#007bff",
              border: "1px solid #ccc",
            }}
          />
        </div>
        {/* <div
                    style={{
                        position: "absolute",
                        // top: "0",
                        left: 0,
                        zIndex: 1000,
                        minWidth: "300px",
                        backgroundColor: "white",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    }}
                >
                    <CListGroup flush>
                        {(textSearch || open) &&
                            filteredOptions?.map((option: any) => (
                                <CListGroupItem
                                    key={option[keyId]}
                                    onClick={() => handleClick(option)}
                                    style={{
                                        cursor: "pointer",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "14px",
                                    }}
                                >
                                    {option.name}
                                </CListGroupItem>
                            ))}
                    </CListGroup>
                </div> */}
      </div>
    </>
  );
}

export default FormSelect;
