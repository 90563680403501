import axios from "@/shared/config/axios-interceptor";
import { ResponseStatus } from "@/shared/enumeration/ResponseStatus";
import { ILogs } from "@/shared/model/project.modal";
import { INewTask, ITask } from "@/shared/model/task.model";
import { IParams } from "@/shared/shared-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";

const prefix = "task";
// const history = 'revision';

export interface ITaskParams extends IParams {
  projectId?: string;
}

export const getLogsTask = createAsyncThunk(
  `logs-${prefix}`,
  async (id: number, thunkAPI) => {
    try {
      const { data } = await axios.get<ILogs[]>(`${prefix}/log/${id}`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntities = createAsyncThunk(
  `get-all-${prefix}`,
  async (fields: ITaskParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<ITask[]>(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(
  `get-single-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      const { data } = await axios.get<ITask>(`${prefix}/${id}`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(
  `create-one-${prefix}`,
  async (body: INewTask, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${prefix}`,
        pickBy(body, (value) => value !== null && value !== undefined)
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateEntity = createAsyncThunk(
  `update-one-${prefix}`,
  async (body: ITask, thunkAPI) => {
    try {
      const { taskId } = body;
      if (!body.assignIds || !body.assignIds.length) {
        body.assignIds = body?.assignUsers?.[0]?.assigns?.map((value: any) => value.assignId)
        delete body.assignUsers;
      }
      const { data } = await axios.put<ITask>(
        `${prefix}/${taskId}`,
        pickBy(body, (value) => value !== null && value !== undefined)
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const removeEntity = createAsyncThunk(
  `delete-one-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      await axios.delete(`${prefix}/${id}`);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntitiesDataTask = createAsyncThunk(
  `get-all-task-data-${prefix}`,
  async (fields: ITaskParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<ITask[]>(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface ITaskDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: ITaskDel, thunkAPI) => {
    try {
      await axios.post<ITask>(`${prefix}/status`, requestBody);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
