import {
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState } from "@/reducers";

import {
  createEntity,
  getEntities,
  getEntity,
  removeEntity,
  updateEntity,
} from "./layer.api";
import { ILayer } from "@/shared/model/layer.model";
import { IInitialState, IResponse } from "@/shared/shared-interfaces";

export const layerAdapter = createEntityAdapter<ILayer>({
  selectId: ({ layerId }) => layerId,
});

export interface IInitialStateLayerReducer extends IInitialState {
  updateLayer: ILayer | null;
}

const initialState: IInitialStateLayerReducer = {
  totalPages: 1,
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  updateLayer: null,
};

const { actions, reducer } = createSlice({
  name: "layerSlice",
  initialState: layerAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.updateLayer = null;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateLayer = null;
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<ILayer[]>>>
      ) => {
        layerAdapter.setAll(state, payload.data.data);
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );

    builder.addCase(
      getEntity.fulfilled.type,
      (state, { payload }: PayloadAction<ILayer>) => {
        layerAdapter.upsertOne(state, payload);
        state.initialState.fetchEntitySuccess = true;
        state.initialState.loading = false;
      }
    );

    builder.addCase(
      getEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitySuccess = false;
      }
    );

    builder.addCase(
      updateEntity.fulfilled.type,
      (state, { payload }: PayloadAction<ILayer>) => {
        layerAdapter.updateOne(state, {
          id: payload.layerId,
          changes: payload,
        });
        state.initialState.updateEntitySuccess = true;
        state.initialState.loading = false;
      }
    );

    builder.addCase(
      updateEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
    builder.addCase(
      createEntity.fulfilled.type,
      (state, { payload }: PayloadAction<ILayer>) => {
        state.initialState.updateLayer = payload;
        layerAdapter.addOne(state, payload);
        state.initialState.updateEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      createEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
    builder.addCase(
      removeEntity.fulfilled.type,
      (state, { payload }: PayloadAction<string>) => {
        layerAdapter.removeOne(state, payload);
        state.initialState.totalItems -= 1;
        state.initialState.deleteEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      removeEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.deleteEntitySuccess = false;
      }
    );
  },
});

export default reducer;

export const { fetching, resetAll, resetEntity } = actions;
export const layerSelectors = layerAdapter.getSelectors(
  (state: RootState) => state.layoutReducer
);

const getLayerState = (state: RootState) => state.layoutReducer;
const { selectById } = layerAdapter.getSelectors();

export const selectEntityById = (id: string) => {
  return createSelector(getLayerState, (state) => selectById(state, id));
};
