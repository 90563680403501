import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import axios from "../../../../shared/config/axios-interceptor";
import { IParams } from "@/shared/shared-interfaces";
import { IComment, INewComment } from "@/shared/model/comment.model";

const prefix = "comment";

export interface ICommentParam extends IParams {
  projectId: number;
}

export const getEntities = createAsyncThunk(
  `get-all-${prefix}`,
  async (field: ICommentParam, thunkAPI) => {
    try {
      const params = pickBy(field);
      return await axios.get<IComment[]>(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(
  `get-single-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      const { data } = await axios.get<IComment>(`${prefix}/${id}`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateEntity = createAsyncThunk(
  `update-one-${prefix}`,
  async (body: IComment, thunkAPI) => {
    try {
      const { commentId } = body;
      const { data } = await axios.put<IComment>(
        `${prefix}/${commentId}`,
        body
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(
  `create-one-${prefix}`,
  async (body: INewComment, thunkAPI) => {
    try {
      const { data } = await axios.post(`${prefix}`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const removeEntity = createAsyncThunk(
  `delete-one-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      await axios.delete(`${prefix}/${id}`);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
