import { CFormInput, CFormLabel } from '@coreui/react-pro';
import dayjs from 'dayjs';
import { FileType } from '../TaskUploadContainer/FormikMultiFileUpload';
import { checkErrorInputFile } from '../TaskUploadContainer/InstanceMultiFileUpload';

interface IButtonUploadProps {
  name: string;
  onFileChange: (fileArr: FileType[]) => void;
  width: number;
  height: number;
  children: React.ReactNode;
  single?: boolean;
}

const ButtonUpload = (props: IButtonUploadProps) => {
  const { name, onFileChange, height, width, children, single } = props;

  const fileToDataURI = (file: File): Promise<FileType> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileObj: FileType = {
          file: file,
          dataURL: e.target?.result as string,
          name: file.name,
          size: file.size,
          type: file.type,
          uploadDate: dayjs().toISOString(),
        };
        resolve(fileObj);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const inputFiles: File[] = e.target.files as File[];
    if (!checkErrorInputFile(inputFiles)) return;
    if (inputFiles && inputFiles.length > 0) {
      const newImagesPromises: Promise<FileType>[] = [];
      Array.from(inputFiles).forEach((item) => {
        newImagesPromises.push(fileToDataURI(item));
      });
      const newImages = await Promise.all(newImagesPromises);
      onFileChange([...newImages]);
    }
  };

  return (
    <>
      {' '}
      <CFormLabel htmlFor={name} className="upload-file-button" style={{ width: `${width}px`, height: `${height}px` }}>
        <CFormInput
          className="input-upoad" 
          type="file"
          name={name}
          multiple={!single}
          id={name}
          onClick={(e) => (e.currentTarget.value = '')}
          onChange={handleFileUpload}
        />
        {children}
      </CFormLabel>{' '}
    </>
  );
};

export default ButtonUpload;
