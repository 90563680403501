import { ReactComponent as EditIcon } from "@/assets/img/edit-05.svg";
import CustomFormikEditor from "@/app/shared/CustomEditor/CustomEditor";
import FormDivider from "@/app/shared/FormDivider/FormDivider";
import InstanceMultiFileUpload from "@/app/shared/TaskUploadContainer/InstanceMultiFileUpload";
import { ToastError } from "@/app/shared/toast/Toast";
import { IProject } from "@/shared/model/project.modal";
import { ITask } from "@/shared/model/task.model";
import {
  checkIsDateAfter,
  checkIsDateBefore,
  handleJsonParseFileArr,
  handleUploadMultipleFile,
} from "@/shared/utils/ultils";
import { AppDispatch } from "@/store";
import {
  CButton,
  CCol,
  CDatePicker,
  CFormLabel,
  CRow,
} from "@coreui/react-pro";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ITaskTree } from "../TasksManagementGantt";
import { updateEntity } from "../tasksManagement.api";
import { useSelector } from "react-redux";
import { RootState } from "@/reducers";

interface ITaskInfomationTabProps {
  project: IProject;
  taskObj: ITaskTree;
}

const TaskInfomationTab = (props: ITaskInfomationTabProps) => {
  const { project, taskObj } = props;

  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.authentication);
  const [taskDescriptionValue, setTaskDescriptionValue] = useState<
    string | null
  >(null);

  const [selectedStartDate, setSelectedStartDate] = useState<string>(
    taskObj?.startedDate
  );

  const [selectedEndDate, setSelectedEndDate] = useState<string>(
    taskObj?.endDate
  );

  // const selectedStartDate = taskObj.startedDate;
  // const selectedEndDate = taskObj.endDate;

  const getRangeLimitDate = (
    limit: "max" | "min",
    parent: null | undefined | ITask
  ) => {
    if (limit === "max") {
      return parent?.endDate || project?.endedDate;
    } else {
      return parent?.startedDate || project?.startedDate;
    }
  };

  useEffect(() => {
    return () => {
      setTaskDescriptionValue(null);
    };
  }, []);

  return (
    <div className="infomation-tab tab-content">
      <CRow className="p-0 m-0">
        <CCol xs="12" className="p-0">
          <CFormLabel className="mb-6 label-gray-500 d-flex justify-content-between align-items-center">
            Mô tả
            <CButton
              className={`${
                taskDescriptionValue ? "d-none" : ""
              } btn-custom variant-gray-300 btn-2xs`}
              onClick={() =>
                setTaskDescriptionValue(taskObj?.description || "")
              }
            >
              <EditIcon /> Sửa
            </CButton>
          </CFormLabel>

          {taskDescriptionValue !== null ? (
            <div>
              <CustomFormikEditor
                simpleMode
                size="sm"
                name="description"
                value={taskDescriptionValue}
                setFieldValue={(_, value) => setTaskDescriptionValue(value)}
              />
              <div
                className="d-flex justify-content-end mt-3"
                style={{ gap: "12px" }}
              >
                <CButton
                  className="btn-custom variant-gray-300 btn-2xs"
                  onClick={() => setTaskDescriptionValue(null)}
                >
                  Huỷ
                </CButton>
                <CButton
                  disabled={
                    taskDescriptionValue === taskObj?.description ||
                    !taskDescriptionValue
                  }
                  className="btn-custom primary-500 btn-2xs"
                  onClick={() => {
                    dispatch(
                      updateEntity({
                        ...taskObj,
                        description: taskDescriptionValue,
                        assignIds: [Number(user?.id)],
                      })
                    );
                    setTaskDescriptionValue(null);
                  }}
                >
                  Lưu
                </CButton>
              </div>
            </div>
          ) : (
            <div
              className="render-container"
              dangerouslySetInnerHTML={{ __html: taskObj?.description ?? "" }}
            ></div>
          )}
        </CCol>
        <FormDivider />

        <CCol xs="12" className="p-0">
          <CRow>
            <CCol xs={12} md={6}>
              <CFormLabel className="mb-6 label-gray-500">
                Ngày bắt đầu
              </CFormLabel>
              <CDatePicker
                className="form-height-44"
                locale="vi-VN"
                weekdayFormat={"narrow"}
                id="startedDate"
                date={
                  (selectedStartDate && dayjs(selectedStartDate)?.toDate()) ??
                  ""
                }
                inputReadOnly
                onDateChange={(date) => {
                  if (
                    date &&
                    checkIsDateBefore(dayjs(date), dayjs(taskObj?.endDate))
                  ) {
                    setSelectedStartDate(
                      date ? dayjs(date)?.startOf("days")?.toISOString() : ""
                    );
                    dispatch(
                      updateEntity({
                        ...taskObj,
                        assignIds: [Number(user?.id)],
                        startedDate: date
                          ? dayjs(date)?.startOf("days")?.toISOString()
                          : "",
                      })
                    );
                  } else {
                    ToastError("Ngày bắt đầu phải trước ngày kết thúc");
                  }
                }}
                placeholder="Chọn ngày bắt đầu"
                minDate={dayjs(
                  getRangeLimitDate("min", taskObj?.parent)
                )?.toDate()}
                maxDate={dayjs(
                  getRangeLimitDate("max", taskObj?.parent)
                )?.toDate()}
              />
            </CCol>

            <CCol xs={12} md={6}>
              <CFormLabel className="mb-6 label-gray-500">
                Ngày kết thúc
              </CFormLabel>
              <CDatePicker
                id="endDate"
                className="form-height-44"
                locale="vi-VN"
                weekdayFormat={"narrow"}
                placeholder="Chọn ngày kết thúc"
                date={selectedEndDate && dayjs(selectedEndDate)?.toDate()}
                inputReadOnly
                onDateChange={(date) => {
                  if (
                    date &&
                    checkIsDateAfter(dayjs(date), dayjs(taskObj?.startedDate))
                  ) {
                    setSelectedEndDate(
                      date ? dayjs(date)?.endOf("days")?.toISOString() : ""
                    );

                    dispatch(
                      updateEntity({
                        ...taskObj,
                        assignIds: [Number(user?.id)],
                        endDate: date
                          ? dayjs(date)?.endOf("days")?.toISOString()
                          : "",
                      })
                    );
                  } else {
                    ToastError("Ngày kết thúc phải sau ngày bắt đầu");
                  }
                }} // BUg
                minDate={
                  dayjs(getRangeLimitDate("min", taskObj?.parent))?.toDate() ??
                  ""
                }
                maxDate={
                  dayjs(getRangeLimitDate("max", taskObj?.parent))?.toDate() ??
                  ""
                }
              />
            </CCol>
          </CRow>
        </CCol>
        <FormDivider />

        <CCol xs="12" className="p-0">
          <InstanceMultiFileUpload
            label={
              <CFormLabel className="mb-6 label-gray-500">
                Tài liệu tải lên
              </CFormLabel>
            }
            name="filesArr"
            onFileChange={async (value) => {
              const filesPromies = await handleUploadMultipleFile([...value]);
              dispatch(
                updateEntity({
                  ...taskObj,
                  files: filesPromies,
                  assignIds: [Number(user?.id)],
                })
              );
            }}
            fileValue={handleJsonParseFileArr(taskObj?.files)}
          />
        </CCol>
      </CRow>
    </div>
  );
};
export default TaskInfomationTab;
