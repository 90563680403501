import {
    createNoteProject,
    getDifferentEntities,
} from "@/app/modules/Project/projectManagement.api";
import {
    fetching,
    setFilterState,
} from "@/app/modules/Project/projectManagement.reducer";
import CustomTableFooter from "@/app/shared/CustomTableFooter/CustomTableFooter";
import FormSelect from "@/app/shared/FormSelect/FormSelect";
import SModal from "@/app/shared/Modal/SModal";
import { ToastError } from "@/app/shared/toast/Toast";
import { RootState } from "@/reducers";
import { IProject } from "@/shared/model/project.modal";
import { AppDispatch } from "@/store";
import { cilFilterX } from "@coreui/icons-pro";
import CIcon from "@coreui/icons-react";
import {
    CAvatar,
    CButton,
    CCol,
    CForm,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
    CSmartTable,
} from "@coreui/react-pro";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
interface IActionConnectFast {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    noteProjectData?: IProject[] | null;
}
export interface INotePostConnectFast {
    projectId: string;
    projectName: string;
}

interface IOptionValue<T> {
    label: string;
    value: T;
}
function ActionConnectFast(props: IActionConnectFast) {
    const { visible, setVisible, noteProjectData } = props;
    const dispatch = useDispatch<AppDispatch>();
    const [activePage, setActivePage] = useState<number>(1);
    const {
        filterState,
        differentProjectData,
        totalPageDifferent,
        totalItemDifferent,
        loading,
    } = useSelector((state: RootState) => state.projectReducer.initialState);
    const [tableItems, setTableItems] = useState<IProject[]>([]);
    const [selectedData, setSelectedData] = useState<IProject[]>(
        noteProjectData?.map((value) => {
            const newValue = { ...value, _selected: true };
            return newValue;
        }) ?? []
    );
    const initialValues = {
        projectId: "",
        projectName: "",
        project: null,
    };

    const [textSearch, setTextSearch] = useState<string>("");

    const handleOnClose = () => {
        setVisible(false);
    };

    const [resetTextSearch, setResetTextSearch] = useState<boolean>(false);
    const columns = [
        {
            key: "logo",
            label: "Logo",
            sorter: false,
            _style: { width: "15%", minWidth: "150px" },
            filter: false,
        },
        {
            key: "code",
            label: "Tên mã dự án",
            sorter: false,
            _style: { width: "45%", minWidth: "200px" },
        },
        {
            key: "name",
            label: "Tên dự án",
            sorter: false,
            _style: { width: "40%", minWidth: "200px" },
        },
    ];

    const ITEMS_PER_PAGE = 5;
    const handlePaginationChange = (page: number) => {
        dispatch(setFilterState({ ...filterState, page }));
    };
    useEffect(() => {
        setActivePage(filterState.page);
    }, [JSON.stringify(filterState)]);

    useEffect(() => {
        const array: IProject[] = [];
        differentProjectData?.forEach((value) => {
            if (
                selectedData?.some((val) => val.projectId === value.projectId)
            ) {
                array.push({ ...value, _selected: true });
                return;
            }

            array.push({ ...value });
        });

        setTableItems(array as IProject[]);
        setSelectedData((prev) => {
            const newPrev = prev.map((value) => {
                const findValue = array.find(
                    (item) => value?.projectId === item.projectId
                );
                if (findValue) {
                    return findValue;
                }
                return value;
            });
            return newPrev;
        });
    }, [JSON.stringify(differentProjectData)]);
    const onRowClick = (data: any) => {
        const findIndex = selectedData.findIndex(
            (value) => data?.projectId === value?.projectId
        );
        if (findIndex === -1) {
            if (selectedData.length >= 5) {
                ToastError(`Không thể chọn quá 5 dự án`);
                return;
            }
            setSelectedData((prev) => {
                const newArr = [...prev];
                newArr.push(data);
                return newArr;
            });
        } else {
            setSelectedData((prev) => {
                const newArr = [...prev];
                newArr.splice(findIndex, 1);
                return newArr;
            });
        }
    };

    // const handleOnChange = (items: Item[]) => {
    //     const newItems = Array.from(
    //         new Set(items.map((value: any) => JSON.stringify(value)))
    //     ).map((value) => JSON.parse(value));

    //     const newArray = newItems?.filter((value) =>
    //         selectedData.every((val) => val?.projectId !== value?.projectId)
    //     );
    //     const newTable = tableItems.map((value) => {
    //         if (newArray.length > 0) {
    //             if (value?.projectId === newArray[0]?.projectId) {
    //                 return {
    //                     ...value,
    //                     _selected: false,
    //                 };
    //             }
    //         }

    //         return {
    //             ...value,
    //             _selected: newItems.some(
    //                 (item) => item?.projectId === value?.projectId
    //             ),
    //         };
    //     });
    //     setTableItems(newTable);
    // };

    const scopedColumn = {
        logo: (item: IProject) => (
            <td>
                {item.avatar ? (
                    <CAvatar src={item?.avatar} className="avatar-25 me-12" />
                ) : (
                    <CAvatar
                        style={{
                            backgroundColor: `${
                                item?.avatarColor || "#1570ef"
                            }`,
                        }}
                        textColor="white"
                        className="avatar-25 me-12"
                    >
                        {item?.name?.[0]}
                    </CAvatar>
                )}
            </td>
        ),
        code: (item: IProject) => {
            return <td>{item.code}</td>;
        },
        name: (item: IProject) => <td>{item.name}</td>,
    };

    useEffect(() => {
        dispatch(fetching());
        dispatch(
            getDifferentEntities({
                ...filterState,
                page: filterState.page,
                limit: 5,
                projectType: "ALL_PROJECT",
            })
        );
    }, [JSON.stringify(filterState)]);

    //@ts-ignore
    useEffect(() => {
        return () =>
            dispatch(
                setFilterState({
                    page: 1,
                    limit: 10,
                    sortBy: "createdDate",
                    sortOrder: "DESC",
                })
            );
    }, []);

    return (
        <>
            {differentProjectData && noteProjectData && (
                <SModal
                    visible={visible}
                    onClose={handleOnClose}
                    backdrop="static"
                    className="custom-modal custom-table-fast custom-pop-up"
                >
                    <Formik
                        initialValues={initialValues}
                        // validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={async (value, { resetForm }) => {
                            const ids = selectedData.map(
                                (value) => `${value.projectId}`
                            );
                            if (ids.length > 5) {
                                ToastError("Vui lòng chọn không quá 5 dự án");
                                return;
                            }
                            if (ids.length > 0) {
                                dispatch(createNoteProject({ projectId: ids }));
                                handleOnClose();
                            } else {
                                ToastError("Vui lòng chọn ít nhất 1 dự án");
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            resetForm,
                            setFieldValue,
                        }) => (
                            <CForm
                                onSubmit={handleSubmit}
                                className="custom-form"
                            >
                                <CModalHeader>
                                    {"Thêm truy cập nhanh"}
                                </CModalHeader>
                                <CModalBody>
                                    <CRow>
                                        <CCol xs={12} className="mb-24">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <FormSelect
                                                    filterState={filterState}
                                                    setFilterState={
                                                        setFilterState
                                                    }
                                                    keyId="projectId"
                                                    keyNameSearch="key"
                                                    placeholder="Tên dự án..."
                                                    getEntities={
                                                        getDifferentEntities
                                                    }
                                                    resetTextSearch={
                                                        resetTextSearch
                                                    }
                                                ></FormSelect>

                                                <div>
                                                    <CButton
                                                        className="btn-custom btn-xs button-custom-hover"
                                                        style={{
                                                            color: "#FFF",
                                                            background:
                                                                "#FF8038",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            minWidth: "45px",
                                                            minHeight: "37.6px",
                                                        }}
                                                        onClick={() => {
                                                            setResetTextSearch(
                                                                (value) =>
                                                                    !value
                                                            );
                                                            dispatch(
                                                                getDifferentEntities(
                                                                    {
                                                                        page: 1,
                                                                        sortBy: "createdDate",
                                                                        sortOrder:
                                                                            "DESC",
                                                                        limit: 5,
                                                                    }
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        <CIcon
                                                            icon={cilFilterX}
                                                        />
                                                    </CButton>
                                                </div>
                                            </div>
                                        </CCol>
                                        <div style={{ color: "red" }}>
                                            <i>{`( * ) Số lượng dự án tối đa được chọn là ${ITEMS_PER_PAGE} dự án`}</i>
                                        </div>
                                        <CCol xs={12}>
                                            <div className="table-content my-3 pointer-event-none-table">
                                                <CSmartTable
                                                    activePage={activePage}
                                                    header
                                                    
                                                    selectable
                                                    loading={loading}
                                                    columns={columns}
                                                    selected={selectedData}
                                                    items={tableItems}
                                                    clickableRows
                                                    noItemsLabel="Không có bản ghi"
                                                    onRowClick={onRowClick}
                                                    scopedColumns={scopedColumn}
                                                    tableProps={{
                                                        hover: true,
                                                        align: "middle",
                                                        responsive: "lg",
                                                        className:
                                                            "custom-table",
                                                        style: {
                                                            borderRadius: "8px",
                                                        },
                                                    }}
                                                    tableHeadProps={{
                                                        className: "table-head",
                                                    }}
                                                    tableBodyProps={{
                                                        className: "table-body",
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <CustomTableFooter
                                                    totalItems={
                                                        totalItemDifferent
                                                    }
                                                    hideSideChosen
                                                    //@ts-ignore
                                                    totalPages={
                                                        totalPageDifferent
                                                    }
                                                    filterState={filterState}
                                                    setFilterState={(
                                                        filter
                                                    ) => {
                                                        dispatch(
                                                            setFilterState(
                                                                filter
                                                            )
                                                        );
                                                    }}
                                                    handlePaginationChange={
                                                        handlePaginationChange
                                                    }
                                                />
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CModalBody>

                                <CModalFooter className="d-flex justify-content-end">
                                    <CButton
                                        className="btn-custom minw-120 variant-gray-300"
                                        onClick={() => {
                                            resetForm();
                                            setVisible(false);
                                        }}
                                    >
                                        Huỷ
                                    </CButton>
                                    <CButton
                                        className="btn-custom minw-120 primary-500"
                                        type="submit"
                                        // disabled={loading}
                                    >
                                        Lưu
                                    </CButton>
                                </CModalFooter>
                            </CForm>
                        )}
                    </Formik>
                </SModal>
            )}
        </>
    );
}

export default ActionConnectFast;
