import { IParams } from "@/shared/shared-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/shared/config/axios-interceptor";

const prefix = "schedule";
export interface IMyScheduleParams extends IParams {
  start: string;
  end: string;
}

export const getEntities = createAsyncThunk(
  `getss-${prefix}`,
  async (fields: IMyScheduleParams, thunkAPI) => {
    try {
      return await axios.get(`${prefix}`, { params: fields });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntityCalender = createAsyncThunk(
  `get-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      const params = { assignId: id };
      return await axios.get(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createCalender = createAsyncThunk(
  `create-${prefix}`,
  async (body: any, thunkAPI) => {
    try {
      const response = await axios.post(`${prefix}`, body);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateCalender = createAsyncThunk(
  `update-${prefix}`,
  async (
    {
      taskId,
      newStatus,
      id,
    }: { taskId: number; newStatus?: string; id: string | undefined },
    thunkAPI
  ) => {
    try {
      const response = await axios.put(`${prefix}/${taskId}`, {
        status: newStatus,
        assignIds: [id],
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteCalender = createAsyncThunk(
  `delete-${prefix}`,
  async ({ task }: { task: number }, thunkAPI) => {
    try {
      const response = await axios.delete(`${prefix}/user-assign`, {
        data: { taskId: task },
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
