import { ReactComponent as ClipboardIcon } from "@/assets/img/clipboard-icon.svg";
import { ReactComponent as FolderIcon } from "@/assets/img/folder-icon.svg";
import { ReactComponent as HomeIcon } from "@/assets/img/home-icon.svg";
import { ReactComponent as ModalCloseIcon } from "@/assets/img/modal-close.svg";
import { ReactComponent as PlusIcon } from "@/assets/img/plusIcon.svg";
import { IUserParams } from "@/app/modules/SystemSetting/UserManagement/usersManagement.api";
import AvatarCheckBox from "@/app/shared/AvatarContainer/AvatarCheckBox";
import AvatarContainer from "@/app/shared/AvatarContainer/AvatarContainer";
import StatusSelect from "@/app/shared/StatusSelect/StatusSelect";
import TaskProgress from "@/app/shared/TaskProgress/TaskProgress";
import { RootState } from "@/reducers";
import { ResponseStatus } from "@/shared/enumeration/ResponseStatus";
import {
  SubTaskStatusEnum,
  TaskTypeEnum,
  subMapTaskStatusToColor,
  subMapTaskStatusToString,
  subTaskStatusArray,
  taskIdNameDefault,
} from "@/shared/enumeration/task.enum";
import { IProject } from "@/shared/model/project.modal";
import { ITask } from "@/shared/model/task.model";
import { IUser } from "@/shared/model/user.model";
import { ISelectValue } from "@/shared/shared-interfaces";
import { AppDispatch } from "@/store";
import {
  CButton,
  CDropdown,
  CDropdownHeader,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
} from "@coreui/react-pro";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { SingleValue } from "react-select";
import { getProjectStaff } from "../../../projectManagement.api";
import {
  ITaskTree,
  handleGetProgressBar,
  taskStatusProgressArray,
} from "../TasksManagementGantt";
import { getEntities, updateEntity } from "../tasksManagement.api";
import { getEntities as getTaskResult } from "./TaskResult/tasksResult.api";
import { ToastError, ToastSuccess } from "@/app/shared/toast/Toast";
import { resetEntity } from "../tasksManagement.reducer";
import { fetching } from "./TaskResult/tasksResult.reducer";
import { ITaskResult } from "@/shared/model/taskResult.model";
import { IProjectMember } from "@/shared/model/projectMember.modal";
import CIcon from "@coreui/icons-react";
import { cisChevronRightAlt } from "@coreui/icons-pro";
import { DictTypeEnum } from "@/shared/enumeration/dict.enum";
import { getDataDifferent } from "@/app/modules/SystemSetting/DictionaryManagement/parentManegement/dictionaryManagement.api";

export interface ITaskTrace {
  id: string | number;
  name: string;
}

const handleGetBreadCrumbToCurrent = (task?: ITask) => {
  const traceArr: ITaskTrace[] = [];

  if (task) {
    if (task?.parent) {
      const getTaskParentArray = handleGetBreadCrumbToCurrent(task?.parent);
      getTaskParentArray?.forEach((item) => traceArr.push(item));
    }
    traceArr.push({ id: task?.taskId, name: task?.name });
  }

  return traceArr;
};

interface ITaskDetailHeaderProps {
  project: IProject;
  currentTask?: ITaskTree;
  onClose: () => void;
  handleSelectId: (id: string | number) => void;
  taskNameValue: string | null;
  setTaskNameValue: (value: string | null) => void;
  selectTaskId: string | number;
  isMyTask?: boolean;
  isAddMember?: boolean;
  projectMembers?: IProjectMember[];
}

const TaskDetailHeader = (props: ITaskDetailHeaderProps) => {
  const {
    currentTask,
    project,
    onClose,
    handleSelectId,
    setTaskNameValue,
    taskNameValue,
    selectTaskId,
    isMyTask,
    isAddMember,
    projectMembers,
  } = props;

  const { initialState: projectInitialState } = useSelector(
    (state: RootState) => state.projectReducer
  );
  const { projectStaff } = projectInitialState;
  const { filterState } = useSelector(
    (state: RootState) => state.tasksReducer.initialState
  );
  const { initialState } = useSelector(
    (state: RootState) => state.taskResultReducer
  );
  const stateTabResult = initialState;
  const dispatch = useDispatch<AppDispatch>();
  const [avatarDropdownOpen, setAvatarDropdownOpen] = useState<boolean>(false);
  const [isStatus, setIsStatus] = useState<boolean>(false);
  const [isLoadingResult, setIsLoadingResult] = useState<boolean>(false);
  const prop = useSelector((state: RootState) => state.tasksReducer);
  const { user } = useSelector((state: RootState) => state.authentication);
  const state = prop.initialState;
  const [taskResults, setTaskResults] = useState<ITaskResult[]>([]);
  const {dataDifferent} = useSelector((state: RootState) => state.dictionaryParentReducer.initialState)
  useEffect(() => {
      dispatch(getDataDifferent(DictTypeEnum.CATE_TASK_TYPE));
  }, [])
  useEffect(() => {
    dispatch(fetching());
    setIsLoadingResult(false);
    dispatch(
      getTaskResult({
        ...stateTabResult.filterState,
        taskId: Number(selectTaskId),
      })
    ).then((data: any) => {
      setIsLoadingResult(true);
      setTaskResults(data.payload?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), stateTabResult.updateEntitySuccess]);

  useEffect(() => {
    if (state.updateEntitySuccess && !isStatus) {
      ToastSuccess("Cập nhật thành công");
      setAvatarDropdownOpen(false);
      dispatch(resetEntity());
      return;
    }
    if (state.updateEntitySuccess && isStatus) {
      ToastSuccess("Thay đổi trạng thái thành công");
      setIsStatus(false);
      dispatch(resetEntity());
      return;
    } 
  }, [state.updateEntitySuccess, isStatus]);

  const handleGetTrace = (currentTask?: ITask) => {
    const traceArr: ITaskTrace[] = [];
    if (currentTask?.id !== taskIdNameDefault.TOPPROJECT) {
      traceArr.push({ id: taskIdNameDefault.TOPPROJECT, name: project?.name });
      const getTaskParentArray = handleGetBreadCrumbToCurrent(currentTask);
      traceArr.push(...getTaskParentArray);
    } else {
      traceArr.push({ id: taskIdNameDefault.TOPPROJECT, name: project?.name });
    }

    return traceArr;
  };

  const traceArr = handleGetTrace(currentTask);
  const mapTaskTypeToIcon: { [key in TaskTypeEnum]: React.ReactNode } = {
    [TaskTypeEnum.FOLDER]: <FolderIcon className="tree-icon" />,
    [TaskTypeEnum.TASK]: <ClipboardIcon className="tree-icon" />,
  };

  const userArray =
    currentTask?.assignUsers?.[0]?.assigns?.map((item: any) => item.assign) ??
    currentTask?.assigns?.map((item: any) => item.assign);

  const [userFilter, setUserFilter] = useState<IUserParams>({
    page: 1,
    limit: 20,
    status: ResponseStatus.ACTIVE,
  });

  useEffect(() => {
    dispatch(
      getProjectStaff({ id: project?.projectId, field: { ...userFilter } })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userFilter)]);

  const returnUserMultipleItem = (userItem: IUser[], selectUser?: any) => {
    if (!userItem) return [];
    if (selectUser) {
      const userSelect = selectUser?.map((item: any) => {
        return {
          value: item.assign.id,
          label: `${item.assign.fullName} - (${item.assign.username})`,
        };
      });
      const userSelectList = userItem?.map((item) => {
        return {
          value: item.id,
          label: `${item.fullName} - (${item.username})`,
        };
      });
      // const result = [...userSelect, ...userSelectList];
      const result = userSelectList?.filter(
        (item) => !userSelect.some((select: any) => select.value === item.value)
      );
      return result;
    }
    return userItem?.map((item) => {
      return {
        value: item.id,
        label: `${item?.fullName} - (${item?.username})`,
      };
    });
  };

  const customUserItems: ISelectValue<string>[] = returnUserMultipleItem(
    projectStaff?.map((item) => item.user),
    currentTask?.assigns || currentTask?.assignUsers ? currentTask?.assignUsers[0].assigns?.map((item: any) => item) : []
  );

  return (
    <>
      <div className="header-container">
        <div className="breadcrumb-container d-flex justify-content-between">
          <div className="breadcrumb-container d-flex justify-content-start">
            {traceArr?.map((trace, index) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  className="breadcrumb m-0 cursor-pointer"
                  key={index}
                  onClick={() => {
                    handleSelectId(trace?.id);
                  }}
                >
                  {trace.name}{" "}
                </p>
                {(index !== traceArr.length - 1 ||
                  trace.id === taskIdNameDefault.TOPPROJECT) && (
                  <div>
                    <CIcon
                      style={{ color: "#cdd5df" }}
                      icon={cisChevronRightAlt}
                      className="breadcrumb-icon"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>

          <ModalCloseIcon
            onClick={onClose}
            className={"close-modal-icon cursor-pointer"}
          />
        </div>
        <div className="title-container d-flex justify-content-start">
          {selectTaskId === taskIdNameDefault.TOPPROJECT ? (
            <HomeIcon className="home-icon" />
          ) : (
            mapTaskTypeToIcon[currentTask?.type as TaskTypeEnum]
          )}

          {selectTaskId === taskIdNameDefault.TOPPROJECT ? (
            <p className="head-title m-0">{project?.name}</p>
          ) : (
            <>
              <div
                className={`${
                  taskNameValue !== null ? "d-none" : ""
                } cursor-pointer`}
                onClick={() => setTaskNameValue(currentTask?.name || "")}
              >
                <p className="head-title m-0">{currentTask?.name}</p>
              </div>

              <div
                className={`${
                  taskNameValue === null ? "d-none" : ""
                } d-flex justify-content-start w-100`}
                style={{ gap: "12px" }}
              >
                <CFormInput
                  className="form-height-28"
                  name="name"
                  autoComplete="off"
                  value={taskNameValue || ""}
                  onChange={(e) => setTaskNameValue(e.target.value)}
                  invalid={!taskNameValue}
                />
                <CButton
                  className="btn-custom variant-gray-300 btn-2xs"
                  onClick={() => setTaskNameValue(null)}
                >
                  Huỷ
                </CButton>
                <CButton
                  className="btn-custom primary-500 btn-2xs"
                  disabled={
                    !taskNameValue || taskNameValue === currentTask?.name
                  }
                  onClick={() => {
                    dispatch(
                      updateEntity({
                        ...currentTask,
                        name: taskNameValue,
                        assignIds: [user?.id],
                      } as any)
                    );
                    setTaskNameValue(null);
                  }}
                >
                  Lưu
                </CButton>
              </div>
            </>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center">
          {currentTask?.type === TaskTypeEnum.TASK ? (
            <div
              className="d-flex justify-content-start"
              style={{ gap: "8px" }}
            >
              <StatusSelect
                color={
                  subMapTaskStatusToColor[
                    //@ts-ignore
                      currentTask?.status as SubTaskStatusEnum
                  ]
              }
              options={subTaskStatusArray?.map((item) => ({
                  value: item,
                  label: subMapTaskStatusToString[item],
              }))}
              value={{
                //@ts-ignore
                  value: currentTask?.status as SubTaskStatusEnum,
                  label: subMapTaskStatusToString[
                    //@ts-ignore
                      currentTask?.status as SubTaskStatusEnum
                  ],
              }}
                minWidth="158px"
                inModal
                onChange={async (value) => {
                  setIsStatus(true);
                  if (value?.value === SubTaskStatusEnum.REVIEW) {
                    if (taskResults.length === 0 && isLoadingResult) {
                      ToastError(" Vui lòng tải tệp kết quả");
                      return;
                    }
                  }
                  await dispatch(
                    updateEntity({
                      ...currentTask,
                      status: value?.value as SubTaskStatusEnum,
                      assignIds: [user?.id],
                    } as any)
                  );
                  await dispatch(
                    getEntities({
                      ...filterState,
                      projectId: String(currentTask.projectId),
                    })
                  );
                }}
              />
              <StatusSelect
                color={"blue-100"}
                options={dataDifferent?.map((item: any) => ({
                  value: Number(item?.dictItemId),
                  label: item.label,
                }))}
                value={{
                  value: Number(currentTask?.dictItemId),
                  label: dataDifferent.find((value: any) => {
                    return Number(value?.dictItemId) === Number(currentTask?.dictItemId)
                  })?.label
                }}
                minWidth="158px"
                inModal
                onChange={async (value) => {
                  setIsStatus(true);
                  await dispatch(
                    updateEntity({
                      ...currentTask,
                      assignIds: [user?.id],
                      dictItemId: value?.value,
                    } as any)
                  );
                  await dispatch(
                    getEntities({
                      ...filterState,
                      projectId: String(currentTask.projectId),
                    })
                  );
                }}
              />
            </div>
          ) : (
            <div style={{ minWidth: "158px" }}>
              <TaskProgress
                height={6}
                taskProgress={handleGetProgressBar(
                  currentTask as ITaskTree,
                  taskStatusProgressArray
                )}
              />
            </div>
          )}
          <div className="d-flex justify-content-end" style={{ gap: "8px" }}>
            {!isAddMember && (
              <CDropdown
                className="user-addmore-container"
                autoClose="outside"
                placement="bottom"
                visible={avatarDropdownOpen}
                onHide={() => setAvatarDropdownOpen(false)}
                onShow={() => setAvatarDropdownOpen(true)}
              >
                {currentTask?.id !== taskIdNameDefault.TOPPROJECT && <CDropdownToggle
                  className="avatar-addmore cursor-pointer"
                  onClick={() => setAvatarDropdownOpen(!avatarDropdownOpen)}
                  caret={false}
                  style={{
                    width: `28px`,
                    height: `28px`,
                    fontSize: `16px`,
                  }}
                >
                  <PlusIcon />
                </CDropdownToggle>}
                <CDropdownMenu className="dropdown-menu">
                  <CDropdownHeader className="dropdown-header ">
                    <div className="header-content d-flex align-items-center justify-content-center">
                      Thành viên
                      <ModalCloseIcon
                        onClick={() => setAvatarDropdownOpen(false)}
                        className={"close-dropdown-icon cursor-pointer"}
                      />
                    </div>
                  </CDropdownHeader>

                  <div className="dropdown-body custom-form">
                    <Select
                      defaultValue={null}
                      className="custom-select height-32"
                      classNamePrefix="react-select"
                      value={null}
                      onChange={(
                        newValue: SingleValue<ISelectValue<string> | null>
                      ) => {
                        if (!newValue) {
                          setUserFilter({
                            page: 1,
                            limit: 20,
                            status: ResponseStatus.ACTIVE,
                          });
                        } else {
                          const newAssigns = userArray?.map((item: any) =>
                            Number(item?.id)
                          );
                          dispatch(
                            updateEntity({
                              ...currentTask,
                              assignIds: [
                                ...newAssigns,
                                Number(newValue.value),
                              ],
                            } as ITask)
                          );
                        }

                        //   const optionValue = newValue.map((item) => Number(item?.value));
                        //   setFieldValue(`assignIds`, optionValue);
                      }}
                      onMenuScrollToBottom={() => {
                        setUserFilter({
                          ...userFilter,
                          limit: userFilter.limit + 20,
                        });
                      }}
                      onInputChange={(newValue: string) => {
                        setUserFilter({ ...userFilter, key: newValue });
                      }}
                      id={"assignIds"}
                      noOptionsMessage={() => <>Không có thành viên nào</>}
                      // onInputChange={textValue.onInputChange}
                      // styles={customSelectStyles}
                      options={customUserItems}
                      placeholder={"Chọn thành viên..."}
                      isClearable={true}
                      closeMenuOnSelect={false}
                      menuPosition={"absolute"}
                      //   menuPortalTarget={document.body}
                    />

                    <div className="user-list-checkbox">
                      <p className="checkbox-label">Thành viên dự án</p>
                      <div
                        className="d-flex flex-wrap align-items-center justify-content-start"
                        style={{ gap: "8px" }}
                      >
                        {userArray?.map((item: any) => (
                          <AvatarCheckBox
                            user={item}
                            key={item.id}
                            checked={Boolean(
                              userArray?.find(
                                (user: any) => user.id === item.id
                              )
                            )}
                            onChecked={(user, checked) => {
                              if (!checked) {
                                const newAssigns = currentTask?.assigns
                                  ?.filter(
                                    (assign) => assign.assign.id !== user.id
                                  )
                                  ?.map((user) => user.assignId);
                                dispatch(
                                  updateEntity({
                                    ...currentTask,
                                    assignIds: newAssigns,
                                  } as ITask)
                                );
                              }
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </CDropdownMenu>
              </CDropdown>
            )}

            <AvatarContainer
              isMyTask={isMyTask}
              limit={3}
              size={24}
              userArray={
                userArray ? userArray : projectMembers?.map((item) => item.user)
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskDetailHeader;
