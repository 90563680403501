import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeaderItem } from './NavItems';

interface IContainerState {
  sidebarShow: boolean;
  asideShow: boolean;
  darkMode: boolean;
  bodyWidth: number;
  customHeader: HeaderItem | null;
}

const initialState: IContainerState = {
  sidebarShow: true,
  asideShow: false,
  darkMode: false,
  bodyWidth: 0,
  customHeader: null,
};

const containerSlice = createSlice({
  name: 'containerSlice',
  initialState,
  reducers: {
    toggleSidebar: (state, { payload }: PayloadAction<boolean>) => {
      state.sidebarShow = payload;
    },
    toggleAside: (state, { payload }: PayloadAction<boolean>) => {
      state.asideShow = payload;
    },
    toggleDarkMode: (state, { payload }: PayloadAction<boolean>) => {
      state.darkMode = payload;
    },
    setBodyWidth: (state, { payload }: PayloadAction<number>) => {
      state.bodyWidth = payload;
    },
    setCustomHeader: (state, { payload }: PayloadAction<HeaderItem | null>) => {
      state.customHeader = payload;
    },
  },
});

export default containerSlice.reducer;
export const { toggleSidebar, toggleAside, toggleDarkMode, setBodyWidth, setCustomHeader } = containerSlice.actions;
