import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

const UpdateProfile = React.lazy(() => import('./UpdateProfile'));
const ChangePassword = React.lazy(() => import('./ChangePassword'));
// const ChangeEmail = React.lazy(() => import('./ChangeEmail'));
const Support = React.lazy(() => import('./Support'));

const AccountSettingLayout: RouteObject[] = [
  {
    path: '/',
    element: <UpdateProfile />,
  },
  {
    path: '/profile',
    element: <UpdateProfile />,
  },
  {
    path: '/change-password',
    element: <ChangePassword />,
  },
  // {
  //   path: '/change-email',
  //   element: <ChangeEmail />,
  // },
  {
    path: '/notifications',
    element: <Support />,
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const AccountSettingRoutes = () => useRoutes(AccountSettingLayout);
export default AccountSettingRoutes;
