import axios from "@/shared/config/axios-interceptor";
import { ResponseStatus } from "@/shared/enumeration/ResponseStatus";
import { ILogs, INewProject, IProject } from "@/shared/model/project.modal";
import { IStaff } from "@/shared/model/user.model";
import { IParams } from "@/shared/shared-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import { IUserParams } from "../SystemSetting/UserManagement/usersManagement.api";

const prefix = "project";
// const history = 'revision';

export type IProjectParams = IParams;

export const getLogsEntities = createAsyncThunk(
  `logs-${prefix}`,
  async (id: number, thunkAPI) => {
    try {
      const { data } = await axios.get<ILogs[]>(`${prefix}/logs/${id}`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntities = createAsyncThunk(
  `get-all-${prefix}`,
  async (fields: IProjectParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IProject[]>(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(
  `get-single-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      const { data } = await axios.get<IProject>(`${prefix}/${id}`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(
  `create-one-${prefix}`,
  async (body: INewProject, thunkAPI) => {
    try {
      const { data } = await axios.post(`${prefix}`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateEntity = createAsyncThunk(
  `update-one-${prefix}`,
  async (body: IProject, thunkAPI) => {
    try {
      const { projectId } = body;
      const { data } = await axios.put<IProject>(
        `${prefix}/${projectId}`,
        pickBy(body)
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const removeEntity = createAsyncThunk(
  `delete-one-${prefix}`,
  async (id: string, thunkAPI) => {
    try {
      await axios.delete(`${prefix}/${id}`);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface IProjectDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: IProjectDel, thunkAPI) => {
    try {
      await axios.post<IProject>(`${prefix}/status`, requestBody);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IProjectStaffParam {
  field: IUserParams;
  id: number;
}

export const getProjectStaff = createAsyncThunk(
  `get-all-staff-${prefix}`,
  async (body: IProjectStaffParam, thunkAPI) => {
    try {
      const { field, id } = body;
      const params = pickBy(field);
      return await axios.get<IStaff[]>(`project/staff/${id}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IProjectDocumentBody {
  projectId: number;
  file: string;
}

export const uploadProjectDocument = createAsyncThunk(
  `upload-document-${prefix}`,
  async (body: IProjectDocumentBody, thunkAPI) => {
    try {
      const { data } = await axios.post(`${prefix}/document`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNoteProject = createAsyncThunk(
  `get-note-${prefix}`,
  async (body: IProjectParams, thunkAPI) => {
    try {
      const params = pickBy(body);
      const { data } = await axios.get(`${prefix}/note-project`, { params });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createNoteProject = createAsyncThunk(
  `update-note-${prefix}`,
  async (body: { projectId: string[] }, thunkAPI) => {
    try {
      const { data } = await axios.post(`${prefix}/note-project`, pickBy(body));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const removeNoteProject = createAsyncThunk(
  `delete-note-${prefix}`,
  async (body: { projectId: string[] }, thunkAPI) => {
    try {
      const { data } = await axios.delete(`${prefix}/note-project`, {
        data: body,
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getDifferentEntities = createAsyncThunk(
  `get-different-all-${prefix}`,
  async (fields: IProjectParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IProject[]>(`${prefix}/note-project-not-assign`, {
        params,
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getDataAllEntities = createAsyncThunk(
  `get-different-data-all-ent-${prefix}`,
  async (fields: IProjectParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IProject[]>(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getLogUser = createAsyncThunk(
  `get-log-user`,
  async (username: string, thunkAPI) => {
    try {
      return await axios.get(`log/user`, { params: { username: username } });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSearchProjectData = createAsyncThunk(
  `get-all-search-data-${prefix}`,
  async (fields: IProjectParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IProject[]>(`${prefix}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
