import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { getEntities } from "./tasksResult.api";
import { ToastError } from "@/app/shared/toast/Toast";
import { ITaskResult } from "@/shared/model/taskResult.model";

export const useTaskResults = () => {
  const dispatch = useDispatch();
  const [isLoadingResult, setIsLoadingResult] = useState<boolean>(false);
  const [taskResults, setTaskResults] = useState<ITaskResult[]>([]);
  const stateTabResult = useSelector(
    (state: RootState) => state.taskResultReducer.initialState
  );

  const fetchAndCheckResults = useCallback(
    async (taskId: number) => {
      setIsLoadingResult(true);
      try {
        const action: any = await dispatch(
          getEntities({
            ...stateTabResult.filterState,
            taskId: Number(taskId),
          }) as any
        );

        const results = action.payload?.data?.data || [];
        setTaskResults(results);

        if (results.length === 0) {
          ToastError(
            "Không thể chuyển trạng thái. Vui lòng tải tệp lên trang kết quả trước."
          );
          return false;
        }
        return true;
      } catch (error) {
        console.error("Error fetching task results:", error);
        ToastError("Đã xảy ra lỗi khi tải kết quả công việc");
        return false;
      } finally {
        setIsLoadingResult(false);
      }
    },
    [dispatch, stateTabResult.filterState]
  );

  return {
    isLoadingResult,
    taskResults,
    fetchAndCheckResults,
  };
};
