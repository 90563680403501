import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import ProtectedPermission from '../../auth/ProtectedPermission';
import { Permission } from '@/shared/enumeration/permission';

const DictionaryManagement = React.lazy(() => import('./DictionaryManagement'));

const DictionaryLayout: RouteObject[] = [
  {
    path: '/',
    element: (
        <ProtectedPermission requiredPerms={[Permission.DICT_VIEW]}>
          <DictionaryManagement />
        </ProtectedPermission>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const DictionaryRoutes = () => useRoutes(DictionaryLayout);
export default DictionaryRoutes;
