import {
    cilArrowLeft,
    cilArrowRight,
    cisChevronDoubleLeftAlt,
    cisChevronDoubleRightAlt,
} from "@coreui/icons-pro";
import CIcon from "@coreui/icons-react";
import {
    CCol,
    CFormLabel,
    CFormSelect,
    CSmartPagination,
} from "@coreui/react-pro";
import { IParams } from "../../../shared/shared-interfaces";
import { useDeviceDetect } from "../../../shared/utils/hooks/useDeviceDetect";

export interface ICustomTableFooter<T extends IParams> {
    totalPages: number;
    totalItems?: number;
    filterState: T;
    handlePaginationChange: (page: number) => void;
    setFilterState: (filterState: IParams) => void;
    sizeArray?: number[];
    hideSideChosen?: boolean;
}

const CustomTableFooter = <T extends IParams>(props: ICustomTableFooter<T>) => {
    const {
        filterState,
        setFilterState,
        totalPages,
        handlePaginationChange,
        sizeArray,
        totalItems,
        hideSideChosen,
    } = props;
    const isMobile = useDeviceDetect();
    const selectOption = sizeArray || [10, 20, 30, 50];
    const startEndIndexCal = () => {
        const endIndex = filterState.page * filterState.limit;
        const startIndex = (filterState.page - 1) * filterState.limit + 1;

        return {
            startIndex,
            endIndex: endIndex > (totalItems || 0) ? totalItems : endIndex,
        };
    };
    const startEndIndex = startEndIndexCal();
    return (
        <>
            <CCol
                className={`d-flex ${
                    hideSideChosen
                        ? "justify-content-end"
                        : "justify-content-between"
                } flex-wrap custom-table-footer align-items-center`}
            >
                <div
                    className={`${
                        hideSideChosen ? "d-none" : "d-flex"
                    } justify-content-start align-items-center `}
                >
                    <div className="custom-form">
                        <CFormSelect
                            id="inputGroupSelect01"
                            className="pagi-select cursor-pointer"
                            value={filterState.limit}
                            onChange={(e) =>
                                setFilterState({
                                    ...filterState,
                                    limit: Number(e.currentTarget.value),
                                })
                            }
                        >
                            {selectOption.map((item, index) => (
                                <option value={item?.toString()} key={index}>
                                    <>{item}</>
                                </option>
                            ))}
                        </CFormSelect>
                    </div>
                    {totalItems ? (
                        <CFormLabel className="pagi-label m-0 ms-3">
                            {startEndIndex.startIndex} -{" "}
                            {startEndIndex.endIndex} trên {totalItems}
                        </CFormLabel>
                    ) : (
                        ""
                    )}
                </div>

                <CSmartPagination
                    align="end"
                    className="table-pagination cursor-pointer m-0"
                    doubleArrows={true}
                    limit={isMobile ? 3 : undefined}
                    activePage={filterState.page}
                    pages={totalPages || 1}
                    onActivePageChange={handlePaginationChange}
                    nextButton={
                        <div
                            className="cursor-pointer d-flex align-items-center"
                            style={{
                                color: "#000",
                            }}
                        >
                            <CIcon icon={cilArrowRight} />
                        </div>
                    }
                    previousButton={
                        <div
                            className="cursor-pointer d-flex align-items-center"
                            style={{ color: "#000" }}
                        >
                            <CIcon icon={cilArrowLeft} />
                        </div>
                    }
                    lastButton={
                        <div
                            className="cursor-pointer"
                            style={{ display: "none" }}
                        >
                            <CIcon icon={cisChevronDoubleRightAlt} />
                        </div>
                    }
                    firstButton={
                        <div
                            className="cursor-pointer"
                            style={{ display: "none" }}
                        >
                            <CIcon icon={cisChevronDoubleLeftAlt} />
                        </div>
                    }
                />
            </CCol>
        </>
    );
};

export default CustomTableFooter;
