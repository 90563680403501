import {
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState } from "../../../../reducers";
import { IUser } from "../../../../shared/model/user.model";
import { IInitialState, IResponse } from "../../../../shared/shared-interfaces";

import {
    createEntity,
    getAllEntities,
    getEntities,
    getEntity,
    IUserParams,
    removeEntity,
    updateDeleteEntity,
    updateEntity,
} from "./usersManagement.api";

interface IUsersInitialState extends IInitialState {
    updateEntityStatusSuccess: boolean;
    filterState: IUserParams;
    allUser: IUser[];
}

export const initialUsersFilter: IUserParams = {
    page: 1,
    limit: 10,
    sortBy: "createdDate",
    sortOrder: "DESC",
};

const initialState: IUsersInitialState = {
    fetchEntitiesSuccess: false,
    fetchEntitySuccess: false,
    updateEntitySuccess: false,
    deleteEntitySuccess: false,
    loading: false,
    errorMessage: null,
    totalItems: 0,
    totalPages: 0,
    updateEntityStatusSuccess: false,
    filterState: initialUsersFilter,
    allUser: [],
};

export const usersAdapter = createEntityAdapter<IUser>({
    selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
    name: "usersSlice",
    initialState: usersAdapter.getInitialState({ initialState }),
    reducers: {
        setFilterState(state, { payload }: PayloadAction<IUserParams>) {
            state.initialState.filterState = payload;
        },
        fetching(state) {
            state.initialState.loading = true;
        },
        resetAll(state) {
            state.initialState.loading = false;
            state.initialState.fetchEntitiesSuccess = false;
            state.initialState.fetchEntitySuccess = false;
            state.initialState.updateEntitySuccess = false;
            state.initialState.deleteEntitySuccess = false;
            state.initialState.errorMessage = null;
        },
        resetEntity(state) {
            state.initialState.updateEntitySuccess = false;
            state.initialState.errorMessage = null;
            state.initialState.deleteEntitySuccess = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getEntities.fulfilled.type,
            (
                state,
                { payload }: PayloadAction<AxiosResponse<IResponse<IUser[]>>>
            ) => {
                usersAdapter.setAll(state, payload.data.data);
                state.initialState.totalItems = Number(payload.data.count);
                state.initialState.totalPages = Number(
                    payload.data.total_pages
                );
                state.initialState.fetchEntitiesSuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            getEntities.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.fetchEntitiesSuccess = false;
            }
        );

        builder.addCase(
            getEntity.fulfilled.type,
            (state, { payload }: PayloadAction<IUser>) => {
                usersAdapter.upsertOne(state, payload);
                state.initialState.fetchEntitySuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            getEntity.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.fetchEntitySuccess = false;
            }
        );
        builder.addCase(
            updateEntity.fulfilled.type,
            (state, { payload }: PayloadAction<IUser>) => {
                usersAdapter.updateOne(state, {
                    id: payload.id,
                    changes: payload,
                });
                state.initialState.updateEntitySuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            updateEntity.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.updateEntitySuccess = false;
            }
        );
        builder.addCase(
            createEntity.fulfilled.type,
            (state, { payload }: PayloadAction<IUser>) => {
                usersAdapter.addOne(state, payload);
                state.initialState.updateEntitySuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            createEntity.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.updateEntitySuccess = false;
            }
        );
        builder.addCase(
            removeEntity.fulfilled.type,
            (state, { payload }: PayloadAction<string>) => {
                usersAdapter.removeOne(state, payload);
                state.initialState.totalItems -= 1;
                state.initialState.deleteEntitySuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            removeEntity.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.deleteEntitySuccess = false;
            }
        );
        builder.addCase(
            updateDeleteEntity.fulfilled.type,
            (state, { payload }: PayloadAction<string[]>) => {
                usersAdapter.removeMany(state, payload);
                state.initialState.totalItems -= payload.length;
                state.initialState.deleteEntitySuccess = true;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            updateDeleteEntity.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
                state.initialState.deleteEntitySuccess = false;
            }
        );

        builder.addCase(
            getAllEntities.fulfilled.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.allUser = payload?.data?.data;
                state.initialState.loading = false;
            }
        );
        builder.addCase(
            getAllEntities.rejected.type,
            (state, { payload }: PayloadAction<any>) => {
                state.initialState.errorMessage = payload?.message;
                state.initialState.loading = false;
            }
        );
    },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const userSelectors = usersAdapter.getSelectors<RootState>(
    (state) => state.usersReducer
);

const { selectById } = usersAdapter.getSelectors();
const getUserState = (rootState: RootState) => rootState.usersReducer;

export const selectEntityById = (id: string) => {
    return createSelector(getUserState, (state) => selectById(state, id));
};
