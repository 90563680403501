import { ReactComponent as PlusCircle } from "@/assets/img/plust-circle.svg";
import {
    cilBell,
    cilCalculator,
    cilChartPie,
    cilCursor,
    cilDrop,
    cilNotes,
    cilPencil,
    cilPuzzle,
    cilStar,
} from "@coreui/icons-pro";
import CIcon from "@coreui/icons-react";
import {
    CAvatar,
    CBadge,
    CNavGroup,
    CNavGroupItems,
    CNavItem,
    CNavTitle,
    CSidebar,
    CSidebarBrand,
    CSidebarNav,
} from "@coreui/react-pro";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router-dom";
import { RootState } from "../../../reducers";
import { useRouter } from "../../../shared/utils/hooks/useRouter";
import ArrowNarrowLeftIcon from "../../shared/icons/ArrowNarrowLeftIcon";
import Logo from "../../shared/icons/sidebar/Logo";
import LogoFull from "../../shared/icons/sidebar/LogoFull";
import { SidebarItem, defaultSiderbarItems } from "../NavItems";
import { toggleSidebar } from "../reducer";
import {
    getNoteProject,
    removeNoteProject,
} from "@/app/modules/Project/projectManagement.api";
import ActionConnectFast from "./ActionConnectFast";
import { resetAll } from "@/app/modules/Project/projectManagement.reducer";
import { ToastSuccess } from "@/app/shared/toast/Toast";
import { IProject } from "@/shared/model/project.modal";
import {
    checkUserAdmin,
    hasPermission,
} from "@/app/modules/auth/ProtectedPermission";

interface ICustomCNavItem {
    href: string;
    target?: string;
    customClassname?: string;
    children: React.ReactNode;
}

export const CustomCNavItem = ({
    href,
    children,
    customClassname,
    target,
}: ICustomCNavItem) => {
    const { location } = useRouter();
    const isActive = matchPath({ path: href, end: false }, location.pathname);
    return (
        <CNavItem
            href={`#${href}`}
            target={target}
            active={Boolean(isActive)}
            className={`${customClassname || ""} text-break dummy`}
        >
            {children}
        </CNavItem>
    );
};

interface ICustomCNavTitle {
    href: string;
    customClassname?: string;
    label: string;
}

// export const getLastChildValue = (props: SidebarItem[], parentName: string[]): SidebarItem[] => {
//   let arrItems: IBusinessAreas[] = [];
//   for (let i = 0; i < props.length; i++) {
//     if (props[i].child.length > 0) {
//       let subItem = getLastChildValue(props[i].child, [...parentName, props[i].name]);
//       subItem.forEach((item) => {
//         arrItems.push(item);
//       });
//     } else {
//       arrItems.push({ ...props[i], parentNameArr: parentName });
//     }
//   }
//   return arrItems; // ["zona_urbana", "franja_ejidal", "something1"]
// };

const TheSidebar = () => {
    const [addConnectFast, setAddConnectFast] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { redirectView, location, navigate } = useRouter();
    const { pathname } = location;
    const containerState = useSelector((state: RootState) => state.container);
    const { sidebarShow } = containerState;
    // const [unfoldable, setUnfoldable] = useState<boolean>(false);
    const [sidebarItems, setSidebarItems] = useState<SidebarItem[]>([]);
    const {
        filterState,
        noteProjectData,
        createNoteSuccess,
        deleteNoteSuccess,
    } = useSelector((state: RootState) => state.projectReducer.initialState);

    const [deleteNote, setDeleteNote] = useState<IProject | null>(null);
    const { user } = useSelector((state: RootState) => state.authentication);
    // const handleItemVisibilityDependsPermission = (
    //   ...requiredPerms: Permission[]
    // ) => {
    //   if (!user) return "d-none";
    //   if (requiredPerms.length === 0) return "d-block-inline";
    //   const { permissionGroup, role } = user;
    //   const permissionArray = handleJsonParseArr(
    //     permissionGroup?.permissions || ""
    //   ) as Permission[];
    //   const isAuthorized = checkOverlapBetweenPerms(
    //     permissionArray,
    //     requiredPerms
    //   );
    //   if (checkIfUserIsAdmin(role)) return "d-block-inline";
    //   return isAuthorized ? "d-block-inline" : "d-none";
    // };

    useEffect(() => {
        // const sidebarItems = handleSidebarItemsOnPathName(pathname);
        setSidebarItems(defaultSiderbarItems || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const CustomCNavTitle = ({
        href,
        customClassname,
        label,
    }: ICustomCNavTitle) => {
        const { redirectView } = useRouter();

        return (
            <CNavTitle
                onClick={redirectView(`#${href}`)}
                className={`${customClassname}`}
            >
                <ArrowNarrowLeftIcon stroke="#fff" />
                {label}
            </CNavTitle>
        );
    };

    const renderSidebarItems = (items: SidebarItem[]) => {
        return items.map((item) => {
            // const className = handleItemVisibilityDependsPermission(...(item.permissions || []));
            if (item.isTitle) {
                return (
                    <CustomCNavTitle
                        href={item.to}
                        key={item.name}
                        label={item.name}
                    />
                );
            }
            if (!item?.permissions || (user && checkUserAdmin(user?.role))) {
                return (
                    <CustomCNavItem
                        href={item.to}
                        key={item.name}
                        customClassname={""}
                    >
                        {item.icon}
                        {item.name}
                    </CustomCNavItem>
                );
            }
            const permissions = hasPermission(
                user?.permissionGroup,
                item?.permissions?.[0]
            );
            if (permissions) {
                return (
                    <CustomCNavItem
                        href={item.to}
                        key={item.name}
                        customClassname={""}
                    >
                        {item.icon}
                        {item.name}
                    </CustomCNavItem>
                );
            }
            return <></>;
        });
    };

    const handleClick = function () {
        // if (noteProjectData && noteProjectData?.length < 5) {
        setAddConnectFast(true);
        // } else {
        //     ToastError(
        //         "Không thể thêm truy cập nhanh vì truy cập nhanh đã đạt giới hạn, vui lòng xóa đi để thêm"
        //     );
        // }
    };
    useEffect(() => {
        //@ts-ignore
        dispatch(getNoteProject(filterState));
    }, []);

    const handleCancelDeleteNote = () => {
        setDeleteNote(null);
    };

    const handleDeleteNote = () => {
        //@ts-ignore
        dispatch(removeNoteProject({ projectId: [deleteNote?.projectId] }));
        setDeleteNote(null);
    };
    useEffect(() => {
        if (createNoteSuccess) {
            ToastSuccess("Cập nhật truy cập nhanh thành công");
            //@ts-ignore
            dispatch(getNoteProject(filterState));
            dispatch(resetAll());
        }
    }, [createNoteSuccess]);

    useEffect(() => {
        if (deleteNoteSuccess) {
            ToastSuccess("Xóa truy cập nhanh thành công");
            //@ts-ignore
            dispatch(getNoteProject(filterState));
            dispatch(resetAll());
        }
    }, [deleteNoteSuccess]);
    return (
        <>
            <CSidebar
                className="custom-sidebar"
                position="fixed"
                visible={sidebarShow}
                // unfoldable={unfoldable}
                onVisibleChange={(val: boolean) => dispatch(toggleSidebar(val))}
            >
                <div onClick={() => navigate("/my-task")}>
                    <CSidebarBrand className="sidebar-brand d-none d-md-flex cursor-pointer">
                        <LogoFull className="sidebar-brand-full" />
                        <Logo className="sidebar-brand-narrow" />
                    </CSidebarBrand>
                </div>
                <CSidebarNav>
                    {renderSidebarItems(sidebarItems)}

                    {/* Favorite section */}
                    <CNavTitle>
                        Truy cập nhanh
                        <div onClick={handleClick}>
                            <PlusCircle />
                        </div>
                    </CNavTitle>
                    {noteProjectData &&
                        noteProjectData.map((value, index) => {
                            return (
                                <div key={value.projectId}>
                                    <CustomCNavItem
                                        href={`/project/${value.projectId}/overview`}
                                        customClassname={
                                            "d-flex justify-content-between align-items-center"
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                            }}
                                        >
                                            {value?.project?.avatar ? (
                                                <CAvatar
                                                    src={value?.project?.avatar}
                                                    className="sidebar-avatar avatar-16"
                                                />
                                            ) : (
                                                <CAvatar
                                                    style={{
                                                        backgroundColor: `${
                                                            value?.project
                                                                ?.avatarColor ||
                                                            "#1570ef"
                                                        }`,
                                                    }}
                                                    textColor="white"
                                                    className="sidebar-avatar avatar-16"
                                                >
                                                    {value?.project?.name?.[0]}
                                                </CAvatar>
                                            )}
                                            {value?.project?.name}
                                        </div>

                                        {/* <div onClick={() => setDeleteNote(value)}>
                                            <XIcon
                                                width="20px"
                                                height="20px"
                                                className="cursor-pointer"
                                            />
                                        </div> */}
                                    </CustomCNavItem>
                                </div>
                            );
                        })}
                </CSidebarNav>

                {/* User interface template */}
                <CSidebarNav className="d-none">
                    <CNavTitle>Theme</CNavTitle>
                    <CNavItem href="#/theme/colors">
                        <CIcon icon={cilDrop} customClassName="nav-icon" />
                        Colors
                    </CNavItem>
                    <CNavItem href="#/theme/typography">
                        <CIcon icon={cilPencil} customClassName="nav-icon" />
                        Typography
                    </CNavItem>
                    <CNavTitle>Components</CNavTitle>
                    <CNavGroup
                        toggler={
                            <>
                                <CIcon
                                    icon={cilPuzzle}
                                    customClassName="nav-icon"
                                />{" "}
                                Base
                            </>
                        }
                    >
                        <CNavGroupItems>
                            <CNavItem href="#/base/accordion">
                                Accordion
                            </CNavItem>
                            <CNavItem href="#/base/breadcrumbs">
                                Breadcrumb
                            </CNavItem>
                            <CNavItem href="#/base/cards">Cards</CNavItem>
                            <CNavItem href="#/base/carousels">
                                Carousel
                            </CNavItem>
                            <CNavItem href="#/base/collapses">
                                Collapse
                            </CNavItem>
                            <CNavItem href="#/base/jumbotrons">
                                Jumbotron
                            </CNavItem>
                            <CNavItem href="#/base/list-groups">
                                List group
                            </CNavItem>
                            <CNavItem href="#/base/breadcrumbs">{`Navs & Tabs`}</CNavItem>
                            <CNavItem href="#/base/paginations">
                                Pagination
                            </CNavItem>
                            <CNavItem href="#/base/placeholders">
                                Placeholders
                            </CNavItem>
                            <CNavItem href="#/base/popovers">Popovers</CNavItem>
                            <CNavItem href="#/base/progress">Progress</CNavItem>
                            <CNavItem href="#/base/spinners">Spinners</CNavItem>
                            <CNavItem href="#/base/tables">Tables</CNavItem>
                            <CNavItem href="#/base/tooltips">Tooltips</CNavItem>
                        </CNavGroupItems>
                    </CNavGroup>
                    <CNavGroup
                        toggler={
                            <>
                                <CIcon
                                    icon={cilCursor}
                                    customClassName="nav-icon"
                                />{" "}
                                Buttons
                            </>
                        }
                    >
                        <CNavGroupItems>
                            <CNavItem href="#/buttons/buttons">
                                Buttons
                            </CNavItem>
                            <CNavItem href="#/buttons/button-groups">
                                Buttons groups
                            </CNavItem>
                            <CNavItem href="#/buttons/dropdowns">
                                Dropdowns
                            </CNavItem>
                        </CNavGroupItems>
                    </CNavGroup>
                    <CNavGroup
                        toggler={
                            <>
                                <CIcon
                                    icon={cilNotes}
                                    customClassName="nav-icon"
                                />{" "}
                                Forms
                            </>
                        }
                    >
                        <CNavGroupItems>
                            <CNavItem href="#/forms/form-control">
                                Form Control
                            </CNavItem>
                            <CNavItem href="#/forms/select">Select</CNavItem>
                            <CNavItem href="#/forms/checks-radios">
                                {"Checks & Radios"}
                            </CNavItem>
                            <CNavItem href="#/forms/range">Range</CNavItem>
                            <CNavItem href="#/forms/input-group">
                                Input Group
                            </CNavItem>
                            <CNavItem href="#/forms/floating-labels">
                                Floating Labels
                            </CNavItem>
                            <CNavItem href="#/forms/layout">Layout</CNavItem>
                            <CNavItem href="#/forms/validation">
                                Validation
                            </CNavItem>
                        </CNavGroupItems>
                    </CNavGroup>
                    <CNavItem href="#/charts">
                        <CIcon icon={cilChartPie} customClassName="nav-icon" />
                        Charts
                    </CNavItem>
                    <CNavGroup
                        toggler={
                            <>
                                <CIcon
                                    icon={cilStar}
                                    customClassName="nav-icon"
                                />{" "}
                                Icons
                            </>
                        }
                    >
                        <CNavGroupItems>
                            <CNavItem href="#/icons/coreui-icons">
                                CoreUI Free{" "}
                                <CBadge color="success ms-auto">NEW</CBadge>
                            </CNavItem>
                            <CNavItem href="#/icons/flags">
                                CoreUI Flags
                            </CNavItem>
                            <CNavItem href="#/icons/brands">
                                CoreUI Brands
                            </CNavItem>
                        </CNavGroupItems>
                    </CNavGroup>
                    <CNavGroup
                        toggler={
                            <>
                                <CIcon
                                    icon={cilBell}
                                    customClassName="nav-icon"
                                />{" "}
                                Notifications
                            </>
                        }
                    >
                        <CNavGroupItems>
                            <CNavItem href="#/notifications/alerts">
                                Alerts
                            </CNavItem>
                            <CNavItem href="#/notifications/badges">
                                Badges
                            </CNavItem>
                            <CNavItem href="#/notifications/modals">
                                Modal
                            </CNavItem>
                            <CNavItem href="#/notifications/toasts">
                                Toasts
                            </CNavItem>
                        </CNavGroupItems>
                    </CNavGroup>
                    <CNavItem href="#/widgets">
                        <CIcon
                            customClassName="nav-icon"
                            icon={cilCalculator}
                        />
                        Widgets
                        <CBadge color="info ms-auto">NEW</CBadge>
                    </CNavItem>
                    <CNavTitle>Extras</CNavTitle>
                    <CNavGroup
                        toggler={
                            <>
                                <CIcon
                                    icon={cilStar}
                                    customClassName="nav-icon"
                                />{" "}
                                Pages
                            </>
                        }
                    >
                        <CNavGroupItems>
                            <CNavItem href="#/login">Login</CNavItem>
                            <CNavItem href="#/register">Register</CNavItem>
                            <CNavItem href="#/404">Error 404</CNavItem>
                            <CNavItem href="#/500">Error 500</CNavItem>
                        </CNavGroupItems>
                    </CNavGroup>
                </CSidebarNav>
                {/* <CSidebarToggler className="d-none d-lg-flex" onClick={() => setUnfoldable(!unfoldable)} /> */}
            </CSidebar>
            {addConnectFast && (
                <ActionConnectFast
                    visible={addConnectFast}
                    setVisible={setAddConnectFast}
                    noteProjectData={noteProjectData}
                />
            )}
            {/* {deleteNote ? (
                <ConfirmDeleteModal
                    visible={Boolean(deleteNote)}
                    handleOnClose={handleCancelDeleteNote}
                    handleDelete={handleDeleteNote}
                    title="Xóa nhóm khỏi hệ thống"
                    content={
                        <>
                            Xác nhận xoá nhóm{" "}
                            <span className="high-light">
                                {deleteNote?.project?.name}
                            </span>{" "}
                            ?
                        </>
                    }
                />
            ) : (
                ""
            )} */}
        </>
    );
};

export default React.memo(TheSidebar);
