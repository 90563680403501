export enum MediaFileType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  PDF = 'PDF',
}

export const mapMediaMimeType: { [key: string]: MediaFileType } = {
  'image/jpeg': MediaFileType.IMAGE,
  'image/png': MediaFileType.IMAGE,
  'image/pjpeg': MediaFileType.IMAGE,
  'image/webp': MediaFileType.IMAGE,
  'image/gif': MediaFileType.IMAGE,
  'video/mp4': MediaFileType.VIDEO,
  'video/webm': MediaFileType.VIDEO,
  'video/ogg': MediaFileType.VIDEO,
  'application/pdf': MediaFileType.PDF,
};

export const mapImageVideoMimeType: { [key: string]: MediaFileType } = {
  'image/jpeg': MediaFileType.IMAGE,
  'image/png': MediaFileType.IMAGE,
  'image/pjpeg': MediaFileType.IMAGE,
  'image/webp': MediaFileType.IMAGE,
  'image/gif': MediaFileType.IMAGE,
  'video/mp4': MediaFileType.VIDEO,
  'video/webm': MediaFileType.VIDEO,
  'video/ogg': MediaFileType.VIDEO,
};
