import { ReactComponent as FileUploadIcon } from "@/assets/img/file-upload-icon.svg";
import { ReactComponent as UploadFileIcon } from "@/assets/img/upload-file.svg";
import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CFormText,
  CRow,
} from "@coreui/react-pro";
import dayjs from "dayjs";
import { useDeviceDetect } from "../../../shared/utils/hooks/useDeviceDetect";
import { formatBytes } from "../../../shared/utils/ultils";
import {
  imageExtension,
  videoExtension,
} from "../AvatarUpload/AvatarUploadContainer";
import { ToastError } from "../toast/Toast";

export interface IFileUploadCustom {
  name: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  fileValue: FileType[];
}

export interface FileType {
  dataURL: string;
  file: File | undefined;
  name: string;
  size: number;
  uploadDate: string;
  type: string;
}

interface IFileContainer {
  index: number;
  file: FileType;
  handleRemoveFile?: (index: number) => () => void;
}

export const returnFileExtension = (srcFile: string) => {
  return srcFile.slice(((srcFile.lastIndexOf(".") - 1) >>> 0) + 2);
};

// const inputFileTypesAccept = imageExtension
//   .map((e) => `image/${e}`)
//   .concat(videoExtension.map((v) => `video/${v}`))
//   .toString();

export const checkErrorInputFile = (filesArr: File[]) => {
  let isValid = true;
  for (let i = 0; i < filesArr.length; i++) {
    const { size } = filesArr[i];
    // const isValidImgExtention = !imageExtension.filter((e) => type.indexOf(e) !== -1).length;
    const maximumSizeBytes = 1024 * 1024 * 10;

    // if (isValidImgExtention) {
    //   ToastError(`File tải lên phải có định dạng (${imageExtension.join(', ')})`);
    //   isValid = false;
    //   break;
    // }
    if (size > maximumSizeBytes) {
      ToastError("File tải lên vượt quá dung lượng cho phép 10MB");
      isValid = false;
      break;
    }
  }
  return isValid;
};

export const FileContainerCustom = (props: IFileContainer) => {
  const isMobile = useDeviceDetect();

  const { file, index, handleRemoveFile } = props;

  const fileExtension = returnFileExtension(file?.dataURL || "");
  const firstTenCharacters = file?.dataURL?.substring(0, 20) || "";
  const isImgType =
    firstTenCharacters.includes("data:image") ||
    imageExtension.includes(fileExtension);
  const isVideoType =
    firstTenCharacters.includes("data:video") ||
    videoExtension.includes(fileExtension);
  const isPdf =
    firstTenCharacters.includes("data:application/pdf") ||
    fileExtension.includes("pdf");

  return (
    <div className="file-container py-2 px-3 rounded me-3 mb-2 d-flex justify-content-between flex-wrap cursor-pointer">
      <div>
        <b className="text-break">{file?.name || ""}</b>
        <CFormText>
          {formatBytes(file?.size)} -{" "}
          {dayjs(file.uploadDate).format("HH:mm, DD/MM/YYYY")}
        </CFormText>
      </div>
      <div
        className={`d-flex align-items-center ms-3 ${isMobile ? "w-100" : ""}`}
      >
        <div
          className={`file-action d-inline-block ${
            isMobile ? "w-100 text-end" : ""
          }`}
        >
          {isImgType || isVideoType || isPdf ? (
            <a
              rel="noreferrer"
              target="_blank"
              href={file.dataURL || ""}
              className={`border-end pe-1 d-inline-block`}
            >
              {/* <FiEye size={22} className="text-info cursor-pointer  my-auto pb-1" /> */}
              Xem
            </a>
          ) : (
            ""
          )}

          <a
            download={file.name}
            href={file.dataURL || ""}
            className={`${
              handleRemoveFile ? "border-end" : ""
            } pe-1 d-inline-block`}
          >
            {/* <FiDownload size={22} className="text-info cursor-pointer ms-1 my-auto pb-1" /> */}
            Tải
          </a>
          {handleRemoveFile ? (
            // <BiTrashAlt
            //   size={22}
            //   className="text-danger cursor-pointer my-auto ms-1 pb-1"
            //   onClick={handleRemoveFile(index)}
            // />
            <div
              className="text-danger cursor-pointer my-auto ms-1 pb-1"
              onClick={handleRemoveFile(index)}
            >
              {" "}
              Xoá
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export const FileContainerVisibleCustom = (props: IFileContainer) => {
  const isMobile = useDeviceDetect();

  const { file, index, handleRemoveFile } = props;

  const fileExtension = returnFileExtension(file?.dataURL || "");
  const firstTenCharacters = file?.dataURL?.substring(0, 20) || "";
  const isImgType =
    firstTenCharacters.includes("data:image") ||
    imageExtension.includes(fileExtension);
  const isVideoType =
    firstTenCharacters.includes("data:video") ||
    videoExtension.includes(fileExtension);
  const isPdf =
    firstTenCharacters.includes("data:application/pdf") ||
    fileExtension.includes("pdf");

  const downloadFile = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = file.dataURL;
    downloadLink.target = "_blank";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="file-container d-flex justify-content-between flex-wrap cursor-pointer">
      <div
        className={`file-img-contain ${isImgType ? "" : "no-img"}`}
        onClick={() => {
          if (isImgType || isVideoType || isPdf) {
            downloadFile();
          }
        }}
      >
        {isImgType ? (
          <img src={file.dataURL} alt={`file-img-${index}`} />
        ) : (
          <FileUploadIcon className={"upload-icon"} />
        )}
      </div>
      <div className={`file-content  ${isMobile ? "w-100" : ""}`}>
        <p className="file-name text-break m-0" title={file?.name || ""}>
          {file?.name || ""}
        </p>

        <div
          className={`file-action d-inline-block ${isMobile ? "w-100" : ""}`}
        >
          {handleRemoveFile ? (
            <div className="cursor-pointer" onClick={handleRemoveFile(index)}>
              {" "}
              Xoá
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

const FormikMultiFileUpload = (props: IFileUploadCustom) => {
  const { setFieldValue, name, fileValue } = props;
  let images: FileType[] = fileValue;

  const handleFileUpload = async (e: any) => {
    const inputFiles: File[] = e.target.files as File[];
    if (!checkErrorInputFile(inputFiles)) return;
    if (inputFiles && inputFiles.length > 0) {
      Array.from(inputFiles).forEach((item) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileObj: FileType = {
            file: item,
            dataURL: e.target?.result as string,
            name: item.name,
            size: item.size,
            type: item.type,
            uploadDate: dayjs().toISOString(),
          };
          images = [...images, fileObj];
          setFieldValue(name, images);
        };
        reader.readAsDataURL(item);
      });
    }
  };

  //   const handleRemoveAllImage = () => {
  //     setFieldValue(name, []);
  //   };

  const handleRemoveFile = (index: number) => () => {
    const newImageArr = fileValue?.filter((_, i) => i !== index);
    setFieldValue(name, newImageArr);
  };

  return (
    <div className="upload-file-wrapper">
      <CRow className="">
        <CCol xs={12} className="">
          <div className="d-flex flex-wrap">
            {fileValue.map((file, index) => (
              <FileContainerVisibleCustom
                key={index}
                index={index}
                file={file}
                handleRemoveFile={handleRemoveFile}
              />
            ))}
          </div>
        </CCol>
      </CRow>
      <CFormLabel htmlFor={name} className="upload-file-container">
        <CFormInput
          className="input-upoad"
          type="file"
          name={name}
          multiple
          id={name}
          onClick={(e) => (e.currentTarget.value = "")}
          onChange={handleFileUpload}
        />
        <CButton className="btn-custom variant-gray-300 btn-xs btn-upload">
          <UploadFileIcon className="upload-icon" /> Tải lên
        </CButton>
      </CFormLabel>

      {/* <div className={`${value.length > 0 ? 'd-flex justify-content-end mt-2 mb-2' : 'd-none'} w-100`}>
        <CButton className="delete-all-btn" onClick={handleRemoveAllImage}>
          <CIcon icon={cilX} /> Xóa tất cả ảnh
        </CButton>
      </div> */}
    </div>
  );
};

export default FormikMultiFileUpload;
