import io from "socket.io-client";
import { SOCKET_URL } from "./constants";

const token =
    localStorage.getItem("authentication_token") ||
    sessionStorage.getItem("authentication_token");

export const socket = io(SOCKET_URL, {
    ackTimeout: 10000, // Chờ tối đa 10 giây để nhận xác nhận
    retries: 3,
    //   Thêm các options cần thiết
    extraHeaders: {
        token: token || "",
    },
});
