import axios from "axios";
import { ToastError } from "../../app/shared/toast/Toast";
import { ErrorMessage, mapErrorMessage } from "../enumeration/errorMessage";
import { SERVER_API_URL } from "./constants";

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const instance = axios.create({
  baseURL: SERVER_API_URL,
  timeout: TIMEOUT,
});

const onRequestSuccess = (config: any) => {
  const token =
    localStorage.getItem("authentication_token") ||
    sessionStorage.getItem("authentication_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const onResponseSuccess = (response: any) => {
  const { data } = response;
  if (data.code !== 200 && (data.msg || data.message || data.messageCode)) {
    if (data.msg || data.message) {
      ToastError(data.msg || data.message);
    } else {
      if (data.messageCode !== ErrorMessage.ROOM_HAS_USERS_EXIST) {
        ToastError(mapErrorMessage[data.messageCode as ErrorMessage]);
      }
    }
  }

  return response;
};

const onResponseError = (err: any) => {
  let errorMessage = "";
  if (err?.response && err?.response?.data && err?.response?.data?.code) {
    errorMessage =
      mapErrorMessage[err?.response?.data?.code as ErrorMessage] ||
      err?.response?.data?.code;
  } else {
    errorMessage = mapErrorMessage[err?.code as ErrorMessage] || err.message;
  }
  ToastError(errorMessage);
  return;
};

instance.interceptors.request.use(onRequestSuccess);
instance.interceptors.response.use(onResponseSuccess, onResponseError);

export default instance;
