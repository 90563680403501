import React, { useEffect, useState, useRef } from "react";
import {
    CAvatar,
    CFormInput,
    CInputGroup,
    CInputGroupText,
} from "@coreui/react-pro";
import SearchIcon from "../icons/SearchIcon";
import { useSelector, useDispatch } from "react-redux";
import {
    getEntity,
    getSearchProjectData,
} from "@/app/modules/Project/projectManagement.api";
import { RootState } from "@/reducers";
import { IProject } from "@/shared/model/project.modal";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "@/store";
import { projectMembersSelectors } from "@/app/modules/Project/Detail/Members/memberManagement.reducer";
import {
    ITaskTree,
    renderTaskTree,
} from "@/app/modules/Project/Detail/Tasks/TasksManagementGantt";
import TaskDetailModal from "@/app/modules/Project/Detail/Tasks/TaskDetailContainer/TaskDetailModal";
import { ITask } from "@/shared/model/task.model";
import { getEntities } from "@/app/modules/Project/Detail/Tasks/tasksManagement.api";
import {
    fetching,
    resetEntity,
} from "@/app/modules/Project/Detail/Tasks/tasksManagement.reducer";

interface SearchResult {
    projects: IProject[];
    tasks: any[]; // Replace 'any' with your actual task interface
}

function Search() {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>("");
    const [id, setId] = useState<string | null>(null);
    const [searchResult, setSearchResult] = useState<SearchResult>({
        projects: [],
        tasks: [],
    });
    const projectMembers = useSelector(projectMembersSelectors.selectAll);
    const [createTask, setCreateTask] = useState<boolean>(false);
    const [treeItems, setTreeItems] = useState<ITaskTree[]>([]);
    const [projectDetail, setProjectDetail] = useState<IProject>();
    const [detailTask, setDetailTask] = useState<number | string | null>(null);
    const handleCancelsetDetailTask = (update?: boolean) => {
        setDetailTask(null);
    };
    const prop = useSelector((state: RootState) => state.tasksReducer);
    const state = prop.initialState;
    const handleCreateTask = () => {
        setCreateTask(true);
    };
    const [recentProjects, setRecentProjects] = useState<IProject[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    const [tasks, setTasks] = useState<ITask[]>([]);
    const { initialState } = useSelector(
        (state: RootState) => state.tasksReducer
    );
    // const projects = useSelector(projectsSelectors.selectAll);
    const { user } = useSelector((state: RootState) => state.authentication);
    const { filterState, searchProjectData: projects } = useSelector(
        (state: RootState) => state.projectReducer.initialState
    );
    const searchRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    useEffect(() => {
        // dispatch(getEntitiesDataTask({ ...filterState, projectId: id }));
        if (id) {
            dispatch(getEntities({ ...filterState, projectId: id }));
        }
    }, [id]);
    useEffect(() => {
        setTasks(initialState.dataTasks);
    }, [JSON.stringify(initialState.dataTasks)]);

    useEffect(() => {
        if (tasks && projectDetail) {
            setTreeItems(renderTaskTree(tasks, projectDetail));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(tasks), JSON.stringify(projectDetail)]);
    const CardProject = ({
        project,
        index,
    }: {
        project: IProject;
        index: number;
    }) => {
        return (
            <div
                onClick={() => {
                    navigate(`/project/${project.projectId}/overview`);
                    setIsOpen(false);
                    setSearchKey("");
                }}
                key={`recent-project-${index}`}
                className="p-2 px-3 d-flex align-items-center background-hover"
                style={{ gap: "12px", cursor: "pointer" }}
            >
                {project.avatar ? (
                    <CAvatar
                        src={project.avatar}
                        shape="rounded"
                        className="avatar-32"
                    />
                ) : (
                    <CAvatar
                        style={{
                            backgroundColor: `${
                                project.avatarColor || "#1570ef"
                            }`,
                        }}
                        textColor="white"
                        shape="rounded"
                        className="avatar-32"
                    >
                        {project.name?.charAt(0).toUpperCase()}
                    </CAvatar>
                )}
                <div>
                    <div style={{ fontWeight: "bold" }}>{project.name}</div>
                    <div style={{ fontSize: "12px", color: "#666" }}>
                        Mã dự án &gt; {project.code}
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                searchRef.current &&
                !searchRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (searchKey.length > 0) {
            dispatch(
                getSearchProjectData({
                    ...filterState,
                    assignId: Number(user?.id),
                    limit: 1000,
                }) as any
            );
        } else {
            dispatch(
                getSearchProjectData({
                    ...filterState,
                    assignId: Number(user?.id),
                    limit: 3,
                    sortOrder: "ASC",
                }) as any
            );
        }
    }, [searchKey]);

    useEffect(() => {
        dispatch(
            getSearchProjectData({
                ...filterState,
                assignId: Number(user?.id),
                limit: 3,
                sortOrder: "ASC",
            }) as any
        );
    }, []);

    useEffect(() => {
        if ((state.updateEntitySuccess || state.createEntitySuccess) && id) {
            dispatch(fetching());
            dispatch(getEntities({ ...filterState, projectId: id }));
            dispatch(resetEntity());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.updateEntitySuccess, state.createEntitySuccess]);

    useEffect(() => {
        if (projects) {
            if (searchKey.length > 0) {
                const filteredProjects = projects?.filter((item: IProject) =>
                    item?.name?.toLowerCase().includes(searchKey.toLowerCase())
                );

                const allTasks = projects?.flatMap((project) =>
                    project.tasks.filter((task) =>
                        task.name
                            .toLowerCase()
                            .includes(searchKey.toLowerCase())
                    )
                );
                
                setSearchResult({
                    projects: filteredProjects,
                    tasks: allTasks,
                });
            } else {
                setRecentProjects(projects.slice(0, 3));
                setSearchResult({ projects: [], tasks: [] });
            }
        }
    }, [searchKey, projects]);

    useEffect(() => {
        if (id) {
            dispatch(getEntity(id)).then((data: any) => {
                setProjectDetail(data.payload?.data);
            });
        }
    }, [id]);

    const handleSearchFocus = () => {
        setIsOpen(true);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value);
    };

    return (
        <>
            <div ref={searchRef} className="position-relative">
                <CInputGroup
                    className="input-start-group d-none d-lg-flex"
                    style={{ marginRight: "8px" }}
                >
                    <CInputGroupText
                        id="search-addon"
                        className="cursor-pointer"
                    >
                        <SearchIcon />
                    </CInputGroupText>
                    <CFormInput
                        autoComplete="off"
                        placeholder="Tìm kiếm"
                        name="keyword"
                        value={searchKey}
                        onChange={handleSearchChange}
                        onFocus={handleSearchFocus}
                    />
                </CInputGroup>

                {isOpen && (
                    <div
                        style={{
                            backgroundColor: "#fff",
                            position: "absolute",
                            width: "480px",
                            minHeight: "10px",
                            marginTop: "6px",
                            borderRadius: "8px",
                            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                            zIndex: 1000,
                        }}
                    >
                        {searchResult.projects.length > 0 || searchResult.tasks.length > 0 || recentProjects.length > 0 ? <>{searchKey.length === 0 &&
                            recentProjects.length > 0 && (
                                <div className="mb-3">
                                    <div
                                        style={{
                                            fontSize: "14px",
                                            color: "#666",
                                            paddingTop: "16px",
                                            fontWeight: "bold",
                                        }}
                                        className="px-3"
                                    >
                                        DỰ ÁN GẦN ĐÂY
                                    </div>
                                    <div style={{
                                                maxHeight: "180px",
                                                overflowY: "auto",
                                            }}>{recentProjects.map((project, index) => (
                                                <CardProject
                                                    project={project}
                                                    index={index}
                                                />
                                            ))}</div>
                                </div>
                            )}

                        {searchKey.length > 0 && (
                            <>
                                

                                {searchResult.projects.length > 0 || searchResult.tasks.length > 0 ? <>{searchResult.projects.length > 0 && (
                                    <div className="">
                                        <div
                                            style={{
                                                fontSize: "14px",
                                                color: "#666",
                                                paddingTop: "16px",
                                                fontWeight: "bold",
                                            }}
                                            className="px-3"
                                        >
                                            DỰ ÁN
                                        </div>
                                        <div style={{
                                                maxHeight: "180px",
                                                overflowY: "auto",
                                            }}>
                                            {searchResult.projects
                                                .slice(0, 3)
                                                .map((project, index) => (
                                                    <CardProject
                                                        project={project}
                                                        index={index}
                                                    />
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {searchResult.tasks.length > 0 && (
                                    <div className="mb-3">
                                        <div
                                            style={{
                                                fontSize: "14px",
                                                color: "#666",
                                                paddingTop: "16px",
                                                fontWeight: "bold",
                                            }}
                                            className="px-3"
                                        >
                                            CÔNG VIỆC
                                        </div>
                                        <div
                                            style={{
                                                maxHeight: "280px",
                                                overflowY: "auto",
                                            }}
                                        >
                                            {searchResult.tasks.map(
                                                (task, index) => {
                                                    return (
                                                        <div
                                                            key={`task-${index}`}
                                                            className="p-2 px-3 d-flex align-items-center background-hover"
                                                            style={{
                                                                gap: "12px",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setId(
                                                                    task?.projectId
                                                                );
                                                                setDetailTask(
                                                                    task?.taskId
                                                                );
                                                            }}
                                                        >
                                                            {task.avatar ? (
                                                                <CAvatar
                                                                    src={
                                                                        task.avatar
                                                                    }
                                                                    shape="rounded"
                                                                    className="avatar-32"
                                                                />
                                                            ) : (
                                                                <CAvatar
                                                                    style={{
                                                                        backgroundColor: `${
                                                                            task.avatarColor ||
                                                                            "#1570ef"
                                                                        }`,
                                                                    }}
                                                                    textColor="white"
                                                                    shape="rounded"
                                                                    className="avatar-32"
                                                                >
                                                                    {task.name
                                                                        ?.charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase()}
                                                                </CAvatar>
                                                            )}
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    {task.name}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        fontSize:
                                                                            "12px",
                                                                        color: "#666",
                                                                    }}
                                                                >
                                                                    Mã dự án{" "}
                                                                    {
                                                                        task.projectCode
                                                                    }{" "}
                                                                    &gt;{" "}
                                                                    {
                                                                        projects?.find(
                                                                            (
                                                                                project
                                                                            ) => {
                                                                                return project.tasks.some(
                                                                                    (
                                                                                        value
                                                                                    ) =>
                                                                                        value.taskId ===
                                                                                        task.taskId
                                                                                );
                                                                            }
                                                                        )?.code
                                                                    }
                                                                    {`(${
                                                                        projects?.find(
                                                                            (
                                                                                project
                                                                            ) => {
                                                                                return project.tasks.some(
                                                                                    (
                                                                                        value
                                                                                    ) =>
                                                                                        value.taskId ===
                                                                                        task.taskId
                                                                                );
                                                                            }
                                                                        )?.name
                                                                    })`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                )}</> : <div style={{
                                    fontSize: "14px",
                                    color: "#666",
                                }}
                                className="p-3">Không tìm thấy</div>}
                            </>
                        )}</> : <div style={{
                            fontSize: "14px",
                            color: "#666",
                        }}
                        className="p-3">Không tìm thấy</div>}
                    </div>
                )}
            </div>

            {detailTask && id && projectDetail && treeItems ? (
                <TaskDetailModal
                    projectMembers={projectMembers}
                    isMyTask={true}
                    setCreateTask={setCreateTask}
                    treeItemArr={treeItems}
                    project={projectDetail}
                    chosenTaskId={detailTask}
                    visible={Boolean(detailTask)}
                    setVisible={handleCancelsetDetailTask}
                />
            ) : (
                ""
            )}
        </>
    );
}

export default Search;
