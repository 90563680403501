import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { firebaseConfig, vapidKeyFirebase } from "./constants";
// Initialize Firebase
// import {  onMessage } from "firebase/messaging";
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);
export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission)
    if (permission === "granted") {
        const token = await getToken(messaging, { vapidKey: vapidKeyFirebase });
        console.log("granted")
        console.log(token)
        return token;
    }
    
    return "";
};

// onMessage(messaging, (payload) => {
//     console.log('Message received. ', payload);
//     // ...
// });
// export const onMessageListener = () =>
//     new Promise((resolve) => {
//         messaging.onMessage((payload) => {
//             resolve(payload);
//         });
//     });

