// import avatar from '@/assets/img/avatar.jpg';
import { IUser } from "@/shared/model/user.model";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { CAvatar, CTooltip } from "@coreui/react-pro";
import React, { ReactNode, useState } from "react";
import ActionMember from "../MemberDetails/ActionMember";
import Work from "../MemberDetails/Work";
import MemberDetailModal from "../MemberDetails/MemberDetailModal";

interface IAvatarContainer {
  limit?: number;
  size?: number;
  userArray: IUser[];
  fontSize?: number;
  totalUser?: number;
  redirectUrl?: string;
  isMyTask?: boolean;
}

// const userArray = [avatar, avatar, avatar, avatar, avatar, avatar, avatar, avatar, avatar, avatar];

const AvatarContainer = (props: IAvatarContainer) => {
  const [visible, setVisible] = useState(true);
  const [user, setUser] = useState<IUser>();

  const {
    limit = 5,
    size = 24,
    userArray,
    totalUser,
    fontSize = 12,
    redirectUrl,
    isMyTask = false,
  } = props;
  const { navigate } = useRouter();

  // add 4px to size from the border of avatar which is 2px
  const realSize = size + 4;

  const total = totalUser || userArray?.length;

  // get postion pixel to position avatar from left
  const positionPixel = Math.round(realSize * 0.8);

  // get the remain pixel to add to width of container
  const lackPixel = Math.round(realSize * 0.2);

  const dummyData: { name: string; component: ReactNode }[] = [
    {
      name: "Hoạt động",
      component: <ActionMember user={user}></ActionMember>,
    },
    { name: "Công việc", component: <Work user={user}></Work> },
  ];

  // get total width of container base on number of avatar
  const totalWidth =
    userArray?.length > limit
      ? positionPixel * (limit + 1)
      : positionPixel * userArray?.length;

  // get real width of container and add lack pixel of the first avatar
  const realWidth = totalWidth + lackPixel;

  const handleRedirect = () => {
    if (!redirectUrl) return;
    navigate(redirectUrl);
  };

  return (
    <div
      className="list-avatar-container"
      style={{ width: `${realWidth}px`, height: `${realSize}px` }}
    >
      {userArray?.map((user, index) => {
        if (index + 1 === limit + 1) {
          const remain = total - limit;
          const remainUser = userArray?.slice(limit, userArray?.length);
          return (
            <CTooltip
              key={user.id}
              // className="position-fixed"
              content={
                <>
                  {totalUser ? (
                    <p key={user.id} className="mb-0">
                      Xem thêm
                    </p>
                  ) : (
                    remainUser.map((user, index) => {
                      const label =
                        user.fullName || user.username || user.email || "";
                      return (
                        <p key={user.id} className="mb-0">
                          {label}
                        </p>
                      );
                    })
                  )}
                </>
              }
            >
              <CAvatar
                textColor="white"
                className="avatar-wrapper extends-avatar"
                style={{
                  boxSizing: "content-box",
                  left: `${positionPixel * index}px`,
                  width: `${realSize}px`,
                  height: `${realSize}px`,
                  fontSize: `${fontSize}px`,
                }}
                onClick={handleRedirect}
              >
                +{remain}
              </CAvatar>
            </CTooltip>
          );
        } else if (index < limit) {
          const label = user.fullName || user.username || user.email || "";
          return (
            <>
              <CTooltip
                key={user.id}
                content={label}
                // className="position-fixed"
              >
                <CAvatar
                  shape="rounded-circle"
                  src={user.avatar || ""}
                  style={{
                    boxSizing: "content-box",
                    backgroundColor: `${user.avatarColor || "#1570ef"}`,
                    left: `${positionPixel * index}px`,
                    width: `${realSize}px`,
                    height: `${realSize}px`,
                    fontSize: `${fontSize}px`,
                  }}
                  key={user.id}
                  textColor="white"
                  className="avatar-wrapper"
                  onClick={() => {
                    setUser(user);
                    setVisible(true);
                  }}
                >
                  {label ? label.charAt(0)?.toUpperCase() : ""}
                </CAvatar>
              </CTooltip>
            </>
          );
        } else {
          return <React.Fragment key={user.id}></React.Fragment>;
        }
      })}
      {user && !isMyTask && (
        <MemberDetailModal
          user={user}
          visible={visible}
          setVisible={setVisible}
          listActionComponent={dummyData}
        ></MemberDetailModal>
      )}
    </div>
  );
};

export default AvatarContainer;
