import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

import { RootState } from "@/reducers";
import { ILogs, IProject } from "@/shared/model/project.modal";
import { IStaff } from "@/shared/model/user.model";
import { IInitialState, IResponse } from "@/shared/shared-interfaces";
import {
  createEntity,
  createNoteProject,
  getDataAllEntities,
  getDifferentEntities,
  getEntities,
  getEntity,
  getNoteProject,
  getProjectStaff,
  IProjectParams,
  removeEntity,
  removeNoteProject,
  updateDeleteEntity,
  updateEntity,
  uploadProjectDocument,
  getLogsEntities,
  getSearchProjectData,
} from "./projectManagement.api";

interface IProjectInitialState extends IInitialState {
  projectStaff: IStaff[];
  LogProjects: ILogs[];
  fetchProjectStaffSuccess: boolean;
  errorProjectStaffMessage: string | null;
  updateEntityStatusSuccess: boolean;
  filterState: IProjectParams;
  updateDocumentSuccess: boolean;
  errorDocumentMessage: string | null;
  noteProjectData?: IProject[] | null;
  differentProjectData: IProject[] | null;
  dataAllProjectData: IProject[] | null;
  createNoteSuccess?: boolean;
  deleteNoteSuccess?: boolean;
  totalItemDifferent?: number;
  totalPageDifferent?: number;
  searchProjectData?: IProject[] | null;
}

export const initialProjectFilter: IProjectParams = {
  page: 1,
  limit: 10,
  sortBy: "createdDate",
  sortOrder: "DESC",
};

const initialState: IProjectInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  totalPages: 0,
  updateEntityStatusSuccess: false,
  filterState: initialProjectFilter,
  projectStaff: [],
  fetchProjectStaffSuccess: false,
  errorProjectStaffMessage: null,
  updateDocumentSuccess: false,
  errorDocumentMessage: null,
  noteProjectData: null,
  differentProjectData: null,
  dataAllProjectData: null,
  LogProjects: [],
  createNoteSuccess: false,
  deleteNoteSuccess: false,
  searchProjectData: [],
};

export const projectsAdapter = createEntityAdapter<IProject>({
  selectId: ({ projectId }) => projectId,
});

const { actions, reducer } = createSlice({
  name: "projectsSlice",
  initialState: projectsAdapter.getInitialState({ initialState }),
  reducers: {
    setFilterState(state, { payload }: PayloadAction<IProjectParams>) {
      state.initialState.filterState = payload;
    },
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.createNoteSuccess = false;
      state.initialState.deleteNoteSuccess = false;
      // state.initialState.noteProjectData = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    resetProjectStaff(state) {
      state.initialState.fetchProjectStaffSuccess = false;
      state.initialState.projectStaff = [];
      state.initialState.errorProjectStaffMessage = null;
    },
    resetUploadDocument(state) {
      state.initialState.updateDocumentSuccess = false;
      state.initialState.errorDocumentMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<IProject[]>>>
      ) => {
        projectsAdapter.setAll(state, payload.data.data);
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
    builder.addCase(
      getEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IProject>) => {
        projectsAdapter.upsertOne(state, payload);
        state.initialState.fetchEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitySuccess = false;
      }
    );
    builder.addCase(
      getLogsEntities.fulfilled.type,
      (state, { payload }: PayloadAction<IResponse<ILogs[]>>) => {
        state.initialState.LogProjects = payload.data;
        state.initialState.fetchEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getLogsEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitySuccess = false;
      }
    );
    builder.addCase(
      updateEntity.fulfilled.type,
      (state, { payload }: PayloadAction<IProject>) => {
        projectsAdapter.updateOne(state, {
          id: payload.projectId,
          changes: payload,
        });
        state.initialState.updateEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      updateEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
    builder.addCase(
      createEntity.fulfilled.type,
      (state, { payload }: PayloadAction<any>) => {
        projectsAdapter.addOne(state, payload);
        if (payload.msg) {
          state.initialState.errorMessage = payload?.msg;
          return;
        }

        state.initialState.updateEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      createEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateEntitySuccess = false;
      }
    );
    builder.addCase(
      removeEntity.fulfilled.type,
      (state, { payload }: PayloadAction<string>) => {
        projectsAdapter.removeOne(state, payload);
        state.initialState.totalItems -= 1;
        state.initialState.deleteEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      removeEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.deleteEntitySuccess = false;
      }
    );
    builder.addCase(
      updateDeleteEntity.fulfilled.type,
      (state, { payload }: PayloadAction<string[]>) => {
        projectsAdapter.removeMany(state, payload);
        state.initialState.totalItems -= payload.length;
        state.initialState.deleteEntitySuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      updateDeleteEntity.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.deleteEntitySuccess = false;
      }
    );
    builder.addCase(
      getProjectStaff.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<IStaff[]>>>
      ) => {
        state.initialState.projectStaff = payload.data.data;
        state.initialState.fetchProjectStaffSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getProjectStaff.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorProjectStaffMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchProjectStaffSuccess = false;
      }
    );

    builder.addCase(
      uploadProjectDocument.fulfilled.type,
      (state, { payload }: PayloadAction<IProject>) => {
        projectsAdapter.updateOne(state, {
          id: payload.projectId,
          changes: payload,
        });
        state.initialState.updateDocumentSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      uploadProjectDocument.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorDocumentMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.updateDocumentSuccess = false;
      }
    );

    builder.addCase(
      getNoteProject.fulfilled.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.noteProjectData = payload?.data;
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getNoteProject.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
    builder.addCase(
      getDifferentEntities.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<IProject[]>>>
      ) => {
        state.initialState.totalItemDifferent = Number(payload.data.count);
        state.initialState.totalPageDifferent = Number(payload.data.total_pages);
        state.initialState.differentProjectData = payload.data.data;
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getDifferentEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );

    builder.addCase(
      getDataAllEntities.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<IProject[]>>>
      ) => {
        state.initialState.dataAllProjectData = payload.data.data;
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getDataAllEntities.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );

    builder.addCase(
      createNoteProject.fulfilled.type,
      (state, { payload }: PayloadAction<IProject>) => {
        // projectsAdapter.updateOne(state, {
        //   id: payload.projectId,
        //   changes: payload,
        // });
        state.initialState.createNoteSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      createNoteProject.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.createNoteSuccess = false;
      }
    );

    builder.addCase(
      removeNoteProject.fulfilled.type,
      (state, { payload }: any) => {
        state.initialState.deleteNoteSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      removeNoteProject.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.deleteNoteSuccess = false;
      }
    );

    builder.addCase(
      getSearchProjectData.fulfilled.type,
      (
        state,
        { payload }: PayloadAction<AxiosResponse<IResponse<IProject[]>>>
      ) => {
        state.initialState.searchProjectData = payload.data.data;
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(
      getSearchProjectData.rejected.type,
      (state, { payload }: PayloadAction<any>) => {
        state.initialState.errorMessage = payload?.message;
        state.initialState.loading = false;
        state.initialState.fetchEntitiesSuccess = false;
      }
    );
  },
});

export const {
  fetching,
  resetAll,
  resetEntity,
  setFilterState,
  resetUploadDocument,
} = actions;
export default reducer;

export const projectsSelectors = projectsAdapter.getSelectors<RootState>(
  (state) => state.projectReducer
);

const { selectById } = projectsAdapter.getSelectors();
const getProjectState = (rootState: RootState) => rootState.projectReducer;

export const selectEntityById = (id: string) => {
  return createSelector(getProjectState, (state) => selectById(state, id));
};
