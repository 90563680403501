import React from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

const TaskOverview = React.lazy(() => import("./TaskOverview"));

const MyTaskLayout: RouteObject[] = [
  {
    path: "/",
    element: <TaskOverview />,
  },

  { path: "*", element: <Navigate to="/404" /> },
];

const MyTaskRoutes = () => useRoutes(MyTaskLayout);
export default MyTaskRoutes;
